import { db, storage } from './firebase'; // Importamos 'db' que es nuestra referencia a Firestore desde el archivo original de firebase
import { collection, getDocs, addDoc, query, where, doc, getDoc, runTransaction, setDoc, writeBatch, updateDoc, orderBy, deleteDoc } from 'firebase/firestore'; // Importamos los métodos necesarios de Firestore
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { logError } from './logError';


// FUNCION PARA OBTENER TODOS LOS PROYECTOS
export async function getProjects() {
    try {
        const projectCollection = collection(db, 'project_detail');
        const projectSnapshot = await getDocs(projectCollection);
        // Verificar si hay documentos
        if (projectSnapshot.empty) {
            return [];
        }

        const projectList = projectSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        // Ordenar la lista alfabéticamente por el campo "project_name" sin importar mayúsculas o minúsculas
        projectList.sort((a, b) => a.project_name.localeCompare(b.project_name, undefined, { sensitivity: 'base' }));

        return projectList;
    } catch (error) {
        console.error("Error al obtener los proyectos: ", error);
        return [];
    }
}

// FUNCION PARA OBTENER TODOS LOS PROVEEDORES
export async function getProviders() {
    try {
        const providerCollection = collection(db, 'proveedor_detail');
        const providerSnapshot = await getDocs(providerCollection);

        // Verificar si hay documentos
        if (providerSnapshot.empty) {
            return [];
        }

        const providerList = providerSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        // Ordenar la lista alfabéticamente por el campo "name" sin importar mayúsculas o minúsculas
        providerList.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

        return providerList;
    } catch (error) {
        console.error("Error al obtener los proveedores: ", error);
        return [];
    }
}

// FUNCION PARA AGREGAR UN NUEVO PROVEEDOR
export async function addNewProvider(providerName) {
    try {
        const providerCollection = collection(db, 'proveedor_detail');

        // Validar si ya existe un proveedor con ese nombre
        const q = query(providerCollection, where("name", "==", providerName));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            // El proveedor ya existe
            return {
                success: false,
                error: "Error al agregar proveedor.",
                message: "El nombre del proveedor ya existe."
            };
        }

        // Si no existe, agregarlo
        const docRef = await addDoc(providerCollection, { name: providerName });
        return {
            success: true,
            message: "Proveedor agregado exitosamente.",
            id: docRef.id,
            name: providerName
        }
    } catch (error) {
        console.error("Error al agregar proveedor: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al agregar el proveedor."
        }
    }
}

//FUNCION PARA OBTENER TODAS LAS REQUISICIONES CON SUS DETALLES
export async function getRequisitionsWithDetails() {
    try {
        const requisitionsCollection = collection(db, 'requisitions');
        const requisitionsSnapshot = await getDocs(requisitionsCollection);
        const requisitionsList = [];

        for (let reqDoc of requisitionsSnapshot.docs) {
            const requisitionData = {
                id: reqDoc.id,
                ...reqDoc.data()
            };

            // Aquí consultamos la subcolección 'requisition_detail' para la requisición actual
            const detailCollection = collection(reqDoc.ref, 'requisition_detail');
            const detailSnapshot = await getDocs(detailCollection);

            const details = detailSnapshot.docs.map(detailDoc => ({
                id: detailDoc.id,
                ...detailDoc.data()
            }));

            requisitionData.details = details;
            requisitionsList.push(requisitionData);
        }

        return requisitionsList;
    } catch (error) {
        console.error("Error al obtener las requisiciones con detalles: ", error);
        return [];
    }
}

//Funcion para agregar requisiciones y sus detalles SIN COORELATIVO
export async function addRequisitionWithDetails(requisitionData, detailsList) {
    try {

        // Paso 0: Obtener el correlativo y asignarlo a la requisición si el estado no es "solicitada"
        if (requisitionData.estado !== "Solicitada") {
            const nextCorrelativo = await incrementRequisitionCounter();
            requisitionData.correlativo = nextCorrelativo;
        }


        // Paso 1: Agrega el documento principal de la requisición
        const newRequisitionRef = await addDoc(collection(db, 'requisitions'), requisitionData);

        // Paso 2: Agrega cada detalle a la subcolección 'requisition_detail' del documento de requisición creado
        for (let index = 0; index < detailsList.length; index++) {
            const detail = detailsList[index];

            // Creamos una referencia para el nuevo detalle para obtener su ID antes de agregarlo
            const newDetailRef = doc(collection(newRequisitionRef, 'requisition_detail'));

            // Inicializamos fileURL como un string vacío
            let fileURL = "";

            // Verificamos si el detalle tiene un archivo asociado
            if (detail.file) {
                // Subimos el archivo a Firebase usando el ID del nuevo detalle y obtenemos la URL
                fileURL = await uploadInvoiceFile(detail.file, newDetailRef.id + 'fac');
                if (!fileURL) {
                    await logError("Requisiciones", "Error subiendo el archivo de la factura para " + newDetailRef.id, detail.file.name);
                }
            }

            // Inicializamos fileURL como un string vacío
            let fileURL2 = "";

            // Verificamos si el detalle tiene un archivo asociado
            if (detail.cotizacion) {
                // Subimos el archivo a Firebase usando el ID del nuevo detalle y obtenemos la URL
                fileURL2 = await uploadInvoiceFile(detail.cotizacion, newDetailRef.id + 'cot');

                if (!fileURL2) {
                    await logError("Requisiciones", "Error subiendo el archivo de la cotizacion para " + newDetailRef.id, detail.cotizacion.name);
                }
            }

            // Eliminamos el objeto File de los detalles antes de guardar en Firestore y agregamos un orden
            const detailWithoutFile = { ...detail, file: fileURL, cotizacion: fileURL2, order: index };
            delete detailWithoutFile.file;
            delete detailWithoutFile.cotizacion;

            // Si hay una URL de archivo, la añadimos al detalle
            if (fileURL || fileURL === "") {
                detailWithoutFile.file = fileURL;
            }

            // Si hay una URL de archivo, la añadimos al detalle
            if (fileURL2 || fileURL2 === "") {
                detailWithoutFile.cotizacion = fileURL2;
            }

            // Ahora, agregamos el detalle usando la referencia que creamos previamente
            await setDoc(newDetailRef, detailWithoutFile);
        }

        return {
            success: true,
            message: "Requisición enviada para su revisión.",
            id: newRequisitionRef.id,
        }
    } catch (error) {
        console.error("Error al agregar la requisición con detalles: ", error);
        await logError("Requisiciones", "Error al agregar la requisición con detalles ", error.message);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al enviar la Requisición."
        }
    }
}

//Funcion para subir los archivos
export async function uploadInvoiceFile(file, detailId) {
    try {

        // Verificar que el archivo no esté vacío
        if (!file || file.size === 0) {
            await logError("Requisiciones", "El archivo está vacío o no existe ", detailId);
            return null;
        }

        // Se limpia el nombre del archivo para que sea un nombre válido de archivo en Firebase
        // eliminar caracteres especiales o espacios, reemplazándolos con guiones bajos.
        let fileName = file.name.replace(/[^a-z0-9.]/gi, '_').toLowerCase();

        // Se define la referencia para almacenar el archivo en el directorio "invoices" con el ID de detalle
        //const stref = ref(storage, `/invoices/${detailId}`);
        const filePath = `/invoices/${detailId}-${fileName}`;

        const stref = ref(storage, filePath);


        // Se sube el archivo
        await uploadBytes(stref, file);

        // Se obtiene la URL para descargar el archivo
        const downloadUrl = await getDownloadURL(stref);

        return downloadUrl;
    } catch (error) {
        console.error("Error subiendo el archivo:", error);
        return null;
    }
}

//funcion para ver el coorelativo 
export async function getNextRequisitionNumber() {
    try {
        const counterRef = doc(db, 'counters', 'requisitions');
        const counterDoc = await getDoc(counterRef);

        if (!counterDoc.exists()) {
            throw new Error("El documento de contador no existe.");
        }

        const currentCount = counterDoc.data().count;
        return currentCount + 1;  // Devuelve el número siguiente

    } catch (error) {
        console.error("Error al obtener el número de requisición: ", error);
        throw error; // Para que cualquier llamada a la función pueda manejar el error también
    }
}


// Función para obtener e incrementar el correlativo
export async function incrementRequisitionCounter() {
    const counterRef = doc(db, 'counters', 'requisitions');

    try {
        const newCount = await runTransaction(db, async (transaction) => {
            const counterDoc = await transaction.get(counterRef);

            if (!counterDoc.exists()) {
                throw Error("El documento de contador no existe.");
            }

            const currentCount = counterDoc.data().count;
            const newCount = currentCount + 1;

            transaction.update(counterRef, { count: newCount });

            return newCount;
        });

        return newCount;

    } catch (error) {
        console.error("Error al incrementar el contador de requisiciones: ", error);
        throw error;
    }
}


// FUNCION PARA OBTENER TODAS LAS REQUISICIONES CON ESTADO "Solicitada" SIN SUS DETALLES
export async function getRequisitions_requested() {
    try {
        const requisitionsCollection = collection(db, 'requisitions');
        // Creamos una consulta para filtrar solo las requisiciones con estado "Solicitada"        
        const q = query(requisitionsCollection, where("estado", "==", "Solicitada"), orderBy("fecha", "desc"));

        const requisitionsSnapshot = await getDocs(q);
        const requisitionsList = requisitionsSnapshot.docs.map(reqDoc => ({
            id: reqDoc.id,
            ...reqDoc.data()
        }));

        //console.log(requisitionsList)
        return requisitionsList;
    } catch (error) {
        console.error("Error al obtener las requisiciones sin detalles: ", error);
        return [];
    }
}

// FUNCION PARA OBTENER TODAS LAS REQUISICIONES CON ESTADO "Nueva" SIN SUS DETALLES
export async function getRequisitions_new() {
    try {
        const requisitionsCollection = collection(db, 'requisitions');
        // Creamos una consulta para filtrar solo las requisiciones con estado "Solicitada"
        const q = query(requisitionsCollection, where("estado", "==", "Nueva"), orderBy("correlativo", "desc"));
        const requisitionsSnapshot = await getDocs(q);
        const requisitionsList = requisitionsSnapshot.docs.map(reqDoc => ({
            id: reqDoc.id,
            ...reqDoc.data()
        }));

        //console.log(requisitionsList)
        return requisitionsList;
    } catch (error) {
        console.error("Error al obtener las requisiciones sin detalles: ", error);
        return [];
    }
}

// FUNCION PARA OBTENER TODAS LAS REQUISICIONES CON ESTADO "Aprobada" SIN SUS DETALLES
export async function getRequisitions_approved() {
    try {
        const requisitionsCollection = collection(db, 'requisitions');
        // Creamos una consulta para filtrar solo las requisiciones con estado "Solicitada"
        const q = query(requisitionsCollection, where("estado", "==", "Aprobada"), orderBy("correlativo", "desc"));
        const requisitionsSnapshot = await getDocs(q);
        const requisitionsList = requisitionsSnapshot.docs.map(reqDoc => ({
            id: reqDoc.id,
            ...reqDoc.data()
        }));

        //console.log(requisitionsList)
        return requisitionsList;
    } catch (error) {
        console.error("Error al obtener las requisiciones approved sin detalles: ", error);
        return [];
    }
}

// FUNCION PARA OBTENER TODAS LAS REQUISICIONES CON ESTADO "Rechazada" SIN SUS DETALLES
export async function getRequisitions_Rejected() {
    try {
        const requisitionsCollection = collection(db, 'requisitions');
        // Creamos una consulta para filtrar solo las requisiciones con estado "Solicitada"
        const q = query(requisitionsCollection, where("estado", "==", "Rechazada"), orderBy("correlativo", "desc"));
        const requisitionsSnapshot = await getDocs(q);
        const requisitionsList = requisitionsSnapshot.docs.map(reqDoc => ({
            id: reqDoc.id,
            ...reqDoc.data()
        }));

        //console.log(requisitionsList)
        return requisitionsList;
    } catch (error) {
        console.error("Error al obtener las requisiciones sin detalles: ", error);
        return [];
    }
}

// FUNCION PARA OBTENER TODAS LAS REQUISICIONES CON ESTADO "Eliminada" SIN SUS DETALLES
export async function getRequisitions_Deleted() {
    try {
        const requisitionsCollection = collection(db, 'requisitions');
        // Creamos una consulta para filtrar solo las requisiciones con estado "Solicitada"
        const q = query(requisitionsCollection, where("estado", "==", "Eliminada"), orderBy("correlativo", "desc"));
        const requisitionsSnapshot = await getDocs(q);
        const requisitionsList = requisitionsSnapshot.docs.map(reqDoc => ({
            id: reqDoc.id,
            ...reqDoc.data()
        }));

        //console.log(requisitionsList)
        return requisitionsList;
    } catch (error) {
        console.error("Error al obtener las requisiciones sin detalles: ", error);
        return [];
    }
}

//FUNCION PARA ELIMINAR LAS REQUISICIONES DE LA BASE DE DATOS
export async function deleteRequisitions(requisitions) {
    const batch = writeBatch(db);


    try {

        // Extrae los IDs de los objetos de requisición
        const idsToDelete = Array.isArray(requisitions) ? requisitions.map(req => req.id) : [requisitions.id];

        // Itera sobre cada ID y elimina la requisición y sus detalles
        for (const requisitionId of idsToDelete) {
            // Elimina la requisición principal
            const requiRef = doc(db, 'requisitions', requisitionId);
            batch.delete(requiRef);

            // Elimina los detalles asociados a la requisición
            const detailsQuery = query(collection(requiRef, 'requisition_detail'));
            const detailDocs = await getDocs(detailsQuery);

            detailDocs.forEach((detailDoc) => {
                batch.delete(detailDoc.ref);
            });
        }


        // Commit the batch
        await batch.commit();

        if (Array.isArray(requisitions)) {

            if (requisitions.length == 1) {
                return {
                    success: true,
                    message: `Requisición # ${requisitions[0].correlativo} eliminada con éxito.`,
                    id: idsToDelete, // Retornar los IDs de las requisiciones eliminadas
                };
            } else {
                return {
                    success: true,
                    message: `${requisitions.length} requisiciones han sido eliminadas.`,
                    id: idsToDelete, // Retornar los IDs de las requisiciones eliminadas
                };
            }

        } else {
            return {
                success: true,
                message: `Requisición # ${requisitions.correlativo} eliminada con éxito.`,
                id: requisitions.id, // Retornar el ID de la requisición eliminada
            };
        }
    } catch (error) {
        console.error("Error al eliminar las requisiciones: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al eliminar la requisición."
        }
    }
}

// FUNCION PARA ACTUALIZAR EL ESTADO DE UNA O MÁS REQUISICIONES A "Eliminada"
export async function updateRequisitionsToDeleted(requisitions, motivo) {
    const batch = writeBatch(db);

    try {
        // Extrae los IDs de los objetos de requisición
        const idsToUpdate = Array.isArray(requisitions) ? requisitions.map(req => req.id) : [requisitions.id];

        // Itera sobre cada ID y prepara la actualización en lote
        idsToUpdate.forEach(requiId => {
            const requiRef = doc(db, 'requisitions', requiId);
            batch.update(requiRef, { estado: 'Eliminada', motivo_eliminacion: motivo });
        });

        // Commit the batch
        await batch.commit();

        if (Array.isArray(requisitions)) {

            if (requisitions.length == 1) {
                return {
                    success: true,
                    message: `Requisición # ${requisitions[0].correlativo} eliminada con éxito.`,
                    id: idsToUpdate, // Retornar los IDs de las requisiciones eliminadas
                };
            } else {
                return {
                    success: true,
                    message: `${requisitions.length} requisiciones han sido eliminadas.`,
                    id: idsToUpdate, // Retornar los IDs de las requisiciones eliminadas
                };
            }

        } else {
            return {
                success: true,
                message: `Requisición # ${requisitions.correlativo} eliminada con éxito.`,
                id: requisitions.id, // Retornar el ID de la requisición eliminada
            };
        }
    } catch (error) {
        console.error("Error al actualizar el estado de las requisiciones: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al eliminar la requisicion."
        }
    }
}

// FUNCION PARA OBTENER UNA REQUISICION ESPECIFICA CON SUS DETALLES
export async function getRequisitionWithDetailsById(requisitionId) {
    try {
        // Acceder a la requisición específica por id
        const requisitionDocRef = doc(db, 'requisitions', requisitionId);
        const requisitionSnapshot = await getDoc(requisitionDocRef);

        if (requisitionSnapshot.exists()) {
            // Construimos el objeto de la requisición
            const requisitionData = {
                id: requisitionSnapshot.id,
                ...requisitionSnapshot.data()
            };

            // Consultamos la subcolección 'requisition_detail' para la requisición actual
            const detailCollection = collection(requisitionDocRef, 'requisition_detail');
            const detailSnapshot = await getDocs(detailCollection);

            // Mapeamos los detalles en el objeto de la requisición
            const details = detailSnapshot.docs.map(detailDoc => ({
                id: detailDoc.id,
                ...detailDoc.data()
            }));

            requisitionData.details = details; // Asignamos los detalles a la requisición

            return requisitionData; // Retornamos la requisición con detalles
        } else {
            console.log("No se encontró la requisición con el ID: ", requisitionId);
            return null; // O manejar como se considere apropiado
        }
    } catch (error) {
        console.error("Error al obtener la requisición con detalles: ", error);
        return null; // O manejar como se considere apropiado
    }
}

//Función para actualizar una requisición con sus detalles
export async function updateRequisitionWithDetails(requisitionId, requisitionData, detailsList) {
    const batch = writeBatch(db);

    try {

        // Verificar si el campo 'setNextCoorelativo' es verdadero y asignar el siguiente correlativo
        if (requisitionData.setNextCoorelativo) {
            const nextCorrelativo = await incrementRequisitionCounter();
            requisitionData.correlativo = nextCorrelativo;
            delete requisitionData.setNextCoorelativo;  // Eliminar el campo 'setNextCoorelativo' antes de guardar
        }

        // Paso 0: Asignar un nuevo orden a cada detalle

        // Asigna un valor de 'order' solo si no existe
        detailsList.forEach((detail, index) => {
            if (detail.order === undefined) {
                detail.order = index;
            }
        });

        for (let i = 0; i < detailsList.length; i++) {
            detailsList[i].order = i; // Asigna el nuevo orden
        }

        // Paso 1: Actualiza el documento principal de la requisición
        const requisitionRef = doc(db, 'requisitions', requisitionId);
        batch.update(requisitionRef, requisitionData);

        // Paso 2: Obtén los detalles actuales de la requisición para saber cuáles eliminar
        const detailsCollectionRef = collection(requisitionRef, 'requisition_detail');
        const currentDetailsSnapshot = await getDocs(detailsCollectionRef);
        const currentDetailsIds = currentDetailsSnapshot.docs.map(doc => doc.id);

        // Filtrar los detalles actuales que no estén en los nuevos detalles
        const newDetailsIds = detailsList.filter(detail => detail.id).map(detail => detail.id);
        const detailsToDelete = currentDetailsIds.filter(id => !newDetailsIds.includes(id));

        // Paso 3: Eliminar los detalles que ya no están presentes
        detailsToDelete.forEach(detailId => {
            const detailRef = doc(detailsCollectionRef, detailId);
            batch.delete(detailRef);
        });

        // Paso 4: Actualizar los detalles existentes o agregar nuevos

        for (const detail of detailsList) {
            if (detail.id) {
                // El detalle existe, actualiza o elimina
                const detailRef = doc(detailsCollectionRef, detail.id);

                // Si el archivo es nulo, significa que no hay archivo para subir o mantener
                if (detail.file === null) {
                    delete detail.file; // Elimina la propiedad para evitar errores
                } else if (typeof detail.file === 'string') {
                    // No hace falta actualizar el archivo ya que es una URL
                } else if (detail.file instanceof File) {
                    // Nuevo archivo para subir
                    const fileURL = await uploadInvoiceFile(detail.file, detail.id + 'fac');
                    detail.file = fileURL;
                    if (!fileURL) {
                        await logError("Requisiciones", "Error subiendo el archivo de la Factura para " + detail.id, detail.file.name);
                    }
                }

                // Si el archivo es nulo, significa que no hay archivo para subir o mantener
                if (detail.cotizacion === null) {
                    delete detail.cotizacion; // Elimina la propiedad para evitar errores
                } else if (typeof detail.cotizacion === 'string') {
                    // No hace falta actualizar el archivo ya que es una URL
                } else if (detail.cotizacion instanceof File) {
                    // Nuevo archivo para subir
                    const fileURL = await uploadInvoiceFile(detail.cotizacion, detail.id + 'cot');
                    detail.cotizacion = fileURL;
                    if (!fileURL) {
                        await logError("Requisiciones", "Error subiendo el archivo de la Cotizacion para " + detail.id, detail.cotizacion.name);
                    }
                }

                // Elimina la propiedad id para evitar errores al guardar
                delete detail.id;
                batch.update(detailRef, detail);
            } else {
                // El detalle es nuevo, crea un nuevo detalle
                const newDetailRef = doc(detailsCollectionRef); // Crea un nuevo documento en la subcolección 'requisition_detail'
                let fileURL = '';
                if (detail.file instanceof File) {
                    // Sube el archivo y obtén la URL
                    fileURL = await uploadInvoiceFile(detail.file, newDetailRef.id + 'fac');
                    if (!fileURL) {
                        await logError("Requisiciones", "Error subiendo el archivo de la Factura para " + newDetailRef.id, detail.file.name);
                    }
                }
                // Asigna la URL del archivo o deja la propiedad vacía si no hay archivo
                detail.file = fileURL || '';

                let fileURL2 = '';

                if (detail.cotizacion instanceof File) {
                    // Sube el archivo y obtén la URL
                    fileURL2 = await uploadInvoiceFile(detail.cotizacion, newDetailRef.id + 'cot');
                    if (!fileURL2) {
                        await logError("Requisiciones", "Error subiendo el archivo de la Cotizacion para " + newDetailRef.id, detail.cotizacion.name);
                    }
                }
                // Asigna la URL del archivo o deja la propiedad vacía si no hay archivo
                detail.cotizacion = fileURL2 || '';

                batch.set(newDetailRef, detail);
            }
        }

        // Paso 5: Ejecuta todas las operaciones en batch
        await batch.commit();

        return {
            success: true,
            message: "Requisición actualizada con éxito.",
            id: requisitionId,
        };
    } catch (error) {
        console.error("Error al actualizar la requisición con detalles: ", error);
        await logError("Requisiciones", "Error al actualizar la requisición con detalles", error.message);
        return {
            success: false,
            message: "Ocurrió un error al actualizar la Requisición.",
            error: error,
        };
    }
}

//Funcion para aprobar o rechazar requisicion
export async function updateRequisitionStatus(requisition, newState, rejectionReason = '') {
    try {
        // Referencia al documento de la requisición
        const requisitionRef = doc(db, 'requisitions', requisition.id);

        // Preparar el objeto de actualización
        const updateData = { estado: newState };
        if (newState === 'Rechazada' && rejectionReason) {
            updateData.motivo_rechazo = rejectionReason;
        }

        // Actualizar la requisición
        await updateDoc(requisitionRef, updateData);

        return {
            success: true,
            message: `Requisición #${requisition.correlativo} '${newState}'.`,
        };
    } catch (error) {
        console.error(`Error al actualizar el estado de la requisición a '${newState}': `, error);
        return {
            success: false,
            message: `Ocurrió un error al actualizar el estado de la Requisición a '${newState}'.`,
            error: error,
        };
    }
}
