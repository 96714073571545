import React, { useState, useEffect } from 'react';

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo, updateUserInfo, uploadFile, changePasswordAndEmail, changeEmail } from '../../firebase/firebase';
import { Timestamp } from 'firebase/firestore';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import DatePicker from "react-datepicker";
import { es } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";


import './editusr.css';

function Editusr(props) {

    const customLocale = {
        ...es,
        localize: {
            ...es.localize,
            month: (monthIndex) => {
                const monthShortNames = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
                return monthShortNames[monthIndex];
            }
        }
    }

    const navigate = useNavigate();

    const [showDetalles, setshowDetalles] = useState(true);
    const [showDataContacto, setshowDataContacto] = useState(false);
    const [showDataPass, setshowDataPass] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordN, setShowPasswordN] = useState(false);
    const [showPasswordV, setShowPasswordV] = useState(false);

    const [PassN, setPassN] = useState("");
    const [PassV, setPassV] = useState("");
    const [OldPass, setOldPass] = useState("");

    const [userLoged, setUserLoged] = useState();

    const [imgfile, setImgfile] = useState();
    const [ImgTemp, setImgTemp] = useState();
    const [avatarUpdate, setAvatarUpdate] = useState(false);
    const [userUpdate, setUserUpdate] = useState(false);

    const [ifNameUpdate, setIfNameUpdate] = useState(false);
    const [ifEmailUpdate, setIfEmailUpdate] = useState(false);
    const [ifGeneroUpdate, setifGeneroUpdate] = useState(false);
    const [ifFechaNacUpdate, setifFechaNacUpdate] = useState(false);
    const [ifTelUpdate, setifTelUpdate] = useState(false);
    const [ifFechaIniUpdate, setifFechaIniUpdate] = useState(false);
    const [ifClaveActualUpdate, setifClaveActualUpdate] = useState(false);
    const [ifClaveNuevaUpdate, setifClaveNuevaUpdate] = useState(false);
    const [ifClaveNueva2UpdateUpdate, setifClaveNueva2Update] = useState(false);



    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);


    //para validar si esta iniciada la sesion
    useEffect(() => {
        onAuthStateChanged(auth, handleUserStateChanged);
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                setUserLoged(user);
                const res = await getUserInfo(user.uid);

                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni,
                        cumple: res.cumple,
                        tel: res.tel,
                        foto: res.foto
                    }));

                    setLoading(false);

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
            }

        } else {
            navigate('/login');
        }
    }



    const changeViewPersona = () => {
        setshowDetalles(true);
        setshowDataContacto(false);
        setshowDataPass(false);
    }

    const changeViewContac = () => {
        setshowDetalles(false);
        setshowDataContacto(true);
        setshowDataPass(false);
    }

    const changeViewPass = () => {
        setshowDetalles(false);
        setshowDataContacto(false);
        setshowDataPass(true);
    }

    //para capturar los datos
    const HandleinputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'Pass':
                setOldPass(value);
                setifClaveActualUpdate(true);
                break;
            case 'PassN':
                setPassN(value);
                setifClaveNuevaUpdate(true);
                break;
            case 'PassV':
                setPassV(value);
                setifClaveNueva2Update(true);
                break;
            default:
        }

        setUserUpdate(true);
    };

    const InputChangeUsr = (event) => {
        const { name, value } = event.target;
        setUserUpdate(true);

        if (name === 'fechaIni' || name === 'cumple') {

            const dateParts = value.split('-').map(part => parseInt(part, 10));
            const dateObject = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2] + 1));


            // Convierte el objeto Date en un objeto Timestamp de Firebase
            const timestamp = Timestamp.fromDate(dateObject);

            setUseUser((prevState) => ({
                ...prevState,
                [name]: timestamp
            }));

        } else {
            setUseUser((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }

        switch (name) {
            case 'nombre':
                setIfNameUpdate(true);
                break;
            case 'genero':
                setifGeneroUpdate(true);
                break;
            case 'cumple':
                setifFechaNacUpdate(true);
                break;
            case 'email':
                setIfEmailUpdate(true);
                break;
            case 'tel':
                setifTelUpdate(true);
                break;
            case 'fechaIni':
                setifFechaIniUpdate(true);
                break;
            case 'Pass':
                setifClaveActualUpdate(true);
                break;
            case 'PassN':
                setifClaveNuevaUpdate(true);
                break;
            case 'PassV':
                setifClaveNueva2Update(true);
                break;
        }

    };


    const [isGenderOpen, setGenderOpen] = useState(false);
    const [selectedGender, setSelectedGender] = useState(useUser.genero);

    const handleGenderChange = (gender) => {
        setSelectedGender(gender);
        setGenderOpen(false);
        InputChangeUsr({ target: { name: 'genero', value: gender } }); // Actualiza el estado 'genero'
    };

    // Efecto para manejar el clic fuera del menú
    useEffect(() => {
        function handleDocumentClick(event) {
            // Verificar si el clic es fuera del menú de género
            if (isGenderOpen && !event.target.closest('.custom-select-container')) {
                setGenderOpen(false);
            }

        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isGenderOpen]);

    function formatDate(timestamp) {
        //console.log(timestamp);
        const date = new Date(timestamp.seconds * 1000);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        //console.log('DIA: ', day);

        //console.log(`${day}/${month}/${year}`);
        //return `${day}/${month}/${year}`;
        return `${year}-${month}-${day}`;
    }

    const [errorPass, setErrorPass] = useState(false);
    const [errorPassV, setErrorPassV] = useState(false);

    const UpdateUser = async (e) => {

        try {
            e.preventDefault();
            let res = null;

            if (ifClaveActualUpdate && ifClaveNuevaUpdate) {
                if (PassN === PassV && PassN != "") {
                    const { success, message, error } = await changePasswordAndEmail(OldPass, PassV, useUser.email);
                    if (!success) {
                        props.showErr('Contraseña actual incorrecta');
                        props.ShowWar(message);
                        if (message == "La contraseña debe tener al menos 6 caracteres.") {
                            setErrorPassV(true);
                        } else {
                            setErrorPass(true);
                            setErrorPassV(false);

                        }
                        return;
                    }
                } else {
                    props.showErr('La nueva contraseña no coincide');
                    setErrorPassV(true);
                    return;

                }
            }

            if (ifEmailUpdate && ifClaveActualUpdate && PassV == "") {
                const { success, message, error } = await changeEmail(useUser.email, OldPass);
                if (!success) {
                    props.showErr('Contraseña actual incorrecta');
                    props.ShowWar(message);
                    setErrorPass(true);
                    setErrorPassV(false);
                    return;
                }

            } else if (ifEmailUpdate && !ifClaveActualUpdate) {
                props.ShowWar("Para cambiar el correo electronico debe introducir su Contraseña Actual en la seccion \"Seguridad\"");
                return;
            }

            if (avatarUpdate) {
                const usravatar = await uploadFile(imgfile, userLoged.uid);

                // Usar una variable local para almacenar el nuevo estado
                const newUserState = {
                    ...useUser,
                    foto: usravatar
                };

                setImgTemp(newUserState.foto)

                // Actualizar el estado con la variable local
                setUseUser(newUserState);

                res = await updateUserInfo(userLoged.uid, newUserState);
                setAvatarUpdate(false);

            } else {
                res = await updateUserInfo(userLoged.uid, useUser);
            }

            if (res) {
                setUserUpdate(false);
                props.ShowDone('Usuario actualizado exitosamente');
                navigate('/user');
            } else {
                props.showErr(res);
            }

        } catch (error) {
            props.showErr(error);
        }
    };



    const SelectImg = async (e) => {
        //console.log(e.target.files[0]);
        const file = e.target.files[0];
        if (file) {
            setImgfile(file);
            setImgTemp(URL.createObjectURL(file));
        }


        setAvatarUpdate(true);
        setUserUpdate(true);

    };

    return (
        <>
            <div className='homeContainer' >
                {!loading && (
                    < HeaderHome userLoged={useUser} />
                )}
                <div className='bodyHome' style={{ height: "125vh" }}>
                    {!loading && (
                        < Menu userLoged={useUser} />
                    )}
                    <form onSubmit={UpdateUser} className='form-ajustes'>
                        <div className='bodyHome-ajustes'>
                            <p>Ajustes</p>
                        </div>
                        <div className='horizontal-menu'>
                            <span onClick={changeViewPersona} className={showDetalles ? 'selected-option' : ''}>Detalles Personales</span>
                            <span onClick={changeViewContac} className={showDataContacto ? 'selected-option' : ''}>Detalles de Contacto</span>
                            <span onClick={changeViewPass} className={showDataPass ? 'selected-option' : ''}>Seguridad</span>
                        </div>
                        <div>
                            {showDetalles ? (
                                <>
                                    <div className='personal-details'>
                                        <div className="left-column">
                                            <label>Nombre Completo</label>
                                            <input id='nombre' name='nombre' onChange={InputChangeUsr} value={useUser.nombre} placeholder='Escribe tu nombre completo' style={{ borderColor: ifNameUpdate ? "#3A93F7" : "initial" }} />
                                            <div className="custom-select-container">
                                                <label>Género</label>
                                                <button className="boton-desplegable-ajustes" type="button" onClick={() => setGenderOpen(!isGenderOpen)}>
                                                    {useUser.genero || "Selecciona tu género"}
                                                    {isGenderOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                                </button>

                                                {isGenderOpen && (
                                                    <div className="dropdown-container-ajustes">
                                                        <ul>
                                                            {["Femenino", "Masculino"].map((gender, index) => (
                                                                <li key={index}>
                                                                    <button className="boton-desplegable-ajustes" type="button" onClick={() => handleGenderChange(gender)}>
                                                                        {gender}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            <label>Fecha de nacimiento</label>
                                            <input type="date" id='cumple' name='cumple' onChange={InputChangeUsr} value={formatDate(useUser.cumple)} style={{ borderColor: ifFechaNacUpdate ? "#3A93F7" : "initial" }} />
                                        </div>
                                        <div className="right-column">
                                            <img src={ImgTemp ? ImgTemp : useUser.foto} alt='Preview' />
                                            <input type="file" name="srcimg" id="srcimg" accept="image/*" style={{ display: 'none' }} onChange={SelectImg} />
                                            <label htmlFor="srcimg" style={{ cursor: 'pointer' }}>
                                                <span>Cambiar foto de perfil</span>
                                            </label>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {showDataContacto ? (
                                <>
                                    <div className='contact-details'>
                                        <label>Correo Electrónico</label>
                                        <input type='email' id='email' name='email' onChange={InputChangeUsr} value={useUser.email} placeholder='ejemplo@mail.com' style={{ borderColor: ifEmailUpdate ? "#3A93F7" : "initial" }} />
                                        <label>Número de teléfono</label>
                                        <input pattern="\d*" id='tel' name='tel' onChange={InputChangeUsr} value={useUser.tel} placeholder='Escribe tu número de teléfono' style={{ borderColor: ifTelUpdate ? "#3A93F7" : "initial" }} />

                                        <label>Fecha de inicio en Codegua</label>
                                        <input type="date" id='fechaIni' name='fechaIni' onChange={InputChangeUsr} value={formatDate(useUser.fechaIni)} style={{ borderColor: ifFechaIniUpdate ? "#3A93F7" : "initial" }} />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {showDataPass ? (
                                <>
                                    <div className='security-details'>
                                        <label>Contraseña actual</label>
                                        <div className='inputIcon'>
                                            <input type={showPassword ? "text" : "password"} id='Pass' name='Pass' onChange={HandleinputChange} placeholder='Escribe tu contraseña actual' value={OldPass} style={{ borderColor: errorPass ? "red" : (ifClaveActualUpdate ? "#3A93F7" : "initial") }} />
                                            {showPassword ? (
                                                <AiOutlineEyeInvisible className='eyeIcon-securityA' onClick={() => setShowPassword(!showPassword)} />
                                            ) : (
                                                <AiOutlineEye className='eyeIcon-securityA' onClick={() => setShowPassword(!showPassword)} />
                                            )}
                                        </div>
                                        <label>Nueva contraseña</label>
                                        <div className='inputIcon'>
                                            <input type={showPasswordN ? "text" : "password"} id='PassN' name='PassN' onChange={HandleinputChange} placeholder='Escribe tu nueva contraseña' value={PassN} style={{ borderColor: ifClaveNuevaUpdate ? "#3A93F7" : "initial" }} />
                                            {showPasswordN ? (
                                                <AiOutlineEyeInvisible className='eyeIcon-securityA' onClick={() => setShowPasswordN(!showPasswordN)} />
                                            ) : (
                                                <AiOutlineEye className='eyeIcon-securityA' onClick={() => setShowPasswordN(!showPasswordN)} />
                                            )}
                                        </div>
                                        <label>Verificación nueva contraseña</label>
                                        <div className='inputIcon'>
                                            <input type={showPasswordV ? "text" : "password"} id='PassV' name='PassV' onChange={HandleinputChange} placeholder='Escribe nuevamente tu nueva contraseña' value={PassV} style={{ borderColor: errorPassV ? "red" : (ifClaveNueva2UpdateUpdate ? "#3A93F7" : "initial") }} />
                                            {showPasswordV ? (
                                                <AiOutlineEyeInvisible className='eyeIcon-securityA' onClick={() => setShowPasswordV(!showPasswordV)} />
                                            ) : (
                                                <AiOutlineEye className='eyeIcon-securityA' onClick={() => setShowPasswordV(!showPasswordV)} />
                                            )}
                                        </div>
                                    </div>

                                </>
                            ) : (
                                <></>
                            )}

                        </div>
                        <div className='button-container-ajustes'>
                            {userUpdate ? (
                                <button type='submit' className='botonPrincipal-Ajustes'>Guardar cambios</button>
                            ) : (
                                <button type='submit' className='botonSecondary-Ajustes' disabled={true}>Guardar cambios</button>
                            )}
                        </div>
                    </form>
                </div >
            </div >
        </>
    );

}

export default Editusr;