import React, { useEffect, useState, useRef, useMemo } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//modal de carga
import LoadingModal from '../Loading-Modal/loading.jsx'

//import para funciones especificas de esta pantalla
import { getAllVouchers, getAccountsDetail, updateVouchersToDeleted, updateVouchersToDelivered } from '../../firebase/vouchers'
import * as XLSX from 'xlsx';

import ReactDatePicker from 'react-datepicker';

//import de iconos
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { CiMenuKebab } from 'react-icons/ci';
import { FaSearch } from "react-icons/fa";
import { GrFormEdit } from 'react-icons/gr';
import { FiTrash2, FiDownload, FiPrinter } from 'react-icons/fi';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';

//modal para eliminar
import ModalEliminarVoucher from '../Modal-Eliminar-Requi/Modal-Eliminar-Requi.jsx'
import ModalMotivoEliminacion from '../Modal-Eliminar-Requi/Modal-Motivo-Eliminacion.jsx'

import "./toggle.css"


function FechaSelector({ fechaInicio, setFechaInicio, fechaFin, setFechaFin, onChange, onClose }) {

    const setLastWeek = () => {
        const today = new Date();
        const startLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7);
        const endLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 1);
        setFechaInicio(startLastWeek);
        setFechaFin(endLastWeek);
        onChange(startLastWeek, endLastWeek);
    };

    const setToday = () => {
        const today = new Date();
        setFechaInicio(today);
        setFechaFin(today);
        onChange(today, today);
    };

    const setCurrentMonth = () => {
        const today = new Date();
        setFechaInicio(new Date(today.getFullYear(), today.getMonth(), 1));
        setFechaFin(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        onChange(new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0));
    };

    const setPreviousMonth = () => {
        const today = new Date();
        setFechaInicio(new Date(today.getFullYear(), today.getMonth() - 1, 1));
        setFechaFin(new Date(today.getFullYear(), today.getMonth(), 0));
        onChange(new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0));
    };

    const ref = useRef();

    // Llamar a onChange cuando se actualizan las fechas
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose();
            }
        }

        // Agregar listener al montar
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Eliminar listener al desmontar
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div ref={ref} className="selector-fecha">
            <div className="custom-buttons-date">
                <label style={{ marginRight: "5px", marginLeft: "10px" }}>Inicio:</label>
                <ReactDatePicker
                    selected={fechaInicio}
                    onChange={date => {
                        setFechaInicio(date);
                        if (fechaFin) { // Si ya se ha seleccionado la fecha de fin
                            onChange(date, fechaFin);
                        }
                    }}

                    selectsStart
                    startDate={fechaInicio}
                    endDate={fechaFin}
                    dateFormat="dd/MM/yyyy"
                />
                <label style={{ marginRight: "5px", marginLeft: "10px" }}>Fin:</label>
                <ReactDatePicker
                    selected={fechaFin}
                    onChange={date => {
                        setFechaFin(date);
                        onChange(fechaInicio, date); // Usando la prop onChange
                        onClose();
                    }}

                    selectsEnd
                    startDate={fechaInicio}
                    endDate={fechaFin}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div className="custom-buttons-fechas">
                <button type="button" onClick={setToday}>Hoy</button>
                <button type="button" onClick={setLastWeek}>Semana Pasada</button>
                <button type="button" onClick={setCurrentMonth}>Mes Actual</button>
                <button type="button" onClick={setPreviousMonth}>Mes Pasado</button>
            </div>

        </div>

    );
}


function HistorialVouchers(props) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar el modal de carga


    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);

    //Estado para almacenar los voucher a mostrar
    const [Vouchers, setVouchers] = useState([]);
    const [accounts, setAccounts] = useState();
    const [proveedores, setProveedores] = useState();


    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                setIsLoading(true);
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //Cargar datos para usar en la pantalla al cargar
                    setVouchers(await getAllVouchers());
                    //setAccounts(await getAccountsDetail());
                    //setProveedores(await getProviders());


                    setLoading(false); // para saber si termino de cargar los datos del usuario
                    setIsLoading(false);

                    const canAccess = await hasPermission(res.rol, "voucher/historial");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }
                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                    setIsLoading(false);
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    const [dropdownOpen, setDropdownOpen] = useState({});

    //AQUI ESTA EL PAGINADOR
    const [currentPage, setCurrentPage] = useState(() => {
        const savedPage = localStorage.getItem('currentPageVouchers');
        return savedPage ? Number(savedPage) : 1;
    });
    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // Calcula los Vouchers actuales para la página actual
    const totalItems = Vouchers.length;
    const [totalPages, setTotalPages] = useState(0);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        localStorage.setItem('currentPageVouchers', pageNumber);
    };

    //seccion para filtros

    const [searchCheque, setSearchCheque] = useState('');
    const [SearchAccount, setSearchAccount] = useState('');
    const [selectedAccountName, setSelectedAccountName] = useState('');
    const [searchProveedor, setSearchProveedor] = useState('');
    const [searchFecha, setSearchFecha] = useState('');

    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);

    const [isAccountOpen, setAccountOpen] = useState(false);
    const [isProveedorOpen, setProveedorOpen] = useState(false);
    const [mostrarSelectorFechas, setMostrarSelectorFechas] = useState(false);


    const InputChangeReq = (event) => {
        event.preventDefault();

        const { name, value } = event.target;
        if (name === 'cheque') {
            if (/^\d*$/.test(value)) {
                setSearchCheque(value);
                //local storage
                saveFiltersToLocalStorage({
                    searchCheque: value,
                    searchFecha,
                    SearchAccount,
                    searchProveedor,
                    fechaInicio,
                    fechaFin
                });
                setCurrentPage(1);
            } else {
                props.ShowWar('Esta campo solo admite números')
            }
        } else if (name === 'fecha') {
            setSearchFecha(value);
        }
        if (value) {
            document.getElementById(name).classList.add('input-active');
        } else {
            document.getElementById(name).classList.remove('input-active');
        }
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador
    };
    const [fechaActiva, setFechaActiva] = useState(false);
    const mostrarSelector = () => {
        setFechaActiva(true);
        setMostrarSelectorFechas(true);
    };

    const cerrarSelectorFecha = () => {
        setMostrarSelectorFechas(false); // Asume que 'setMostrarSelectorFechas' es tu función para controlar la visibilidad
    };

    const manejadorCambioFecha = (inicio, fin) => {
        const formatoFecha = fecha => fecha.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const fechaFormateada = `${formatoFecha(inicio)} - ${formatoFecha(fin)}`;
        setSearchFecha(`${formatoFecha(inicio)} - ${formatoFecha(fin)}`);
        setFechaInicio(inicio);
        setFechaFin(fin);

        //local storage
        saveFiltersToLocalStorage({
            searchCheque,
            searchFecha: fechaFormateada,
            SearchAccount,
            searchProveedor,
            fechaInicio: inicio,
            fechaFin: fin
        });
        setCurrentPage(1);
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador
    };

    const filteredVouchers = useMemo(() => Vouchers.filter(vou => {
        // Convertir la fecha de los voucher desde un timestamp de Firebase a un objeto Date
        const fechaVouchers = new Date(vou.check.date.seconds * 1000);
        const fechaVouchersInicio = new Date(fechaVouchers.setHours(0, 0, 0, 0));
        const fechaVouchersFin = new Date(fechaVouchers.setHours(23, 59, 59, 999));

        // Establecer el inicio y fin del día para la fechaInicio
        const startOfDay = fechaInicio ? new Date(fechaInicio.setHours(0, 0, 0, 0)) : null;
        const endOfDay = fechaFin ? new Date(fechaFin.setHours(23, 59, 59, 999)) : null;

        // Verificar si la fecha de el voucher está dentro del rango seleccionado
        const enRangoDeFechas = !startOfDay || !endOfDay || (fechaVouchersFin >= startOfDay && fechaVouchersInicio <= endOfDay);

        return (
            String(vou.check.number).includes(String(searchCheque)) &&
            enRangoDeFechas &&
            (SearchAccount === '' || vou.account.account_number === SearchAccount) &&
            (searchProveedor === '' || vou.check.beneficiary === searchProveedor)
        );
    }), [Vouchers, searchCheque, SearchAccount, searchProveedor, fechaInicio, fechaFin]);

    useEffect(() => {
        const filteredAccounts = [];
        const filteredProviders = [];

        filteredVouchers.forEach(vou => {
            if (vou.account && !filteredAccounts.some(acc => acc.id === vou.account.id)) {
                filteredAccounts.push({
                    id: vou.account.id,
                    name: vou.account.name,
                    bank: vou.account.bank,
                    currency: vou.account.currency,
                    account_number: vou.account.account_number
                });
            }
            if (vou.check && !filteredProviders.some(prov => prov.id === vou.check.beneficiary_id)) {
                filteredProviders.push({
                    id: vou.check.beneficiary_id,
                    name: vou.check.beneficiary
                });
            }
        });

        // Ordenar alfabeticamente
        filteredAccounts.sort((a, b) => a.name.localeCompare(b.name));
        filteredProviders.sort((a, b) => a.name.localeCompare(b.name));

        setAccounts(filteredAccounts);
        setProveedores(filteredProviders);
    }, [filteredVouchers]);

    useEffect(() => {
        setTotalPages(Math.ceil(filteredVouchers.length / itemsPerPage));
    }, [filteredVouchers, itemsPerPage]);

    const [executionCount, setExecutionCount] = useState(0);

    //Restaurar los Filtros desde localStorage al Cargar la Página
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('filtersVOucher'));
        if (savedFilters) {
            setSearchCheque(savedFilters.searchCheque || '');
            setSearchFecha(savedFilters.searchFecha || '');
            setSearchAccount(savedFilters.SearchAccount || '');
            setSearchProveedor(savedFilters.searchProveedor || '');
            setFechaInicio(savedFilters.fechaInicio ? new Date(savedFilters.fechaInicio) : null);
            setFechaFin(savedFilters.fechaFin ? new Date(savedFilters.fechaFin) : null);
        }

        const savedPage = localStorage.getItem('currentPageVouchers');

        if (savedPage) {
            setCurrentPage(Number(savedPage));
        }

    }, []);

    useEffect(() => {
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador

        const lastURL = localStorage.getItem('lastURL');
        if ((executionCount >= 3) || (lastURL != 'voucher/historial')) {
            setCurrentPage(1);
            localStorage.setItem('currentPageVouchers', 1);
            localStorage.setItem('lastURL', 'voucher/historial');
        }
    }, [searchCheque, SearchAccount, searchProveedor, fechaInicio, fechaFin]);

    //SECCION PARA MANEJAR HISTORIAL EN FILTROS POR LOCALSTORAGE

    //Guardar los Filtros en localStorage
    const saveFiltersToLocalStorage = (filters) => {
        localStorage.setItem('filtersVOucher', JSON.stringify(filters));
    };

    const currentVouchers = filteredVouchers.slice(indexOfFirstItem, indexOfLastItem);

    // Calcular el total de elementos filtrados
    const totalElementosFiltrados = filteredVouchers.length;

    // Calcular la suma total en Quetzales
    const sumaTotalQuetzales = filteredVouchers.reduce((total, vou) => {
        return total + vou.check.amount;
    }, 0);

    //funcion para actualizar el estado SearchAccount
    const handleProjectChange = (event, accountId) => {
        event.preventDefault();
        event.stopPropagation();

        let Account = '';

        if (accountId === null) {
            setSearchAccount('');
            setSelectedAccountName('');
        } else {
            const selectedAccount = accounts.find(p => p.id === accountId);
            if (selectedAccount) {
                setSearchAccount(selectedAccount.account_number);
                setSelectedAccountName(selectedAccount.name);
                Account = selectedAccount.account_number
            }
        }
        setAccountOpen(false);

        //local storage
        saveFiltersToLocalStorage({
            searchCheque,
            searchFecha,
            SearchAccount: Account,
            searchProveedor,
            fechaInicio,
            fechaFin
        });
        setCurrentPage(1);
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador
    };

    //funcion para actualizar el estado de SearchProveedor
    const handleProveedorChange = (event, proveedorId) => {
        event.preventDefault();

        let proveedorName = '';

        if (proveedorId === null) {
            setSearchProveedor('');
        } else {
            const selectedProveedor = proveedores.find(p => p.id === proveedorId);
            if (selectedProveedor) {
                setSearchProveedor(selectedProveedor.name);
                proveedorName = selectedProveedor.name;

            }
        }
        setProveedorOpen(false);

        //local storage
        saveFiltersToLocalStorage({
            searchCheque,
            searchFecha,
            SearchAccount,
            searchProveedor: proveedorName,
            fechaInicio,
            fechaFin
        });
        setCurrentPage(1);
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador
    };

    //para manejar el menu seleccionable por opcion en la tabla
    const toggleDropdown = (VouId) => {
        setDropdownOpen(prevState => {
            const isCurrentlyOpen = !!prevState[VouId];
            const newState = {};

            // Close all dropdowns
            Object.keys(prevState).forEach(key => {
                newState[key] = false;
            });

            // If the dropdown was not already open, then open it
            if (!isCurrentlyOpen) {
                newState[VouId] = true;
            }

            return newState;
        });
    };

    const dropdownRefs = useRef({});
    const accountRef = useRef(null);
    const proveedorRef = useRef(null);


    const setDropdownRef = (node, id) => {
        if (node) {
            dropdownRefs.current[id] = node;
        }
    };

    //useeffect para manejar el click afuera del menu
    useEffect(() => {
        const handleDocumentClick = event => {
            // Verificar si se hizo clic fuera de los menús desplegables existentes
            const isDropdownClicked = Object.keys(dropdownRefs.current).some(key => {
                return dropdownRefs.current[key] && dropdownRefs.current[key].contains(event.target);
            });

            // Verificar si se hizo clic fuera del menú de cuantas
            const isProjectClicked = accountRef.current && accountRef.current.contains(event.target);

            // Verificar si se hizo clic fuera del menú de Beneficiario
            const isProveedorClicked = proveedorRef.current && proveedorRef.current.contains(event.target);

            // Cerrar los menús si es necesario
            if (!isDropdownClicked) {
                setDropdownOpen({});
            }
            if (!isProjectClicked) {
                setAccountOpen(false);
            }
            if (!isProveedorClicked) {
                setProveedorOpen(false);
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);


    //AQUI ESTA EL MODAL
    const [showModalForOneId, setShowModalForOneId] = useState(null);
    const [isModal2Open, setIsModal2Open] = useState(false);
    const [motivoEliminacion, setMotivoEliminacion] = useState(""); // Estado local para el motivo de eliminación


    const [showMultiDeleteModal, setShowMultiDeleteModal] = useState(false);

    //FUNCION PARA ELIMINAR SOLO UN VOUCHER
    const deleteVoucher = async (voucher) => {

        setIsLoading(true);
        try {

            const result = await updateVouchersToDeleted(voucher);

            if (result.success) {

                // Actualizar el estado para excluir los Vouchers eliminados exitosamente
                setVouchers(prevVouchers => prevVouchers.filter(vou => !result.id.includes(vou.id)));

                // Cerrar el modal una vez que se haya completado la eliminación
                setShowMultiDeleteModal(false);

                props.ShowDone(result.message)

            } else {
                props.showErr(result.message);

            }
            setIsLoading(false);

        } catch (error) {
            props.showErr('Ocurrio un error inesperado.');
            console.log(error);
            setIsLoading(false);
        }
    };

    const handleModalDelete = (VouId) => {

        const ToDelete = Vouchers.find(u => u.id === VouId);

        if (ToDelete) {

            deleteVoucher(ToDelete);
        }
        setShowModalForOneId(null);  // Cerrar el modal
    };


    function formatDateOnly(timestamp) {
        // Crear un objeto Date usando el valor de seconds
        const date = new Date(timestamp.seconds * 1000);

        // Opciones para formatear solo la fecha
        const options = {
            year: 'numeric', month: '2-digit', day: '2-digit'
        };

        // Formatear y devolver solo la fecha
        return date.toLocaleDateString('es', options);
    }


    const goToEditVoucher = async (vou) => {
        navigate('/voucher/edit', { state: { vou } });
    };

    const goToVerVoucher = async (vou) => {
        navigate('/voucher/ver', { state: { vou } });
    };

    const goToPrintVoucher = async (vou) => {
        navigate('/voucher/print', { state: { vou } });
    };


    function onClickExport(data) {

        // Mapear y formatear los datos para la exportación
        const formattedData = data.map(item => ({
            "CORRELATIVO": item.correlativo,
            "FECHA_CREACION": new Date(item.date_creation.seconds * 1000).toLocaleDateString('es-GT'),
            "ESTADO": item.estado,

            "NUMERO_CUENTA": item.account.account_number,
            "NOMBRE_CUENTA": item.account.name,
            "BANCO_CUENTA": item.account.bank,
            "MONEDA_CUENTA": item.account.currency,

            "NUMERO_CHEQUE": item.check.number,
            "FECHA_CHEQUE": new Date(item.check.date.seconds * 1000).toLocaleDateString('es-GT'),
            "BENEFICIARIO_CHEQUE": item.check.beneficiary,
            "DESCRIPCIÓN_CHEQUE": item.check.descripcion,
            "MONTO_CHEQUE": {
                v: item.check.amount,
                t: 'n',
                z: '"Q"#,##0.00' // Formato de moneda para Quetzales
            }
        }));

        const d = new Date();
        const dateString = [
            d.getDate().toString().padStart(2, '0'),
            (d.getMonth() + 1).toString().padStart(2, '0'),
            d.getFullYear().toString().substring(2)
        ].join('-') + '_' + [
            d.getHours().toString().padStart(2, '0'),
            d.getMinutes().toString().padStart(2, '0'),
            d.getSeconds().toString().padStart(2, '0')  // Incluir los segundos aquí
        ].join('');

        const fileName = "Historial_Vouchers_" + dateString;

        exportToExcel(fileName, formattedData);
    }

    //funcion para generar el archivo en Excel
    function exportToExcel(datedoc, formattedData) {

        // Crear una hoja de trabajo con los datos formateados
        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Obtener el rango de celdas de la hoja de trabajo
        const range = XLSX.utils.decode_range(worksheet['!ref']);

        // Aplicar negritas a la primera fila (encabezados)
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const headerCell = XLSX.utils.encode_cell({ c: C, r: 0 });
            worksheet[headerCell].s = {
                font: {
                    bold: true
                }
            };
        }

        // Definir los anchos de las columnas 
        worksheet['!cols'] = [
            { wch: 5 }, // # columna
            { wch: 15 }, // Fecha columna
            { wch: 15 }, // ESTADO columna
            { wch: 25 }, // NUMERO_CUENTA columna
            { wch: 40 }, // NOMBRE_CUENTA columna
            { wch: 30 }, // BANCO_CUENTA columna
            { wch: 10 }, // MONEDA_CUENTA columna
            { wch: 25 }, // NUMERO_CHEQUE columna
            { wch: 15 }, // FECHA_CHEQUE columna
            { wch: 40 }, // BENEFICIARIO_CHEQUE columna
            { wch: 50 }, // DESCRIPCIÓN_CHEQUE columna
            { wch: 15 } // MONTO_CHEQUE columna

        ];

        // Crear un libro de trabajo y añadir la hoja
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Vouchers");

        // Escribe el libro de trabajo en formato binario
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Función para convertir la cadena de datos binarios a un Blob
        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        // Crear un Blob con los datos en formato binario
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });


        // Crear un enlace y hacer clic en él para descargar
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = datedoc + '.xlsx'; // Sugerir un nombre de archivo predeterminado
        document.body.appendChild(link); // Necesario para que Firefox funcione correctamente
        link.click();
        document.body.removeChild(link); // Limpiar el DOM

    }

    //funcion para actualizar el estado del voucher
    async function DeliverVoucher(voucher) {
        setIsLoading(true);
        try {
            const updatedVoucher = { ...voucher, estado: 'Entregado' };
            const result = await updateVouchersToDelivered(updatedVoucher);

            if (result.success) {
                setVouchers((prevVouchers) =>
                    prevVouchers.map((v) => (v.id === voucher.id ? { ...v, estado: 'Entregado' } : v))
                );

                props.ShowDone(result.message);
            } else {
                props.showErr(result.message);
            }
        } catch (error) {
            props.showErr('Ocurrió un error inesperado.');
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const [toggledStates, setToggledStates] = useState({});

    // Esta función se llama al montar el componente y cuando se actualizan los Vouchers.
    useEffect(() => {
        const newToggledStates = {};
        Vouchers.forEach((voucher) => {
            newToggledStates[voucher.id] = voucher.estado === 'Entregado';
        });
        setToggledStates(newToggledStates);
    }, [Vouchers]);


    const toggleStatus = async (voucher) => {
        // Solo permite cambiar el estado si el voucher está actualmente 'Pendiente'
        if (voucher.estado === 'Pendiente') {
            setToggledStates({
                ...toggledStates,
                [voucher.id]: true,
            });
            await DeliverVoucher(voucher);
        }
    };


    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' style={{ height: "215vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div style={{ width: "100%" }}>
                            <div className='titulo-container-solicitud'>
                                <p style={{ fontWeight: "500" }}>Historial de Vouchers</p>
                                <button onClick={() => onClickExport(filteredVouchers)}><FiDownload style={{ marginRight: "10px" }} />Exportar</button>
                            </div>
                            <div className='filter-users-historial'>
                                <form onSubmit={(e) => e.preventDefault()} >
                                    <div className="form-row">
                                        <div className="form-column">
                                            <label># de Cheque</label>
                                            <div className="historial-icon-container">
                                                <input id='cheque' name='cheque' onChange={InputChangeReq} placeholder="Escribe el # de cheque" value={searchCheque} />
                                                {searchCheque &&
                                                    <AiFillCloseCircle className="historial-clear-icon" onClick={() => {
                                                        setSearchCheque('');
                                                        //limpiar local storage
                                                        saveFiltersToLocalStorage({
                                                            searchCheque: '',
                                                            searchFecha,
                                                            SearchAccount,
                                                            searchProveedor,
                                                            fechaInicio,
                                                            fechaFin
                                                        });
                                                        document.getElementById('cheque').classList.remove('input-active');
                                                    }} />}
                                            </div>
                                        </div>
                                        <div className="form-column form-column-fecha">
                                            <label>Fecha</label>
                                            <div className="historial-icon-container"><input id='fecha' name='fecha' className={` ${fechaActiva ? 'input-active' : ''}`} onClick={(e) => {
                                                e.stopPropagation(); // Detiene la propagación del evento
                                                mostrarSelector();
                                            }}
                                                placeholder='Seleccione una fecha' readOnly value={searchFecha} />
                                                {searchFecha && <AiFillCloseCircle className="historial-clear-icon" onClick={() => {
                                                    setSearchFecha('');
                                                    setFechaInicio(null);
                                                    setFechaFin(null);
                                                    setFechaActiva(false);
                                                    //limpiar local storage
                                                    saveFiltersToLocalStorage({
                                                        searchCheque,
                                                        searchFecha: '',
                                                        SearchAccount,
                                                        searchProveedor,
                                                        fechaInicio: '',
                                                        fechaFin: ''
                                                    });
                                                }} />}
                                            </div>

                                            {mostrarSelectorFechas && (
                                                <div className="fecha-selector-container">
                                                    <FechaSelector
                                                        fechaInicio={fechaInicio}
                                                        setFechaInicio={setFechaInicio}
                                                        fechaFin={fechaFin}
                                                        setFechaFin={setFechaFin}
                                                        onChange={manejadorCambioFecha}
                                                        onClose={cerrarSelectorFecha}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-column">
                                            <label>Cuenta Bancaria</label>

                                            <button className="boton-form-column" style={{
                                                marginTop: "5px", border: SearchAccount ? "1px solid #3A93F7" : ""
                                            }} onClick={() => setAccountOpen(!isAccountOpen)} >
                                                {accounts.find(p => p.account_number === SearchAccount)
                                                    ? `${accounts.find(p => p.account_number === SearchAccount).name} - ${accounts.find(p => p.account_number === SearchAccount).bank}`
                                                    : "Selecciona una cuenta bancaria"}
                                                {isAccountOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                            </button>

                                            {isAccountOpen && (
                                                <div className="dropdown-historial" ref={accountRef}>
                                                    <ul>
                                                        <li>
                                                            <button className="boton-form-column" type="button" onClick={(e) => handleProjectChange(e, null)}>
                                                                Selecciona una cuenta bancaria
                                                            </button>
                                                        </li>
                                                        {accounts.map(account => (
                                                            <li key={account.id}>
                                                                <button style={{ fontSize: "9px" }} className="boton-form-column" type="button" onClick={(e) => handleProjectChange(e, account.id)} >
                                                                    {account.name} - {account.bank} [{account.currency}]
                                                                </button>
                                                            </li>
                                                        ))}

                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-column">
                                            <label>Beneficiario</label>

                                            <button className="boton-form-column" style={{
                                                marginTop: "5px", marginTop: "5px", border: searchProveedor ? "1px solid #3A93F7" : ""
                                            }} type="button" onClick={() => setProveedorOpen(!isProveedorOpen)} >
                                                {searchProveedor || "Selecciona un beneficiario"}
                                                {isProveedorOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                            </button>
                                            {isProveedorOpen && (
                                                <div className="dropdown-historial" ref={proveedorRef}>
                                                    <ul>
                                                        <li>
                                                            <button className="boton-form-column" type="button" onClick={(e) => handleProveedorChange(e, null)}>
                                                                Selecciona un beneficiario
                                                            </button>
                                                        </li>
                                                        {proveedores.map(proveedor => (
                                                            <li key={proveedor.id}>
                                                                <button className="boton-form-column" type="button" onClick={(e) => handleProveedorChange(e, proveedor.id)}>
                                                                    {proveedor.name}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="label-historial">
                                <label>Total: Q {sumaTotalQuetzales.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label>
                                <label>Cantidad: {totalElementosFiltrados}</label>
                            </div>

                            <div className='user-details-requis-solicitadas'>
                                <table className='tabla-requis-solicitadas'>
                                    <thead>
                                        <tr className='tabla-header'>
                                            <th style={{ paddingTop: "2%", paddingBottom: "2%", paddingLeft: "1.5%", width: "8%" }}># Cheque</th>
                                            <th style={{ width: "11%" }}>Fecha</th>
                                            <th style={{ width: "22%" }}>Cuenta Bancaria</th>
                                            <th style={{ width: "15%" }}>Beneficiario</th>
                                            <th style={{ width: "10%" }}>Total</th>
                                            <th style={{ width: "12%" }}>Estado</th>
                                            <th style={{ width: "4%" }}></th>
                                        </tr>
                                        <tr className="separator">
                                            <td colSpan="7"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentVouchers.slice(0, 10).map((vou) => (

                                            <React.Fragment key={vou.id}>
                                                <tr key={vou.id} style={{ height: "75px" }}>
                                                    <td style={{ paddingLeft: "1%", wordBreak: "break-all", paddingRight: "0.5%" }}>{vou.check.number}</td>
                                                    <td>{formatDateOnly(vou.check.date)}</td>
                                                    <td>{vou.account.account_number} [ {vou.account.name} ]</td>
                                                    <td>{vou.check.beneficiary}</td>
                                                    <td>Q {vou.check.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td>
                                                        <div className={`toggle-container ${toggledStates[vou.id] ? 'toggled' : ''}`} onClick={() => toggleStatus(vou)}>
                                                            <div className={`toggle-button ${!toggledStates[vou.id] ? '' : 'toggled'}`}>
                                                                <div className="toggle-indicator"></div>
                                                            </div>
                                                            <div className="status-text">
                                                                {toggledStates[vou.id] ? 'Entregado' : 'Pendiente'}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='menu-usuarios-requis-solicitadas'
                                                            ref={node => setDropdownRef(node, vou.id)}
                                                            onClick={(e) => e.stopPropagation()}>
                                                            <button className="icon-button-requis-solicitadas"
                                                                onClick={() => toggleDropdown(vou.id)}>
                                                                <CiMenuKebab /></button>

                                                            {!showMultiDeleteModal && dropdownOpen[vou.id] && (
                                                                <div className="dropdownMenu-requis-solicitadas">
                                                                    <ul>
                                                                        <li><a style={{ marginRight: "3px" }} className="dontCloseDropdown-requis-solicitadas" onClick={() => goToVerVoucher(vou)}><FaSearch style={{ marginRight: "3px", marginLeft: "5px" }}></FaSearch >Ver</a></li>
                                                                        {vou.estado === 'Entregado' ?
                                                                            "" :
                                                                            <li><a style={{ marginRight: "3px" }} className="dontCloseDropdown-requis-solicitadas" onClick={() => goToEditVoucher(vou)}><GrFormEdit style={{ marginRight: "3px", marginLeft: "5px" }}></GrFormEdit>Editar</a></li>
                                                                        }

                                                                        <li><a style={{ marginRight: "3px" }} className="dontCloseDropdown-requis-solicitadas" onClick={() => goToPrintVoucher(vou)}><FiPrinter style={{ marginRight: "3px", marginLeft: "5px" }}></FiPrinter>Imprimir</a></li>
                                                                        <li style={{ borderBottom: "none" }}><a className="dontCloseDropdown-requis-solicitadas" onClick={() => { setShowModalForOneId(vou.id); setDropdownOpen({}); }}><FiTrash2 style={{ marginRight: "3px", marginLeft: "5px" }}></FiTrash2>Eliminar</a></li>
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="separator">
                                                    <td colSpan="7"></td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='paginador-usuarios'>
                                    <span>{currentPage} de {totalPages} </span>
                                    <div>
                                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}><MdArrowBackIosNew /> Anterior</button>
                                        {currentPage > 1 && <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>}
                                        <button style={{ fontWeight: "bold" }} className={currentPage === currentPage ? "selected-page" : ""}>{currentPage}</button>
                                        {currentPage < totalPages && <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>}
                                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Siguiente <MdArrowForwardIos /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showModalForOneId && (
                <ModalEliminarVoucher
                    voucher={Vouchers.find((u) => u.id === showModalForOneId)}
                    initialOpen={true}
                    handleClose={() => setShowModalForOneId(null)}
                    onDelete={() => {
                        handleModalDelete(showModalForOneId);
                    }}
                />
            )}

            {isModal2Open && (
                <ModalMotivoEliminacion
                    handleClose={() => {
                        setIsModal2Open(false)
                        setShowModalForOneId(null)
                    }} // Cierra el Modal 2
                    onAceptar={(motivo) => {
                        // Actualiza el estado local con el motivo de eliminación
                        setMotivoEliminacion(motivo);
                        // Llama a handleModalDelete pasando el motivo

                        handleModalDelete(showModalForOneId, motivo);
                        setIsModal2Open(false); // Cierra el Modal 2 después de ejecutar la lógica
                    }}
                />
            )}
            {isLoading && <LoadingModal />}
        </>
    );
}

export default HistorialVouchers;