import React, { useState, useEffect } from 'react';

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//import para funciones especificas de esta pantalla
import { serverTimestamp } from "firebase/firestore";
import { registerUser, getRoles } from "../../firebase/usuarios_roles"
import defaultProfileImage from '../../assets/images/default_profile.jpg';


//import de iconos
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

//modal de carga
import LoadingModal from '../Loading-Modal/loading.jsx'


import './editar-nuevo-usuario.css';

function Nuevo_Usuario(props) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar el modal de carga


    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);

    const [userDetails, setUserDetails] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: serverTimestamp(),
        cumple: serverTimestamp(),
        tel: '',
        foto: '',
        ultimo_log: serverTimestamp()
    });
    const [nuevoCorreo, setNuevoCorreo] = useState();
    const [nuevaPass, setNuevaPass] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [userUpdate, setUserUpdate] = useState(false);

    const [ImgTemp, setImgTemp] = useState(defaultProfileImage);
    const [imgfile, setImgfile] = useState(defaultProfileImage);
    const [avatarUpdate, setAvatarUpdate] = useState(false);

    const [roles, setRoles] = useState([]);



    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //cargar los roles
                    const fetchedRoles = await getRoles();
                    setRoles(fetchedRoles);

                    fetch(defaultProfileImage)
                        .then(res => res.blob())
                        .then(blob => {
                            setImgfile(blob);
                        })
                        .catch(error => {
                            console.error("Error al convertir la imagen predeterminada en Blob:", error);
                        });

                    setLoading(false); // para saber si termino de cargar los datos del usuario

                    const canAccess = await hasPermission(res.rol, "permisos/usuarios");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A', rol: 'N/A', email: 'N/A', genero: 'N/A', fechaIni: 'N/A', cumple: 'N/A', tel: 'N/A', foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    const InputChangeUsr = (event) => {

        const { name, value } = event.target;

        setUserUpdate(true);

        if (name === 'Pass') {
            setNuevaPass(value);
        } else {
            setUserDetails((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    }

    const [isRoleOpen, setRoleOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState("");

    const handleRoleChange = (role) => {
        console.log(role)
        setSelectedRole(role);
        setRoleOpen(false);
        InputChangeUsr({ target: { name: 'rol', value: role } }); // Actualiza el estado 'rol'
    };

    // Efecto para manejar el clic fuera del menú
    useEffect(() => {
        function handleDocumentClick(event) {
            // Verificar si el clic es fuera del menú de roles
            if (isRoleOpen && !event.target.closest('.field-container-proyecto')) {
                setRoleOpen(false);
            }

        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isRoleOpen]); // Dependencias del efecto

    const SelectImg = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setImgfile(file);
            setImgTemp(URL.createObjectURL(file));
        }


        setAvatarUpdate(true);
        setUserUpdate(true);

    };

    const CreateUser = async (e) => {
        setIsLoading(true);
        try {

            e.preventDefault();

            const { success, message, error } = await registerUser(userDetails.email, nuevaPass, userDetails, imgfile)
            if (success) {
                props.ShowDone(message);
                navigate('/permisos/usuarios');
            } else {
                props.showErr(message);
                props.ShowWar(error);
            }
            setIsLoading(false);

        } catch (error) {
            props.showErr("No es posible registrar el usuario");
            setIsLoading(false);

        }

    };


    return (
        <>
            {!loading && (
                <div>
                    <HeaderHome userLoged={useUser} />

                    <div className='bodyHome-user' style={{ height: "135vh" }}>
                        <Menu userLoged={useUser} />
                        <div>
                            <p>Nuevo Usuario</p>
                            <div className='personal-details-usuario'>
                                <div className="left-column">
                                    <form>
                                        <label>Nombre Completo</label>
                                        <input id='nombre' name='nombre' onChange={InputChangeUsr} placeholder='Escribe el nombre del usuario' />
                                        <label>Correo Electrónico</label>
                                        <input type='email' id='email' name='email' onChange={InputChangeUsr} placeholder='Escribe el correo electrónico' />
                                        <div className="field-container-proyecto" style={{ width: "100%" }}>
                                            <label style={{ marginTop: "0" }}>Rol</label>
                                            <button className="boton-desplegable" style={{ fontSize: "medium", width: "100%" }} type="button" onClick={() => setRoleOpen(!isRoleOpen)}>
                                                {selectedRole || "Selecciona un rol"}
                                                {isRoleOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                            </button>

                                            {isRoleOpen && (
                                                <div className="dropdown-container-proyecto">
                                                    <ul>
                                                        {roles.map((role, index) => (
                                                            <li key={index}>
                                                                <button className="boton-desplegable" style={{ fontSize: "medium", width: "100%" }} type="button" onClick={() => handleRoleChange(role)}>
                                                                    {role}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        <label>Contraseña</label>
                                        <div className='inputIcon'>
                                            <input type={showPassword ? "text" : "password"} id='Pass' name='Pass' onChange={InputChangeUsr} placeholder='Escribe una contraseña' />
                                            {showPassword ? (
                                                <AiOutlineEyeInvisible className='eyeIcon-security' onClick={() => setShowPassword(!showPassword)} />
                                            ) : (
                                                <AiOutlineEye className='eyeIcon-security' onClick={() => setShowPassword(!showPassword)} />
                                            )}
                                        </div>
                                    </form>
                                </div>
                                <div className="right-column">
                                    <img src={ImgTemp ? ImgTemp : userDetails.foto} alt='Preview' />
                                    <input type="file" name="srcimg" id="srcimg" accept="image/*" style={{ display: 'none' }} onChange={SelectImg} />
                                    <label htmlFor="srcimg" style={{ cursor: 'pointer' }}>
                                        <span>Cambiar foto de perfil</span>
                                    </label>
                                </div>
                            </div>
                            <div className='button-container-nuevo-usuario'>
                                {userUpdate ? (
                                    <button type='submit' className='botonPrincipal' onClick={CreateUser}>Crear Usuario</button>
                                ) : (
                                    <button type='submit' className='botonSecondary' disabled={true}>Crear Usuario</button>
                                )}
                            </div>
                        </div>
                    </div >
                </div >
            )
            }
            {isLoading && <LoadingModal />}
        </>
    );

}

export default Nuevo_Usuario;