import React, { useEffect, useState, useRef } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//modal de carga
import LoadingModal from '../Loading-Modal/loading.jsx'


//import para funciones especificas de esta pantalla
import { getRequisitions_requested, deleteRequisitions, getRequisitionWithDetailsById } from '../../firebase/requisiciones'

import { Link } from 'react-router-dom';

//import de iconos
import { CiMenuKebab } from 'react-icons/ci';
import { GrFormEdit } from 'react-icons/gr';
import { FiTrash2 } from 'react-icons/fi';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';

//modal para eliminar
import Modal from '../Modal/Modal.jsx'

import './requisicion_solicitadas.css'

function Requisiciones_Solicitadas(props) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar el modal de carga

    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);

    //Estado para almacenar las requisiciones a mostrar
    const [requisiciones, setRequisiciones] = useState([]);


    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                setIsLoading(true);
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //Cargar datos para usar en la pantalla al cargar
                    setRequisiciones(await getRequisitions_requested());

                    setLoading(false); // para saber si termino de cargar los datos del usuario
                    setIsLoading(false);

                    const canAccess = await hasPermission(res.rol, "requisicion/nueva");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }
                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                    setIsLoading(false);
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    const [update, setUpdate] = useState(false);

    // Estado para rastrear qué requisiciones han sido seleccionados
    const [selectedRequi, setSelectedRequi] = useState([]);
    const [selectAll, setSelectAll] = useState(false); // Estado para el checkbox de la cabecera

    const [dropdownOpen, setDropdownOpen] = useState({});



    //AQUI ESTA EL PAGINADOR
    const [currentPage, setCurrentPage] = useState(() => {
        const savedPage = localStorage.getItem('currentPageSolicitadas');
        return savedPage ? Number(savedPage) : 1;
    });
    const [itemsPerPage] = useState(4);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // Calcula las requisiciones actuales para la página actual
    const currentRequisiciones = requisiciones.slice(indexOfFirstItem, indexOfLastItem);

    const totalItems = requisiciones.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage) || 1;

    const paginate = (pageNumber) => {
        // Al cambiar de página, resetea el estado de los checkboxes
        setSelectAll(false);
        setSelectedRequi([]);
        setCurrentPage(pageNumber);
        localStorage.setItem('currentPageSolicitadas', pageNumber);
    };

    //Restaurar los Filtros desde localStorage al Cargar la Página
    useEffect(() => {

        const savedPage = localStorage.getItem('currentPageSolicitadas');

        if (savedPage) {
            setCurrentPage(Number(savedPage));
        }

        const lastURL = localStorage.getItem('lastURL');

        if (lastURL != 'requisicion/nueva') {
            setCurrentPage(1);
            localStorage.setItem('currentPageSolicitadas', 1);
        }

        localStorage.setItem('lastURL', 'requisicion/nueva');

    }, []);

    const toggleDropdown = (requiId) => {
        setDropdownOpen(prevState => {
            const isCurrentlyOpen = !!prevState[requiId];
            const newState = {};

            // Close all dropdowns
            Object.keys(prevState).forEach(key => {
                newState[key] = false;
            });

            // If the dropdown was not already open, then open it
            if (!isCurrentlyOpen) {
                newState[requiId] = true;
            }

            return newState;
        });
    };


    const dropdownRefs = useRef({});

    const setDropdownRef = (node, id) => {
        if (node) {
            dropdownRefs.current[id] = node;
        }
    };

    // Esta función maneja el clic fuera del menú
    const handleClickOutside = (event) => {
        if (dropdownRefs.current) {
            // Cerrar todos los menús si se clickea fuera de ellos
            const allClosed = Object.keys(dropdownRefs.current).every((key) => {
                const ref = dropdownRefs.current[key];
                if (ref && !ref.contains(event.target)) {
                    return true;
                }
                return false;
            });

            if (allClosed) {
                setDropdownOpen({});
            }
        }
    };

    //useeffect para manejar el click afuera del menu
    useEffect(() => {
        const handleDocumentClick = event => {
            const isDropdownClicked = Object.keys(dropdownRefs.current).some(key => {
                return dropdownRefs.current[key] && dropdownRefs.current[key].contains(event.target);
            });

            if (!isDropdownClicked) {
                setDropdownOpen({});
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);


    //AQUI ESTA EL MODAL
    const [showModal, setShowModal] = useState(false);
    const [showModalForOneId, setShowModalForOneId] = useState(null);

    const [showMultiDeleteModal, setShowMultiDeleteModal] = useState(false);


    // Función para manejar la selección de una requisicion
    const handleRequiSelect = (requi, isSelected) => {
        if (isSelected) {
            setSelectedRequi([...selectedRequi, requi]);
        } else {
            setSelectedRequi(selectedRequi.filter(u => u.id !== requi.id));
            setSelectAll(false); // Desactivar el checkbox de la cabecera si un usuario se deselecciona
        }
    };

    // Función para manejar el cambio en el checkbox de la cabecera
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);

        if (!selectAll) {
            setSelectedRequi(currentRequisiciones);
        } else {
            setSelectedRequi([]);
        }
    };

    const handleShowModalMulti = () => {
        setShowMultiDeleteModal(true);
        setDropdownOpen({});
    };

    // Función para manejar la eliminación de requisiciones seleccionadas
    const handleDeleteselectedRequi = async () => {

        setIsLoading(true);
        try {

            const result = await deleteRequisitions(selectedRequi);

            if (result.success) {

                // Actualizar el estado para excluir las requisiciones eliminados exitosamente
                setRequisiciones(prevRequisiciones => prevRequisiciones.filter(requi => !result.id.includes(requi.id)));


                // Desactivar el checkbox de la cabecera
                setSelectAll(false);

                // Limpiar la lista de requisiciones seleccionados
                setSelectedRequi([]);

                // Cerrar el modal una vez que se haya completado la eliminación
                setShowMultiDeleteModal(false);

                props.ShowDone(result.message)

            } else {
                props.showErr(result.message);

            }
            setIsLoading(false);

        } catch (error) {
            props.showErr('Ocurrio un error inesperado.');
            console.log(error);
            setIsLoading(false);
        }

    };

    //FUNCION PARA ELIMINAR SOLO UNA REQUISICION
    const deleteRequi = async (requi) => {

        setIsLoading(true);
        try {

            const result = await deleteRequisitions(requi);

            if (result.success) {

                // Actualizar el estado para excluir las requisiciones eliminados exitosamente
                setRequisiciones(prevRequisiciones => prevRequisiciones.filter(requi => !result.id.includes(requi.id)));


                // Desactivar el checkbox de la cabecera
                setSelectAll(false);

                // Limpiar la lista de requisiciones seleccionados
                setSelectedRequi([]);

                // Cerrar el modal una vez que se haya completado la eliminación
                setShowMultiDeleteModal(false);

                props.ShowDone(result.message)

            } else {
                props.showErr(result.message);

            }
            setIsLoading(false);

        } catch (error) {
            props.showErr('Ocurrio un error inesperado.');
            console.log(error);
            setIsLoading(false);
        }
    };

    function formatDate(timestamp) {
        // Crear un objeto Date usando el valor de seconds
        const date = new Date(timestamp.seconds * 1000);

        // Opciones para formatear la fecha en el estilo deseado
        const options = {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', hour12: true
        };

        // Formatear la fecha
        let formattedDate = date.toLocaleDateString('es', options);

        // Reemplazar espacios no separables y ajustes para el formato de hora
        formattedDate = formattedDate
            .replace(/\u00A0/g, ' ') // Reemplazar espacios no separables por espacios normales
            .replace(/(\d+)\/(\d+)\/(\d+),/, '$1/$2/$3') // Reordenar fecha
            .replace(',', '') // Eliminar coma extra
            .replace(' a. m.', ' am') // Formatear AM
            .replace(' p. m.', ' pm'); // Formatear PM

        // Corregir la representación de "00:xx PM" a "12:xx PM"
        if (formattedDate.includes(' 00:') && formattedDate.includes(' pm')) {
            formattedDate = formattedDate.replace(' 00:', ' 12:');
        }

        return formattedDate;
    }



    function truncateText(text, wordLimit) {
        const wordsArray = text.split(' ');
        if (wordsArray.length > wordLimit) {
            return wordsArray.slice(0, wordLimit).join(' ') + '...';
        } else {
            return text;
        }
    }

    const goToEditRequi = async (req) => {

        const requiSel = await getRequisitionWithDetailsById(req.id)
        navigate('./editar', { state: { requiSel } });
    };

    const handleModalDelete = (requiID) => {
        const ToDelete = requisiciones.find(u => u.id === requiID);
        if (ToDelete) {
            deleteRequi(ToDelete);
        }
        setShowModalForOneId(null);  // Cerrar el modal
    };


    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' style={{ height: "100vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div style={{ width: "100%" }}>
                            <div className='titulo-container-requis-solicitadas'>
                                <p style={{ fontWeight: "500" }}>Requisiciones Solicitadas</p>
                                <button onClick={() => navigate("/requisicion/nueva/crear")}>+ Crear Requisición</button>
                            </div>

                            <div className='delete-requis-solicitadas' >
                                <button onClick={handleShowModalMulti} style={{ cursor: "pointer" }}><FiTrash2></FiTrash2> Eliminar</button>
                            </div>
                            {showMultiDeleteModal &&
                                <Modal
                                    selectedRequi={selectedRequi}
                                    initialOpen={true}
                                    close={() => setShowMultiDeleteModal(false)}
                                    onDelete={handleDeleteselectedRequi}
                                />
                            }
                            <div className='user-details-requis-solicitadas'>
                                <table className='tabla-requis-solicitadas'>
                                    <thead>
                                        <tr className='tabla-header'>
                                            <th style={{ paddingTop: "2%", paddingBottom: "2%", paddingLeft: "3%", width: "10%" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th style={{ width: "16%" }}>Solicita</th>
                                            <th style={{ width: "16%" }}>Enviado a las</th>
                                            <th style={{ width: "13%" }}>Proveedor</th>
                                            <th style={{ width: "23%" }}>Descripción</th>
                                            <th style={{ width: "15%" }}>Total</th>
                                            <th style={{ width: "9%" }}></th>
                                        </tr>
                                        <tr className="separator">
                                            <td colSpan="7"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentRequisiciones.slice(0, 4).map((requi) => (

                                            <React.Fragment key={requi.id}>
                                                <tr key={requi.id} style={{ height: "75px" }}>
                                                    <td style={{ paddingLeft: "3%" }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedRequi.some(u => u.id === requi.id)}
                                                            onChange={e => handleRequiSelect(requi, e.target.checked)}
                                                        />
                                                    </td>
                                                    <td>{requi.solicitante}</td>

                                                    <td>{formatDate(requi.fecha)}</td>
                                                    <td>{requi.proveedor.nombre}</td>
                                                    <td style={{ paddingRight: "5%" }}>{truncateText(requi.descripcion, 18)}</td>
                                                    <td>Q {requi.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>

                                                    <td>
                                                        <div className='menu-usuarios-requis-solicitadas'
                                                            ref={node => setDropdownRef(node, requi.id)}
                                                            onClick={(e) => e.stopPropagation()}>
                                                            <button className="icon-button-requis-solicitadas"
                                                                onClick={() => toggleDropdown(requi.id)}>
                                                                <CiMenuKebab /></button>

                                                            {!showMultiDeleteModal && dropdownOpen[requi.id] && (
                                                                <div className="dropdownMenu-requis-solicitadas">
                                                                    <ul>
                                                                        <li><a style={{ marginRight: "3px" }} className="dontCloseDropdown-requis-solicitadas" onClick={() => goToEditRequi(requi)}><GrFormEdit style={{ marginRight: "3px", marginLeft: "5px" }}></GrFormEdit>Editar</a></li>
                                                                        <li style={{ borderBottom: "none" }}><a className="dontCloseDropdown-requis-solicitadas" onClick={() => { setShowModalForOneId(requi.id); setDropdownOpen({}); }}><FiTrash2 style={{ marginRight: "3px", marginLeft: "5px" }}></FiTrash2>Eliminar</a></li>
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="separator">
                                                    <td colSpan="7"></td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='paginador-usuarios'>
                                    <span>{currentPage} de {totalPages} </span>
                                    <div>
                                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}><MdArrowBackIosNew /> Anterior</button>
                                        {currentPage > 1 && <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>}
                                        <button style={{ fontWeight: "bold" }} className={currentPage === currentPage ? "selected-page" : ""}>{currentPage}</button>
                                        {currentPage < totalPages && <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>}
                                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Siguiente <MdArrowForwardIos /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showModalForOneId &&
                <Modal
                    requi={requisiciones.find(u => u.id === showModalForOneId)}
                    initialOpen={true}
                    close={() => setShowModalForOneId(null)}
                    onDelete={() => handleModalDelete(showModalForOneId)}
                />
            }
            {isLoading && <LoadingModal />}
        </>
    );
}



export default Requisiciones_Solicitadas;