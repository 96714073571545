import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//import para funciones especificas de esta pantalla
import { fetchRolesAndPermissions, saveRolesToFirestore } from '../../firebase/usuarios_roles';
import ModalCrearRol from '../Modal-Crear-Rol/Modal-Crear-Rol';

import './roles.css'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';



function RolesAndPermissions(props) {

    const navigate = useNavigate();

    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);

    const [roles, setRoles] = useState({});

    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    // Este useEffect inicializa checkedPermissions
    useEffect(() => {
        LoadRolesAndPermissionsinStructure();
    }, [roles]);

    const LoadRolesAndPermissionsinStructure = () => {
        // Inicializar checkedPermissions
        const initialCheckedPermissions = {};
        Object.keys(roles).forEach((roleName) => {
            initialCheckedPermissions[roleName] = {};
            Object.keys(roles[roleName]).forEach((category) => {
                initialCheckedPermissions[roleName][category] = {};
                roles[roleName][category].forEach((permission) => {
                    initialCheckedPermissions[roleName][category][permission.name] = true; // Aquí cambiamos a true
                });
            });
        });

        // Luego, puedes actualizar el estado con estos valores iniciales
        setCheckedPermissions(initialCheckedPermissions);
    }

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //cargar los Roles  y Permisos
                    const fetchedRoles = await fetchRolesAndPermissions();
                    setRoles(fetchedRoles);

                    setLoading(false); // para saber si termino de cargar los datos del usuario

                    const canAccess = await hasPermission(res.rol, "permisos/roles");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    //DRAG AND DROP
    const [roleColumns, setRoleColumns] = useState([]);

    useEffect(() => {
        const roleNames = Object.keys(roles).filter((roleName) => roleName !== "admin");
        setRoleColumns(roleNames);
    }, [roles]);

    const DraggableHeader = ({ roleName, index, moveColumn }) => {
        const ref = React.useRef(null);

        const [, refDrop] = useDrop({
            accept: 'COLUMN',
            hover(item) {
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) return;
                moveColumn(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        });

        const [, refDrag] = useDrag({
            type: 'COLUMN',
            item: { index },
        });

        refDrag(refDrop(ref));

        return <th ref={ref}>{roleName}</th>;
    };

    const moveColumn = (fromIndex, toIndex) => {
        const updatedColumns = [...roleColumns];
        const [movedColumn] = updatedColumns.splice(fromIndex, 1);
        updatedColumns.splice(toIndex, 0, movedColumn);
        setRoleColumns(updatedColumns);
    };


    //Usestates para categorias expandidas
    const [expandedCategories, setExpandedCategories] = useState({});
    const [checkedPermissions, setCheckedPermissions] = useState({});

    const [renderCount, setRenderCount] = useState(0);
    const [update, setUpdate] = useState(false);

    //ORDEN
    const MENU_ORDER = ["Requisiciones", "Vouchers", "Proveedores", "Proyecto", "Empresa", "Cta. Bancaria", "Permisos"];

    //Estado para modal de crear rol
    const [showModal, setShowModal] = useState(false);

    //funciones para abrir y cerrar modal
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = async () => {
        setShowModal(false);
        //cargar los Roles  y Permisos
        const fetchedRoles = await fetchRolesAndPermissions();
        setRoles(fetchedRoles);
        LoadRolesAndPermissionsinStructure();
    };


    //Funcion para ver el click en lol titulos de las filas
    const toggleCategory = (category) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [category]: !prev[category],
        }));
    };

    //funcion para ver los checkbox
    const handleCheckboxChange = (roleName, category, permissionName, isChecked) => {
        setCheckedPermissions(prevState => {
            const newState = { ...prevState };
            if (!newState[roleName]) {
                newState[roleName] = {};
            }
            if (!newState[roleName][category]) {
                newState[roleName][category] = {};
            }
            newState[roleName][category][permissionName] = isChecked;
            return newState;
        });

        setUpdate(true);
    };

    // Obtener todos los permisos únicos en un array
    const allPermissions = Array.from(
        new Set(
            Object.values(roles).flatMap((role) =>
                Object.keys(role).flatMap((category) =>
                    role[category].map((perm) => perm.name)
                )
            )
        )
    );

    // Usar el rol "admin" como plantilla para la estructura de las filas
    const adminRole = roles["admin"] || {};
    const categories = Object.keys(adminRole);

    // Ordenar las categorías
    const sortedCategories = categories.sort((a, b) => {
        const indexA = MENU_ORDER.indexOf(a);
        const indexB = MENU_ORDER.indexOf(b);

        if (indexA === -1 && indexB === -1) return 0; // Ambas categorías no están en MENU_ORDER
        if (indexA === -1) return 1; // a no está en MENU_ORDER, debe ir después
        if (indexB === -1) return -1; // b no está en MENU_ORDER, debe ir después

        return indexA - indexB; // Ambas están en MENU_ORDER, ordenar según su posición
    });

    //enviar a guardar los roles y sus permisos
    const handleSaveChanges = async () => {

        try {

            const { success, message, error } = await saveRolesToFirestore(checkedPermissions, roles['admin']);

            if (success) {
                props.ShowDone(message);
                setUpdate(false);
                setRenderCount(renderCount + 1);
            } else {
                props.showErr(message);
                props.ShowWar(error);
            }

        } catch (error) {
            props.showErr("No es posible actualizar los permisos.");
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' style={{ height: "210vh" }}>
                        < Menu
                            userLoged={useUser}
                            key={renderCount}
                        />
                        <div style={{ width: "65%" }}>
                            <p>Roles</p>
                            <div className='filter-users-roles'>
                                <button onClick={handleOpenModal}>+ Crear un Rol</button>
                            </div>
                            {showModal && <ModalCrearRol handleClose={handleCloseModal}
                                showErr={props.showErr}
                                ShowDone={props.ShowDone}
                                ShowWar={props.ShowWar}
                            />}
                            <div className="roles-table">
                                <table>
                                    <thead>
                                        <tr className='tabla-header-roles'>
                                            <th>Permisos</th>
                                            {roleColumns.map((roleName, index) => (
                                                <DraggableHeader
                                                    key={roleName}
                                                    roleName={roleName}
                                                    index={index}
                                                    moveColumn={moveColumn}
                                                />
                                            ))}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categories.map((category, categoryIndex) => (
                                            <React.Fragment key={category}>
                                                <tr onClick={() => toggleCategory(category)} className="category-fila">
                                                    <td className="category-title" colSpan={Object.keys(roles).length}>
                                                        {category}
                                                        <span style={{ float: 'right', paddingRight: "15px" }}>
                                                            {expandedCategories[category] ? <IoIosArrowUp /> : <IoIosArrowDown />}
                                                        </span>
                                                    </td>
                                                </tr>
                                                {expandedCategories[category] &&
                                                    adminRole[category].map((permission, permissionIndex) => (
                                                        <React.Fragment key={permission.name}>
                                                            {/* Si permissionIndex es 0, no insertar un separador */}
                                                            {permissionIndex > 0 && (
                                                                <tr className="separator-tabla">
                                                                    <td colSpan={roleColumns.length + 1}></td>
                                                                </tr>
                                                            )}
                                                            <tr className="permisos-fila">
                                                                <td>{permission.name}</td>
                                                                {roleColumns.map((roleName) => (
                                                                    <td key={roleName}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                !!(
                                                                                    checkedPermissions[roleName] &&
                                                                                    checkedPermissions[roleName][category] &&
                                                                                    checkedPermissions[roleName][category][permission.name]
                                                                                )
                                                                            }
                                                                            onChange={(e) => handleCheckboxChange(roleName, category, permission.name, e.target.checked)}
                                                                        />
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                            </React.Fragment>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                            <div className='button-container-roles'>
                                {update ? (
                                    <button className='botonPrincipal-roles' onClick={handleSaveChanges}>Guardar cambios</button>
                                ) : (
                                    <button className='botonSecondary-roles' disabled={true}>Guardar cambios</button>
                                )}
                            </div>
                        </div>

                    </div>
                </div>

            )}
        </DndProvider>
    );

}

export default RolesAndPermissions;