import React, { useEffect, useState, useRef } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//para generar PDF
import PDFComponent, { getPDFStyles } from './pdfCreate';

//modal de carga
import LoadingModal from '../Loading-Modal/loading.jsx'

//import para funciones especificas de esta pantalla
import { useLocation } from 'react-router-dom';

import { getProjects, getProviders, getNextRequisitionNumber, updateRequisitionWithDetails, getRequisitionWithDetailsById } from '../../firebase/requisiciones'
import { getAllUsers } from '../../firebase/usuarios_roles';
import './solicitud.css'
import { CiFileOff } from 'react-icons/ci';
import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlineEye } from 'react-icons/ai';
import { MdUploadFile } from 'react-icons/md';
import { FiPrinter, FiUpload } from 'react-icons/fi';
import { MdOutlineFolderOpen } from "react-icons/md";
import './requisicion_solicitadas.css'
import './nueva_editar_requisicion.css'

function VerRequi(props) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar el modal de carga
    // constante para recibir el state desde la pantalla que solicito la edicion
    const location = useLocation();

    const { requiSel } = location.state || {}; // Asegúrate de manejar el caso en que state no esté definido

    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);


    //estados para cargar informacion
    const [proyectos, setProyectos] = useState();
    const [proveedores, setProveedores] = useState();
    const [correlativo, setCorrelativo] = useState(0);
    const [users, setUsers] = useState([]);



    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                setIsLoading(true);
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //Cargar datos para usar en la pantalla al cargar
                    setUsers(await getAllUsers());
                    setProyectos(await getProjects());
                    setProveedores(await getProviders());
                    setCorrelativo(await getNextRequisitionNumber());

                    setLoading(false); // para saber si termino de cargar los datos del usuario
                    setIsLoading(false);


                    const canAccess = await hasPermission(res.rol, "requisicion/historial");
                    if (!canAccess || !requiSel) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }

                    //cargar datos de la requisicion
                    loadDataRequisition();

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    //Menú desplegable en detalle requisición

    const [openMenuIndex, setOpenMenuIndex] = useState();

    const handleMenuClick = (index) => {
        if (openMenuIndex === index) {
            setOpenMenuIndex(null);
        } else {
            setOpenMenuIndex(index);
        }
    };

    const [update, setUpdate] = useState(false);

    //Campos State para uso de funcionalidades
    const [descripcion, setDescripcion] = useState('');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedProject, setSelectedProject] = useState(''); // Guarda el ID del proyecto seleccionado.
    const [selectedProjectName, setSelectedProjectName] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(''); // Guarda la empresa relacionada con el proyecto seleccionado.

    const [detalleItems, setDetalleItems] = useState([{ cotizacion: null, file: null, editable: true, isEditing: false, descripcion: '', pago: '', factura: '', unidadMedida: '', cantidad: '', precio: '', descuento: '', iva: '', isr: '', total: 0 }]);
    const [totalGeneral, setTotalGeneral] = useState(0);

    //funcion para cargar datos de la requisicion hacia los estados:
    const loadDataRequisition = () => {
        setCorrelativo(requiSel.correlativo)
        setCurrentDate(requiSel.fecha)
        setSelectedProjectName(requiSel.proyecto.nombre)
        setSelectedProject(requiSel.proyecto.id)
        setSelectedCompany(requiSel.proyecto.compania)
        setSelectedUserName(requiSel.solicitante)
        setSelectedUser(users.find(u => u.nombre === requiSel.solicitante)?.id || '')
        setSelectedProviderName(requiSel.proveedor.nombre)
        setSelectedProvider(requiSel.proveedor.id)
        setDescripcion(requiSel.descripcion)

        // Filtra los detalles para eliminar aquellos que no cumplan con los criterios
        const filteredDetails = requiSel.details.filter(detail => {
            // Comprueba si todos los campos, excepto 'id' y 'order', están vacíos o son 0
            return Object.entries(detail).some(([key, value]) => {
                if (key === 'id' || key === 'order') {
                    return false;
                }
                return value !== '' && value !== 0 && value != null;
            });
        });

        // Ordena los detalles filtrados según el campo 'order'
        const sortedDetails = filteredDetails.sort((a, b) => a.order - b.order);

        // Establece los detalles ordenados y filtrados
        setDetalleItems(sortedDetails);
    }

    useEffect(() => {
        loadDataRequisition();
        // Dependiendo de cómo esté implementada, es posible que necesites pasar `requiSel` o parte de él a `loadDataRequisition`
    }, [requiSel]); // Asegúrate de que este efecto se ejecute cuando `requiSel` cambie

    const formatDate = (timestamp) => {
        // Create a new JavaScript Date object based on the timestamp's seconds
        const date = new Date(timestamp.seconds * 1000); // Multiply by 1000 because JavaScript uses milliseconds
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const period = hours < 12 ? 'am' : 'pm';

        // Convert hours to 12-hour format
        const formattedHours = hours % 12 || 12;

        // Return the formatted string
        return `${day}/${month}/${year} ${formattedHours}:${minutes} ${period}`;
    };

    //funcion para controlar el cambio en el drop down de Proyecto
    const handleProjectChange = (projectId) => {

        const project = proyectos.find(p => p.id === projectId);

        if (project) {
            setSelectedProject(project.id);
            setSelectedProjectName(project.project_name);
            setSelectedCompany(project.project_company);
        } else {
            setSelectedProject(null);
            setSelectedProjectName("");
            setSelectedCompany('');
        }
        setProjectOpen(false);
    };

    //funcion para controlar el cambio en el drop down de Usuarios
    const handleUsersChange = (userID) => {

        const user = users.find(u => u.id === userID);

        if (user) {
            setSelectedUser(user.id);
            setSelectedUserName(user.nombre);
        } else {
            setSelectedUser(null);
            setSelectedUserName("");
        }
        setUsersOpen(false);
    };

    //Expreciones regulares para la seccion de Detalles
    const regex = {
        factura: /^[A-Za-z0-9_@$&%!*.-]+$/,
        unidadMedida: /^[A-Za-z0-9_@$&%!*.-]+$/,  // Permite letras, números y símbolos comunes.
        decimal: /^[0-9]+(\.[0-9]{0,2})?$/, // Números positivos con hasta dos decimales, sin ceros iniciales
        descuento: /^[0-9]+(\.[0-9]{0,2})?$/,
        iva: /^[0-9]+(\.[0-9]{0,2})?$/,
        isr: /^[0-9]+(\.[0-9]{0,2})?$/,
        cantidad: /^[0-9]+(\.[0-9]{0,2})?$/,
        precio: /^[0-9]+(\.[0-9]{0,2})?$/
    };

    //Funcion para validar si hay algun campo vacio en Detalle
    const anyFieldEmpty = () => {
        return detalleItems.some(item => {
            return Object.entries(item).some(([key, value]) => {
                if (key !== 'file' && key !== 'cotizacion') {
                    return value === '' || value === null;
                }
                return false; // Ignorar el campo 'file'
            });
        });
    };


    //funcion para llenar los campos de los detalles
    const handleChangeDetalle = (e, index, field) => {
        const value = e.target.value;
        // Si el campo es "descripción" o "pago", no aplicamos validación
        if (field === 'descripcion' || field === 'pago') {
            const newDetalleItems = [...detalleItems];
            newDetalleItems[index][field] = value;
            setDetalleItems(newDetalleItems);
            return;
        }

        // Para los demás campos, aplicamos validación
        if (value !== "" && regex[field] && !regex[field].test(value)) {
            // No cumple con la validación
            props.ShowWar('Valor no permitido')
            return;
        }

        const newDetalleItems = [...detalleItems];
        newDetalleItems[index][field] = value;

        // Verificando y calculando el total
        if (field === 'cantidad' || field === 'precio' || field === 'descuento' || field === 'iva' || field === 'isr') {
            const cantidad = parseFloat(newDetalleItems[index].cantidad) || 0;
            const precio = parseFloat(newDetalleItems[index].precio) || 0;
            const descuento = parseFloat(newDetalleItems[index].descuento) || 0;
            const subTotal = (cantidad * precio) - descuento || 0;
            const iva = parseFloat(newDetalleItems[index].iva) || 0;
            const isr = parseFloat(newDetalleItems[index].isr) || 0;
            newDetalleItems[index].total = subTotal - iva - isr || 0;
        }

        setDetalleItems(newDetalleItems);
    };


    //useEffect que se actualiza solo cuando cambia "detalleItems"
    useEffect(() => {
        const total = detalleItems.reduce((accum, item) => accum + (item.total || 0), 0);
        setTotalGeneral(total);
    }, [detalleItems]);

    //Para imprimir
    const pdfRef = useRef(null);

    const mostrarVistaPreviaImpresion = () => {

        const pdfStyles = getPDFStyles();
        if (pdfRef.current) {
            const contenido = pdfRef.current.innerHTML;
            const printWindow = window.open('', '_blank');
            if (printWindow) {  // Verificar que la ventana se haya abierto

                printWindow.document.write('<html><head><title>REQUISICION</title>');
                // Inyecta los estilos CSS
                printWindow.document.write(`<style>${pdfStyles}</style>`);
                printWindow.document.write('</head><body>');
                printWindow.document.write(contenido);
                printWindow.document.write('</body></html>');
                printWindow.document.close();

                // Evento que detecta el cierre de la ventana de impresión
                const checkWindowClosed = setInterval(() => {
                    if (printWindow.closed) {
                        window.focus();  // Devuelve el foco a la ventana principal
                        clearInterval(checkWindowClosed);  // Detiene la verificación
                        printWindow.close();
                    }
                }, 100);

                printWindow.addEventListener('afterprint', function () {
                    printWindow.close();
                });
                printWindow.print();
            } else {
                console.error("No se pudo abrir la ventana de impresión. Verifica los bloqueadores de ventanas emergentes.");
            }

        } else {
            console.error("Referencia no disponible");
        }
    };


    //estados para menu desplegable
    const [isOpen, setIsOpen] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [selectedProviderName, setSelectedProviderName] = useState('');

    const [isProjectOpen, setProjectOpen] = useState(false);
    const [isUsersOpen, setUsersOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUserName, setSelectedUserName] = useState('');



    //menu desplegable de proveedores
    const handleProviderClick = (providerId) => {
        setSelectedProvider(providerId);
        setIsOpen(false); // Cierra el menú después de seleccionar
        const provider = proveedores.find(p => p.id === providerId);
        if (provider) {
            setSelectedProviderName(provider.name);
        }
    };


    //cerrar el menu al darle click fuera
    useEffect(() => {
        function handleDocumentClick(event) {
            if (openMenuIndex !== null && !event.target.closest('.menu-dropdown-detalle-requi')) {
                setOpenMenuIndex(null);
                setIsOpen(false)
            }

            // Verificar si el clic es fuera del menú "card-informacion-solicitud-cheque"
            if (isOpen && !event.target.closest('.card-informacion-solicitud-proyecto')) {
                setIsOpen(false);
            }

            // Verificar si el clic es fuera del menú "field-container-proyecto"
            if (isProjectOpen && !event.target.closest('.field-container-proyecto')) {
                setProjectOpen(false);
            }

            // Verificar si el clic es fuera del menú "dropdown-container-users"
            if (isUsersOpen && !event.target.closest('.field-container-proyecto') && !event.target.closest('.users-button')) {
                setUsersOpen(false);
            }
        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [openMenuIndex, isOpen, isProjectOpen, isUsersOpen]);


    //para subir las Cotizacion
    // Añade un estado para el archivo seleccionado
    const [selectedCoti, setSelectedCoti] = useState(null);

    // Función que se ejecuta cuando el usuario selecciona un archivo
    const handleFileChange2 = (e, index) => {
        //console.log("COTIZACION")

        setOpenMenuIndex(null);

        const file = e.target.files[0];
        if (file) {
            setSelectedCoti(file);
            //console.log(URL.createObjectURL(file));
            handleAssignCotiToItem(index, file);
        }
    }

    // Función para asignar el archivo al item correspondiente
    const handleAssignCotiToItem = (index, file) => {
        const newDetalleItems = [...detalleItems];
        newDetalleItems[index].cotizacion = file;
        setDetalleItems(newDetalleItems);
        setSelectedCoti(null); // Resetear el archivo seleccionado
        setUpdate(true)
    }

    //para subir las facturas
    // Añade un estado para el archivo seleccionado
    const [selectedFile, setSelectedFile] = useState(null);

    // Función que se ejecuta cuando el usuario selecciona un archivo
    const handleFileChange = (e, index) => {
        setOpenMenuIndex(null);

        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            //console.log(URL.createObjectURL(file));
            handleAssignFileToItem(index, file);
        }
    }

    // Función para asignar el archivo al item correspondiente
    const handleAssignFileToItem = (index, file) => {
        const newDetalleItems = [...detalleItems];
        newDetalleItems[index].file = file;
        setDetalleItems(newDetalleItems);
        setSelectedFile(null); // Resetear el archivo seleccionado
        setUpdate(true)
    }

    //enviar requisicion a aprobar
    const handleSaveChanges = async () => {
        setIsLoading(true);
        try {

            //Creando la estructura de la requisicion
            const newRequisitionData = {
                proveedor: {
                    id: selectedProvider,
                    nombre: selectedProviderName
                },
                descripcion: descripcion,
                proyecto: {
                    id: selectedProject,
                    compania: selectedCompany,
                    nombre: selectedProjectName
                },
                correlativo: correlativo,
                solicitante: selectedUserName,
                emisor: requiSel.emisor,
                fecha: requiSel.fecha,
                estado: requiSel.estado,
                total: totalGeneral
            };

            //modificando la estructura de los detalles para esta requisicion
            const adaptedDetailsList = detalleItems.map(detail => {
                return {
                    ...detail,
                    cantidad: detail.cantidad ? parseFloat(detail.cantidad) : 0,
                    precio: detail.precio ? parseFloat(detail.precio) : 0,
                    descuento: detail.descuento ? parseFloat(detail.descuento) : 0,
                };
            }).map(({ editable, isEditing, order, ...rest }) => rest);  // Esta segunda operación con map elimina el campo "editable"

            const result = await updateRequisitionWithDetails(requiSel.id, newRequisitionData, adaptedDetailsList);

            if (result.success) {

                //Limpiar todos los datos
                setCurrentDate(new Date());
                setSelectedProject('');
                setSelectedProvider('');
                setSelectedProviderName('');
                setDescripcion('');
                setSelectedCompany('');
                setSelectedProjectName('');
                setCorrelativo(await getNextRequisitionNumber());
                setSelectedUser('');
                setSelectedUserName('');

                setDetalleItems([
                    { cotizacion: null, file: null, editable: true, isEditing: false, descripcion: '', pago: '', factura: '', unidadMedida: '', cantidad: '', precio: '', descuento: '', iva: '', isr: '', total: '' }
                ]);

                setUpdate(false);
                setIsLoading(false);

                props.ShowDone("Archivos cargados con exito.")

                // Obtén los datos actualizados y navega a la nueva página con los datos actualizados
                const updatedRequisition = await getRequisitionWithDetailsById(requiSel.id);
                navigate('/requisicion/historial/ver', { state: { requiSel: updatedRequisition } });
                loadDataRequisition();
            } else {
                props.showErr(result.error)
                props.ShowWar(result.message)
                setIsLoading(false);
            }

        } catch (error) {
            props.showErr("No es posible crear la requisición.");
            props.ShowWar(error)
            setIsLoading(false);
        }
    };

    //metodo para validar la URL
    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }


    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' >

                        < Menu
                            userLoged={useUser}
                        />
                        <div ref={pdfRef} hidden={true}>
                            <PDFComponent
                                numero={correlativo}
                                emisor={requiSel.emisor}
                                solicitante={selectedUserName}
                                fecha={formatDate(currentDate)}
                                proyecto={selectedProjectName}
                                empresa={selectedCompany}
                                proveedor={selectedProviderName}
                                descripcion={descripcion}
                                total={totalGeneral}
                                items={detalleItems}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <a className="back-button-requis" onClick={() => navigate("/requisicion/historial")}> <IoIosArrowBack style={{ marginRight: "10px" }} /> Regresar</a>
                            <div className="titulo-container-solicitud">
                                <p style={{ fontWeight: "500" }}>Requisición #{correlativo}</p>
                            </div>
                            <div className='header-solicitud'>
                                <span>Emisor: {requiSel.emisor}</span>
                                <span>Fecha: {formatDate(currentDate)}</span>
                            </div>
                            <div className='card-informacion-solicitud'>
                                <div className="card-informacion-solicitud-span">
                                    <span>Información Básica</span>
                                </div>
                                <div className="card-informacion-solicitud-proyecto">
                                    <div className="field-container-proyecto">
                                        <label>Proyecto</label>
                                        <input type="text" disabled={true} value={requiSel.proyecto.nombre} style={{ marginTop: "5px" }} />
                                    </div>

                                    <div className="field-container-proyecto">
                                        <label>Empresa</label>
                                        <input type="text" disabled={true} value={selectedCompany} />
                                    </div>
                                </div>
                                <div className="card-informacion-solicitud-proyecto">
                                    <div className="field-container-proyecto">
                                        <label>Solicita</label>
                                        <input type="text" disabled={true} value={selectedUserName} style={{ marginTop: "5px" }} />
                                    </div>
                                    <div className="field-container-proyecto">
                                        <label>Emitir cheque para</label>
                                        <input type="text" disabled={true} value={selectedProviderName} style={{ marginTop: "5px" }} />
                                    </div>
                                </div>

                                <div className="card-informacion-solicitud-description">
                                    <label>Descripción</label>
                                    <textarea
                                        style={{ background: "#F3F7F9 0% 0% no-repeat padding-box", border: "none", boxShadow: "3px 3px 5px #00000029" }}
                                        name="descripcion"
                                        id="descripcion"
                                        placeholder="Escribe la descripción de la requisición"
                                        value={descripcion}
                                        disabled={true}>
                                    </textarea>
                                </div>
                            </div>
                            <div className='card-detalle-solicitud'>
                                <div className='card-detalle-solicitud-span'>
                                    <span>Detalle de la Requisición</span>
                                </div>
                                <div className='card-detalle-solicitud-titulos'>
                                    <span style={{ width: "40%" }}>#</span>
                                    <span>Descripción</span>
                                    <span># Pago</span>
                                    <span>Factura</span>
                                    <span>Unidad de medida</span>
                                    <span>Cantidad</span>
                                    <span style={{ justifyContent: "left", marginRight: "1%" }}>Precio Unitario</span>
                                    <span style={{ marginRight: "1%" }}>Descuento</span>
                                    <span>Retención IVA</span>
                                    <span>Retención ISR</span>
                                    <span style={{ justifyContent: "right" }}>Total</span>
                                    <span></span>
                                </div>
                                {detalleItems.map((item, index) => (
                                    <div key={index} className='card-detalle-solicitud-detalle'>
                                        <span >{index + 1}</span>
                                        <input type="text" value={item.descripcion} onChange={e => handleChangeDetalle(e, index, 'descripcion')} disabled={!item.editable} style={{ border: "none" }} />
                                        <input type="text" value={item.pago} onChange={e => handleChangeDetalle(e, index, 'pago')} disabled={!item.editable} style={{ border: "none" }} />
                                        <input type="text" value={item.factura} onChange={e => handleChangeDetalle(e, index, 'factura')} disabled={!item.editable} style={{ border: "none" }} />
                                        <input type="text" value={item.unidadMedida} onChange={e => handleChangeDetalle(e, index, 'unidadMedida')} disabled={!item.editable} style={{ border: "none" }} />
                                        <input type="text" value={item.cantidad} onChange={e => handleChangeDetalle(e, index, 'cantidad')} disabled={!item.editable} style={{ border: "none" }} />
                                        <span style={{ width: "1.5%", border: "none" }}>Q </span>
                                        <input style={{ marginLeft: "0", border: "none" }} type="text" value={item.precio} onChange={e => handleChangeDetalle(e, index, 'precio')} disabled={!item.editable} />
                                        <span style={{ width: "1.5%", border: "none" }}>Q </span>
                                        <input style={{ marginLeft: "0", border: "none" }} type="text" value={item.descuento} onChange={e => handleChangeDetalle(e, index, 'descuento')} disabled={!item.editable} />
                                        <span style={{ width: "1.5%", border: "none" }}>Q </span>
                                        <input style={{ marginLeft: "0", border: "none" }} type="text" value={item.iva} onChange={e => handleChangeDetalle(e, index, 'iva')} disabled={!item.editable} />
                                        <span style={{ width: "1.5%", border: "none" }}>Q </span>
                                        <input style={{ marginLeft: "0", border: "none" }} type="text" value={item.isr} onChange={e => handleChangeDetalle(e, index, 'isr')} disabled={!item.editable} />
                                        <span style={{ width: "13%", marginLeft: "1.5%", wordBreak: "break-all" }}>
                                            Q {typeof item.total === 'number' ? item.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0"}
                                        </span>
                                        <span onClick={() => handleMenuClick(index)} className="icon-button-requis-editar"><MdOutlineFolderOpen /></span>
                                        {openMenuIndex === index && (
                                            <div className="menu-dropdown-detalle-requi">
                                                <ul className="menu-dropdown-detalle">

                                                    {isValidUrl(item.file) ? (
                                                        <li onClick={() => window.open(item.file, '_blank')}><AiOutlineEye /> Factura</li>
                                                    ) : (
                                                        <li htmlFor="srcimg"><FiUpload />
                                                            <input type="file" name="srcimg" id="srcimg" accept=".pdf, image/*, .xlsx, .xls, .xlsm" style={{ display: 'none' }} onChange={(e) => handleFileChange(e, index)} />
                                                            <label htmlFor="srcimg" style={{ cursor: 'pointer' }}>
                                                                Factura
                                                            </label>
                                                        </li>
                                                    )}
                                                    {isValidUrl(item.cotizacion) ? (
                                                        <li style={{ borderBottom: "none" }} onClick={() => window.open(item.cotizacion, '_blank')}><AiOutlineEye /> Cotización</li>
                                                    ) : (
                                                        <li style={{ borderBottom: "none" }} htmlFor="srcimg2"><MdUploadFile style={{ fontSize: "20px" }} />
                                                            <input type="file" name="srcimg2" id="srcimg2" accept=".pdf, image/*, .xlsx, .xls, .xlsm" style={{ display: 'none' }} onChange={(e) => handleFileChange2(e, index)} />
                                                            <label htmlFor="srcimg2" style={{ cursor: 'pointer' }}>
                                                                Cotización
                                                            </label>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className='card-detalle-solicitud-total'>
                                    Total Requisición: Q {totalGeneral.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </div>
                            </div>
                            <div className='button-container-solicitud'>
                                {update ? (
                                    <button className='botonPrincipal-solicitud' onClick={handleSaveChanges}>Guardar Archivos</button>
                                ) : (
                                    <button className='botonPrincipal-solicitud' onClick={mostrarVistaPreviaImpresion}><FiPrinter style={{ marginRight: "5px" }} /> Imprimir</button>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isLoading && <LoadingModal />}
        </>
    );
}

export default VerRequi;