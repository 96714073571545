import React, { useEffect, useState, useRef } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//para generar PDF
import PDFSolicitud, { getPDFStyles } from './pdf_solicitud';

//modal de carga
import LoadingModal from '../Loading-Modal/loading.jsx'

//import para funciones especificas de esta pantalla
import { getProjects, getProviders, addNewProvider, addRequisitionWithDetails, getNextRequisitionNumber } from '../../firebase/requisiciones'
import './solicitud.css'
import { FiPrinter, FiTrash2, FiUpload, FiSave } from 'react-icons/fi';
import { CiMenuKebab } from 'react-icons/ci';
import { MdOutlineModeEditOutline, MdUploadFile } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

function Solicitud(props) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar el modal de carga

    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);


    //estados para cargar informacion
    const [proyectos, setProyectos] = useState();
    const [proveedores, setProveedores] = useState();
    //const [correlativo, setCorrelativo] = useState(0);


    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //Cargar datos para usar en la pantalla al cargar
                    setProyectos(await getProjects());
                    setProveedores(await getProviders());
                    //setCorrelativo(await getNextRequisitionNumber());
                    setLoading(false); // para saber si termino de cargar los datos del usuario

                    const canAccess = await hasPermission(res.rol, "requisicion/solicitud");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    //Menú desplegable en detalle requisición
    const [showMenu, setShowMenu] = useState(false);

    const [openMenuIndex, setOpenMenuIndex] = useState();

    const handleMenuClick = (index) => {
        if (openMenuIndex === index) {
            setOpenMenuIndex(null);
        } else {
            setOpenMenuIndex(index);
        }
    };


    const [update, setUpdate] = useState(false);

    //Campos State para uso de funcionalidades
    const [descripcion, setDescripcion] = useState('');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedProject, setSelectedProject] = useState(''); // Guarda el ID del proyecto seleccionado.
    const [selectedProjectName, setSelectedProjectName] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(''); // Guarda la empresa relacionada con el proyecto seleccionado.

    const [detalleItems, setDetalleItems] = useState([{ cotizacion: null, file: null, editable: true, isEditing: false, descripcion: '', pago: '', factura: '', unidadMedida: '', cantidad: '', precio: '', descuento: '', iva: '', isr: '', total: 0 }]);
    const [totalGeneral, setTotalGeneral] = useState(0);


    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const period = hours < 12 ? 'am' : 'pm';
        return `${day}/${month}/${year} ${hours % 12 || 12}:${minutes} ${period}`;
    }

    //funcion para controlar el cambio en el drop down de Proyecto
    const handleProjectChange = (projectId) => {

        const project = proyectos.find(p => p.id === projectId);

        if (project) {
            setSelectedProject(project.id);
            setSelectedProjectName(project.project_name);
            setSelectedCompany(project.project_company);
        } else {
            setSelectedProject(null);
            setSelectedProjectName("");
            setSelectedCompany('');
        }
        setUpdate(true);
        setProjectOpen(false);
    };

    //Expreciones regulares para la seccion de Detalles
    const regex = {
        factura: /^[A-Za-z0-9_@$&%!*.-]+$/,
        unidadMedida: /^[A-Za-z0-9_@$&%!*.-]+$/,  // Permite letras, números y símbolos comunes.
        decimal: /^[0-9]+(\.[0-9]{0,2})?$/, // Números positivos con hasta dos decimales, sin ceros iniciales
        descuento: /^[0-9]+(\.[0-9]{0,2})?$/,
        iva: /^[0-9]+(\.[0-9]{0,2})?$/,
        isr: /^[0-9]+(\.[0-9]{0,2})?$/,
        cantidad: /^[0-9]+(\.[0-9]{0,2})?$/,
        precio: /^[0-9]+(\.[0-9]{0,2})?$/
    };

    //Funcion para validar si hay algun campo vacio en Detalle
    const anyFieldEmpty = () => {
        return detalleItems.some(item => {
            return Object.entries(item).some(([key, value]) => {
                if (key !== 'file' && key !== 'cotizacion') {
                    return value === '' || value === null;
                }
                return false; // Ignorar el campo 'file'
            });
        });
    };


    //funcion para agregar detalle
    const addDetalle = () => {
        if (anyFieldEmpty()) {
            props.ShowWar('Por favor, completa todos los campos antes de añadir un nuevo detalle.');
        } else {
            // Hacer todas las filas anteriores no editables
            const prevDetalleItems = detalleItems.map(item => ({ ...item, editable: false }));

            setDetalleItems([
                ...prevDetalleItems,
                { cotizacion: null, file: null, editable: true, isEditing: false, descripcion: '', pago: '', factura: '', unidadMedida: '', cantidad: '', precio: '', descuento: '', iva: '', isr: '', total: '' }
            ]);
        }
    };

    //funcion para llenar los campos de los detalles
    const handleChangeDetalle = (e, index, field) => {
        const value = e.target.value;
        setUpdate(true);
        // Si el campo es "descripción" o "pago", no aplicamos validación
        if (field === 'descripcion' || field === 'pago') {
            const newDetalleItems = [...detalleItems];
            newDetalleItems[index][field] = value;
            setDetalleItems(newDetalleItems);
            return;
        }

        // Para los demás campos, aplicamos validación
        if (value !== "" && regex[field] && !regex[field].test(value)) {
            // No cumple con la validación
            props.ShowWar('Valor no permitido')
            return;
        }

        const newDetalleItems = [...detalleItems];
        newDetalleItems[index][field] = value;

        // Verificando y calculando el total
        if (field === 'cantidad' || field === 'precio' || field === 'descuento' || field === 'iva' || field === 'isr') {
            const cantidad = parseFloat(newDetalleItems[index].cantidad) || 0;
            const precio = parseFloat(newDetalleItems[index].precio) || 0;
            const descuento = parseFloat(newDetalleItems[index].descuento) || 0;
            const subTotal = (cantidad * precio) - descuento || 0;
            const iva = parseFloat(newDetalleItems[index].iva) || 0;
            const isr = parseFloat(newDetalleItems[index].isr) || 0;
            newDetalleItems[index].total = subTotal - iva - isr || 0;
        }

        setDetalleItems(newDetalleItems);
    };


    //useEffect que se actualiza solo cuando cambia "detalleItems"
    useEffect(() => {
        const total = detalleItems.reduce((accum, item) => accum + (item.total || 0), 0);
        setTotalGeneral(total);
    }, [detalleItems]);

    //Para imprimir
    const pdfRef = useRef(null);

    const mostrarVistaPreviaImpresion = () => {

        const pdfStyles = getPDFStyles();
        if (pdfRef.current) {
            const contenido = pdfRef.current.innerHTML;
            const printWindow = window.open('', '_blank');
            if (printWindow) {  // Verificar que la ventana se haya abierto

                printWindow.document.write('<html><head><title>REQUISICION</title>');
                // Inyecta los estilos CSS
                printWindow.document.write(`<style>${pdfStyles}</style>`);
                printWindow.document.write('</head><body>');
                printWindow.document.write(contenido);
                printWindow.document.write('</body></html>');
                printWindow.document.close();


                // Evento que detecta el cierre de la ventana de impresión
                const checkWindowClosed = setInterval(() => {
                    if (printWindow.closed) {
                        window.focus();  // Devuelve el foco a la ventana principal
                        clearInterval(checkWindowClosed);  // Detiene la verificación
                        printWindow.close();
                    }
                }, 100);

                printWindow.addEventListener('afterprint', function () {
                    printWindow.close();
                });


                printWindow.print();
            } else {
                console.error("No se pudo abrir la ventana de impresión. Verifica los bloqueadores de ventanas emergentes.");
            }

        } else {
            console.error("Referencia no disponible");
        }
    };


    //estados para menu desplegable
    const [isOpen, setIsOpen] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [selectedProviderName, setSelectedProviderName] = useState('');

    const [isProjectOpen, setProjectOpen] = useState(false);



    //menu desplegable de proveedores
    const handleProviderClick = (providerId) => {
        setSelectedProvider(providerId);
        setIsOpen(false); // Cierra el menú después de seleccionar
        const provider = proveedores.find(p => p.id === providerId);
        if (provider) {
            setSelectedProviderName(provider.name);
        }
        setUpdate(true);
    };

    //estados para agregar nuevo proveedor
    const [isAddingProvider, setIsAddingProvider] = useState(false);
    const [newProviderName, setNewProviderName] = useState('');

    //funcion para agregar neuvo proveedor
    const handleAddNewProvider = async () => {

        if (newProviderName.trim() === '') {
            props.ShowWar("El nombre del proveedor no puede estar vacío");
            return;
        }
        try {
            const newProvider = await addNewProvider(newProviderName);
            if (newProvider.success) {
                // Actualiza el estado de los proveedores con el nuevo proveedor.
                //setProveedores(prevProviders => [...prevProviders, { id: newProvider.id, name: newProvider.name }]);
                // Actualiza el estado de los proveedores con el nuevo proveedor y los ordena alfabéticamente.
                setProveedores(prevProviders => {
                    const updatedProviders = [...prevProviders, { id: newProvider.id, name: newProvider.name }];
                    return updatedProviders.sort((a, b) => a.name.localeCompare(b.name));
                });

                setNewProviderName('');
                setIsAddingProvider(false);
                props.ShowDone(newProvider.message)
            } else {
                props.showErr(newProvider.error)
                props.ShowWar(newProvider.message)
            }

        } catch (error) {
            console.error("Error al agregar proveedor: ", error);
            props.showErr("Error inesperado al agregar proveedor.")
        }
    }

    //funcion para editar un campo de los detalles
    function handleEditClick(indexToEdit) {
        const updatedItems = [...detalleItems];
        if (updatedItems[indexToEdit].isEditing) {
            // Si ya está en modo de edición, guardamos los cambios
            updatedItems[indexToEdit].isEditing = false;
            updatedItems[indexToEdit].editable = false;
        } else {
            // Si no está en modo de edición, habilitamos la edición
            updatedItems[indexToEdit].isEditing = true;
            updatedItems[indexToEdit].editable = true;
        }
        setOpenMenuIndex(null);
        setDetalleItems(updatedItems);
    }

    //Eliminar item de los detalles
    function handleDeleteClick(indexToDelete) {
        const updatedItems = [...detalleItems];
        updatedItems.splice(indexToDelete, 1); // Elimina el ítem del arreglo
        setOpenMenuIndex(null);
        setDetalleItems(updatedItems);
    }

    //cerrar el menu al darle click fuera
    useEffect(() => {
        function handleDocumentClick(event) {
            if (openMenuIndex !== null && !event.target.closest('.menu-dropdown-detalle-requi')) {
                setOpenMenuIndex(null);
                setIsOpen(false)
            }

            // Verificar si el clic es fuera del menú "card-informacion-solicitud-cheque"
            if (isOpen && !event.target.closest('.card-informacion-solicitud-cheque')) {
                setIsOpen(false);
            }

            // Verificar si el clic es fuera del menú "field-container-proyecto"
            if (isProjectOpen && !event.target.closest('.field-container-proyecto')) {
                setProjectOpen(false);
            }
        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [openMenuIndex, isOpen, isProjectOpen]);

    //para subir las Cotizacion
    // Añade un estado para el archivo seleccionado
    const [selectedCoti, setSelectedCoti] = useState(null);

    // Función que se ejecuta cuando el usuario selecciona un archivo
    const handleFileChange2 = (e, index) => {
        setOpenMenuIndex(null);

        const file = e.target.files[0];
        if (file) {
            setSelectedCoti(file);
            //console.log(URL.createObjectURL(file));
            handleAssignCotiToItem(index, file);
        }
    }

    // Función para asignar el archivo al item correspondiente
    const handleAssignCotiToItem = (index, file) => {
        const newDetalleItems = [...detalleItems];
        newDetalleItems[index].cotizacion = file;
        setDetalleItems(newDetalleItems);
        setSelectedCoti(null); // Resetear el archivo seleccionado
    }

    //para subir las facturas
    // Añade un estado para el archivo seleccionado
    const [selectedFile, setSelectedFile] = useState(null);

    // Función que se ejecuta cuando el usuario selecciona un archivo
    const handleFileChange = (e, index) => {
        setOpenMenuIndex(null);

        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            //console.log(URL.createObjectURL(file));
            handleAssignFileToItem(index, file);
        }
    }

    // Función para asignar el archivo al item correspondiente
    const handleAssignFileToItem = (index, file) => {
        const newDetalleItems = [...detalleItems];
        newDetalleItems[index].file = file;
        setDetalleItems(newDetalleItems);
        setSelectedFile(null); // Resetear el archivo seleccionado
    }

    const InputChange = (e) => {

        const { name, value } = e.target;
        setUpdate(true);

        if (name === 'descripcion') {
            setDescripcion(value);
        }
    };

    //enviar a guardar requisicion
    const handleSaveChanges = async () => {
        setIsLoading(true);
        try {

            //Creando la estructura de la requisicion
            const newRequisitionData = {
                proveedor: {
                    id: selectedProvider,
                    nombre: selectedProviderName
                },
                descripcion: descripcion,
                proyecto: {
                    id: selectedProject,
                    compania: selectedCompany,
                    nombre: selectedProjectName
                },
                //correlativo: correlativo,
                solicitante: useUser.nombre,
                emisor: useUser.nombre,
                fecha: currentDate,
                estado: 'Solicitada',
                total: totalGeneral
            };

            //modificando la estructura de los detalles para esta requisicion
            const adaptedDetailsList = detalleItems.map(detail => {
                return {
                    ...detail,
                    cantidad: detail.cantidad ? parseFloat(detail.cantidad) : 0,
                    precio: detail.precio ? parseFloat(detail.precio) : 0,
                    descuento: detail.descuento ? parseFloat(detail.descuento) : 0,
                };
            }).map(({ editable, isEditing, ...rest }) => rest);  // Esta segunda operación con map elimina el campo "editable"

            const result = await addRequisitionWithDetails(newRequisitionData, adaptedDetailsList);

            if (result.success) {
                //Limpiar todos los datos
                setCurrentDate(new Date());
                setSelectedProject('');
                setSelectedProvider('');
                setSelectedProviderName('');
                setDescripcion('');
                setSelectedProject('');
                setSelectedCompany('');
                setSelectedProjectName('');
                //setCorrelativo(await getNextRequisitionNumber());

                setDetalleItems([
                    { cotizacion: null, file: null, editable: true, isEditing: false, descripcion: '', pago: '', factura: '', unidadMedida: '', cantidad: '', precio: '', descuento: '', iva: '', isr: '', total: '' }
                ]);

                setUpdate(false);
                setIsLoading(false);

                props.ShowDone(result.message)
            } else {
                props.showErr(result.error)
                props.ShowWar(result.message)
                setIsLoading(false);

            }

        } catch (error) {
            props.showErr("No es posible crear la solicitud.");
            props.ShowWar(error)
            setIsLoading(false);
        }
    };

    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' >

                        < Menu
                            userLoged={useUser}
                        />

                        <div style={{ width: "100%" }}>
                            <div className="titulo-container-solicitud">
                                <p style={{ fontWeight: "500" }}>Solicitud Requisición</p>
                                <div ref={pdfRef} hidden={true}>
                                    <PDFSolicitud
                                        //numero={correlativo}
                                        emisor={useUser.nombre}
                                        solicitante={useUser.nombre}
                                        fecha={currentDate.toLocaleString('es-GT', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                        }).replace(/(\d+)\/(\d+)\/(\d+),? (\d+:\d+ [APM]+)/i, '$1/$2/$3 $4').toLowerCase()}
                                        proyecto={selectedProjectName}
                                        empresa={selectedCompany}
                                        proveedor={selectedProviderName}
                                        descripcion={descripcion}
                                        total={totalGeneral}
                                        items={detalleItems}
                                    />

                                </div>
                                <button onClick={mostrarVistaPreviaImpresion}><FiPrinter style={{ marginRight: "10px" }} /> Imprimir</button>
                            </div>
                            <div className='header-solicitud'>
                                <span>Solicita: {useUser.nombre}</span>
                                <span>Fecha: {formatDate(currentDate)}</span>
                            </div>
                            <div className='card-informacion-solicitud'>
                                <div className="card-informacion-solicitud-span">
                                    <span>Información Básica</span>
                                </div>
                                <div className="card-informacion-solicitud-proyecto">
                                    <div className="field-container-proyecto">
                                        <label>Proyecto</label>
                                        <button onClick={() => setProjectOpen(!isProjectOpen)} style={{ marginTop: "5px" }}>
                                            {proyectos.find(p => p.id === selectedProject)?.project_name || "Selecciona un proyecto"}
                                            {isProjectOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                        </button>

                                        {isProjectOpen && (
                                            <div className="dropdown-container-proyecto">
                                                <ul>
                                                    {proyectos.map(project => (
                                                        <li key={project.id}>
                                                            <button onClick={() => handleProjectChange(project.id)}>
                                                                {project.project_name}
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>

                                    <div className="field-container-proyecto">
                                        <label>Empresa</label>
                                        <input type="text" disabled={true} value={selectedCompany} />
                                    </div>
                                </div>
                                <div className="card-informacion-solicitud-cheque">
                                    <label>Emitir cheque para</label>
                                    <button onClick={() => setIsOpen(!isOpen)} style={{ paddingLeft: "10px", paddingRight: "10px", justifyContent: "space-between" }}>
                                        {selectedProviderName || "Escribe el nombre del beneficiario"}
                                        {isOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                    </button>

                                    {isOpen && (
                                        <div className="dropdown-cheque">
                                            <ul>
                                                {isAddingProvider ? (
                                                    <div className="dropdown-cheque-nuevo-proveedor">
                                                        <input
                                                            type="text"
                                                            value={newProviderName}
                                                            onChange={(e) => setNewProviderName(e.target.value)}
                                                            placeholder="Nombre del nuevo proveedor"
                                                        />
                                                        <button onClick={handleAddNewProvider}>Guardar</button>
                                                        <button onClick={() => setIsAddingProvider(false)} style={{ backgroundColor: "#647787" }}>Cancelar</button>
                                                    </div>
                                                ) : (
                                                    <li>
                                                        <button onClick={() => setIsAddingProvider(true)} style={{ color: "#3A93F7", paddingLeft: "10px" }}>
                                                            + Agregar nuevo proveedor
                                                        </button>
                                                    </li>
                                                )}
                                                {proveedores.map(provider => (
                                                    <li key={provider.id}>
                                                        <button style={{ paddingLeft: "10px" }} onClick={() => handleProviderClick(provider.id)}
                                                            className={selectedProvider === provider.id ? 'selected' : ''}>
                                                            {provider.name}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    )}
                                </div>
                                <div className="card-informacion-solicitud-description">
                                    <label>Descripción</label>
                                    <textarea
                                        name="descripcion"
                                        id="descripcion"
                                        placeholder="Escribe la descripción de la requisición"
                                        value={descripcion}
                                        onChange={InputChange}>
                                    </textarea>
                                </div>
                            </div>
                            <div className='card-detalle-solicitud'>
                                <div className='card-detalle-solicitud-span'>
                                    <span>Detalle de la Requisición</span>
                                    <button onClick={addDetalle}>Añadir</button>
                                </div>
                                <div className='card-detalle-solicitud-titulos'>
                                    <span style={{ width: "40%" }}>#</span>
                                    <span>Descripción</span>
                                    <span># Pago</span>
                                    <span>Factura</span>
                                    <span>Unidad de medida</span>
                                    <span>Cantidad</span>
                                    <span style={{ justifyContent: "left", marginRight: "1%" }}>Precio Unitario</span>
                                    <span style={{ marginRight: "1%" }}>Descuento</span>
                                    <span>Retención IVA</span>
                                    <span>Retención ISR</span>
                                    <span style={{ justifyContent: "center" }}>Total</span>
                                    <span></span>
                                </div>
                                {detalleItems.map((item, index) => (
                                    <div key={index} className='card-detalle-solicitud-detalle'>
                                        <span >{index + 1}</span>
                                        <input type="text" value={item.descripcion} onChange={e => handleChangeDetalle(e, index, 'descripcion')} disabled={!item.editable} />
                                        <input type="text" value={item.pago} onChange={e => handleChangeDetalle(e, index, 'pago')} disabled={!item.editable} />
                                        <input type="text" value={item.factura} onChange={e => handleChangeDetalle(e, index, 'factura')} disabled={!item.editable} />
                                        <input type="text" value={item.unidadMedida} onChange={e => handleChangeDetalle(e, index, 'unidadMedida')} disabled={!item.editable} />
                                        <input type="text" value={item.cantidad} onChange={e => handleChangeDetalle(e, index, 'cantidad')} disabled={!item.editable} />
                                        <span style={{ width: "1.5%" }}>Q </span>
                                        <input style={{ marginLeft: "0" }} type="text" value={item.precio} onChange={e => handleChangeDetalle(e, index, 'precio')} disabled={!item.editable} />
                                        <span style={{ width: "1.5%" }}>Q </span>
                                        <input style={{ marginLeft: "0" }} type="text" value={item.descuento} onChange={e => handleChangeDetalle(e, index, 'descuento')} disabled={!item.editable} />
                                        <span style={{ width: "1.5%" }}>Q </span>
                                        <input style={{ marginLeft: "0", paddingRight: "2%" }} type="text" value={item.iva} onChange={e => handleChangeDetalle(e, index, 'iva')} disabled={!item.editable} />
                                        <span style={{ width: "1.5%" }}>Q </span>
                                        <input style={{ marginLeft: "0", paddingRight: "2%" }} type="text" value={item.isr} onChange={e => handleChangeDetalle(e, index, 'isr')} disabled={!item.editable} />
                                        <span style={{ width: "13%", marginLeft: "1.5%", wordBreak: "break-all" }}>
                                            Q {typeof item.total === 'number' ? item.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0"}
                                        </span>
                                        <span onClick={() => handleMenuClick(index)} style={{ cursor: "pointer" }}><CiMenuKebab /></span>
                                        {openMenuIndex === index && (
                                            <div className="menu-dropdown-detalle-requi">
                                                <ul className="menu-dropdown-detalle">
                                                    <li onClick={() => handleEditClick(index)}>
                                                        {item.isEditing ? <FiSave /> : <MdOutlineModeEditOutline />}
                                                        {item.isEditing ? 'Guardar' : 'Editar'}
                                                    </li>
                                                    <li onClick={() => handleDeleteClick(index)}><FiTrash2 /> Eliminar</li>

                                                    <li htmlFor="srcimg"><FiUpload />
                                                        <input type="file" name="srcimg" id="srcimg" accept=".pdf, image/*, .xlsx, .xls, .xlsm" style={{ display: 'none' }} onChange={(e) => handleFileChange(e, index)} />
                                                        <label htmlFor="srcimg" style={{ cursor: 'pointer' }}>
                                                            Factura
                                                        </label>
                                                    </li>

                                                    <li style={{ borderBottom: "none" }} htmlFor="srcimg2"><MdUploadFile style={{ fontSize: "20px" }} />
                                                        <input type="file" name="srcimg2" id="srcimg2" accept=".pdf, image/*, .xlsx, .xls, .xlsm" style={{ display: 'none' }} onChange={(e) => handleFileChange2(e, index)} />
                                                        <label htmlFor="srcimg2" style={{ cursor: 'pointer' }}>
                                                            Cotización
                                                        </label>
                                                    </li>

                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className='card-detalle-solicitud-total'>
                                    Total Requisición: Q {totalGeneral.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </div>
                            </div>
                            <div className='button-container-solicitud'>
                                {update ? (
                                    <button className='botonPrincipal-solicitud' onClick={handleSaveChanges}>Enviar</button>
                                ) : (
                                    <button className='botonSecondary-solicitud' disabled={true}>Enviar</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isLoading && <LoadingModal />}
        </>
    );
}

export default Solicitud;