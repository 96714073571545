import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//importacion del MENU
import Menu from '../../components/menu';
import HeaderHome from '../../components/header';

import '../home/home.css';

function Home(props) {
    const navigate = useNavigate();

    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);


    //para validar si esta iniciada la sesion
    useEffect(() => {
        onAuthStateChanged(auth, handleUserStateChanged);
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    setLoading(false);

                    redirectUser(res.rol);

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
            }

        } else {
            navigate('/login');
        }
    }

    const redirectUser = async (rol) => {

        const canAccessRequi = await hasPermission(rol, "requisicion/historial");
        if (canAccessRequi) {
            navigate('/requisicion/historial');
            return;
        }

        const canAccessVoucher = await hasPermission(rol, "voucher/historial");
        if (canAccessVoucher) {
            navigate('/voucher/historial');
            return;
        }

        const canAccessProyecto = await hasPermission(rol, "proyecto/nuevo");
        if (canAccessProyecto) {
            navigate('/proyecto/nuevo');
            return;
        }

        navigate('/user');
        return;
    }

    return (
        <>
            {!loading && (
                <div className='homeContainer'>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome' style={{ height: "100vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div>
                            <p>¡Bienvenido!</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Home;
