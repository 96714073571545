import React from 'react';
import './loading.css'
import loadingImage from '../../assets/images/Logo_Codegua.png'
import BeatLoader from "react-spinners/BeatLoader"

const LoadingModal = () => {
    return (
        <div className="loading-modal">
            <img src={loadingImage} alt="Cargando..." className="loading-image" />
            <p className='loading-text'>Cargando...</p>
            <BeatLoader
                color={"#FFFFFF"}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            {/*<p>Cargando...</p>*/}

        </div>
    );
};

export default LoadingModal;