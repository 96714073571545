import React, { useState } from "react";
import "./Modal-Eliminar-Requi.css";

export default function ModalMotivoEliminacion({ handleClose, showErr, ShowDone, ShowWar, onAceptar }) {

    const [motivoEliminacion, setMotivoEliminacion] = useState(""); // Estado local para el motivo de eliminación

    const handleInputChange = (event) => {
        setMotivoEliminacion(event.target.value); // Actualiza el estado local con el valor del input
    };

    //funcion para validar entrada
    const validateJust = () => {

        if (motivoEliminacion.trim().length < 1) {
            ShowWar("Debes indicar un motivo valido.");
            return false;
        }
        return true;
    };

    const handleAceptar = () => {
        // Llama a la función onAceptar pasando el motivo de eliminación
        if (validateJust()) {
            onAceptar(motivoEliminacion);
            handleClose(); // Cierra el Modal
        }
    };

    return (
        <>
            <div className="modal">
                <div className="overlay"></div>
                <div className="modal-content" style={{ height: '190px' }}>
                    <p>¿Cuál es el motivo de la eliminación?</p>
                    <textarea
                        className="modal-content-textarea"
                        placeholder="Escribe el motivo de la eliminación de la requisición"
                        value={motivoEliminacion}
                        onChange={handleInputChange} // Manejador de eventos para el input
                    />
                    <button className="close-modal" onClick={handleClose}>X</button>
                    <div className="modal-footer">
                        <button className="confirmation-button" onClick={handleAceptar}>Aceptar</button>
                        <button className="close-button" onClick={handleClose}>Cancelar</button>
                    </div>
                </div>
            </div>
        </>
    );
}
