import React, { useState, useEffect } from 'react';

const convertToCSSString = (styleObj) => {
    let cssStr = '';
    for (const [key, value] of Object.entries(styleObj)) {
        const cssKey = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
        cssStr += `${cssKey}: ${value}; `;
    }
    return cssStr;
};


const baseStyles = {

    estiloRequisicion: {
        fontFamily: 'Montserrat, sans-serif',
        width: '89%',
        height: '92%',
        padding: '5%',
        border: '1px solid #ddd'
    },
    encabezado: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '0px',
        marginBottom: '5px'

    },
    titulo: {
        marginTop: "25px",
        textAlign: 'center',
        fontSize: '35px',
        marginBottom: '10px',
        fontWeight: '600'
    },
    dato: {
        fontSize: '16px',
        marginTop: "0",
        fontWeight: "550"
    },
    detalle: {
        marginLeft: "15px",
        margin: "0"
    },
    descrip: {
        marginTop: "0",
        height: '150px',
        fontSize: '16px',
        fontWeight: "550"
    },
    total: {
        fontSize: '16px',
        fontWeight: "bolder",
        margin: '0'
    },
    linea: {
        height: '7px',
        backgroundColor: 'black',
        margin: '10px 0'
    },
    firmas: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '230px'
    },
    firma: {
        borderTop: '1px solid black',
        width: '45%',
        textAlign: 'center',
        fontSize: '16px',
        paddingTop: '10px'
    },
    seccion: {
        borderBottom: '1px solid #000',
        marginBottom: '50px',

    },
    table: {
        borderCollapse: 'collapse',
        marginLeft: '-22px',
        fontSize: '10px'
    },
    tableRow: {
        borderBottom: '1px solid black',
    },
    tableCell: {
        border: '1px solid black',
        textAlign: 'center',
        padding: '1.5px 5px'
    }
};

const PDFSolicitud = ({ numero, emisor, solicitante, fecha, proyecto, empresa, proveedor, descripcion, total, items }) => {

    //estados para calculos
    const [SubTotal, setSubTotal] = useState(0);
    const [DescuentoTotal, setDescuentoTotal] = useState(0);
    const [TotalIVA, setTotalIVA] = useState(0);
    const [TotalISR, setTotalISR] = useState(0);

    useEffect(() => {

        // Calculando el subtotal
        const subtotal = items.reduce((acc, item) => acc + ((item.cantidad ?? 0) * (Number(item.precio) || 0)), 0);

        // Calculando el descuento
        const descuento = items.reduce((acc, item) => acc + (Number(item.descuento) || 0), 0);

        // Calculando el total de IVA
        const totalIVA = items.reduce((acc, item) => acc + (Number(item.iva) || 0), 0);

        // Calculando el total de ISR
        const totalISR = items.reduce((acc, item) => acc + (Number(item.isr) || 0), 0);

        // Actualizando los estados
        setSubTotal(subtotal);
        setDescuentoTotal(descuento);
        setTotalIVA(totalIVA);
        setTotalISR(totalISR);

    }, [items]);

    return (
        <div className="estiloRequisicion" id="contenidoParaImprimir">
            <div className="encabezado">
                <div className="titulo">SOLICITUD DE COMPRA</div>
            </div>
            <div className='seccion'></div>
            <div style={{ display: "flex" }}>
                <p className="dato">FECHA: </p>
                <p className="detalle" style={{ marginLeft: "10px" }}>{fecha}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="dato" style={{ marginBottom: "50px" }}>EMISOR: </p>
                <p className="detalle" style={{ marginLeft: "10px" }}>{emisor}</p>
            </div>

            <div className='seccion'></div>


            <div style={{ display: "flex" }}>
                <p className="dato">PROYECTO: </p>
                <p className="detalle" style={{ marginLeft: "10px" }}>{proyecto}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="dato">EMPRESA: </p>
                <p className="detalle" style={{ marginLeft: "10px" }}>{empresa}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="dato">EMITIR CHEQUE PARA: </p>
                <p className="detalle" style={{ marginLeft: "10px" }}>{proveedor}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="descrip">DESCRIPCIÓN: </p>
                <p className="detalle" style={{ width: "90%", marginLeft: "10px" }}>{descripcion}</p>
            </div>

            <div>
                <table className='table' style={baseStyles.table}>
                    <thead>
                        <tr>
                            <th style={baseStyles.tableCell}>#</th>
                            <th style={baseStyles.tableCell}>DESCRIPCIÓN</th>
                            <th style={baseStyles.tableCell}># PAGO</th>
                            <th style={{ ...baseStyles.tableCell, width: "100px" }}>FACTURA</th>
                            <th style={baseStyles.tableCell}>UNIDAD DE MEDIDA</th>
                            <th style={baseStyles.tableCell}>CANTIDAD</th>
                            <th style={baseStyles.tableCell}>PRECIO UNITARIO</th>
                            <th style={baseStyles.tableCell}>DESCUENTO</th>
                            <th style={baseStyles.tableCell}>RETENCIÓN IVA</th>
                            <th style={baseStyles.tableCell}>RETENCIÓN ISR</th>
                            <th style={{ ...baseStyles.tableCell, width: "100px" }}>TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td style={baseStyles.tableCell}>{index + 1}</td>
                                <td style={baseStyles.tableCell}>{item.descripcion}</td>
                                <td style={baseStyles.tableCell}>{item.pago}</td>
                                <td style={baseStyles.tableCell}>{item.factura}</td>
                                <td style={baseStyles.tableCell}>{item.unidadMedida}</td>
                                <td style={baseStyles.tableCell}>{item.cantidad}</td>
                                <td style={baseStyles.tableCell}>Q {item.precio ? parseFloat(item.precio).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                <td style={baseStyles.tableCell}>Q {item.descuento ? parseFloat(item.descuento).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                <td style={baseStyles.tableCell}>Q {item.iva ? parseFloat(item.iva).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                <td style={baseStyles.tableCell}>Q {item.isr ? parseFloat(item.isr).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                <td style={baseStyles.tableCell}>Q {item.total ? parseFloat(item.total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>


            <div style={{ display: "flex", marginTop: "45px" }}>
                <p className="total">SUBTOTAL: </p>
                <p className="detalle" style={{ marginLeft: "15px" }}>Q {SubTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="total">TOTAL DESCUENTO: </p>
                <p className="detalle" style={{ marginLeft: "15px" }}>Q {DescuentoTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="total">TOTAL RETENCIÓN IVA: </p>
                <p className="detalle" style={{ marginLeft: "15px" }}>Q {TotalIVA.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="total">TOTAL RETENCIÓN ISR: </p>
                <p className="detalle" style={{ marginLeft: "15px" }}>Q {TotalISR.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            <div style={{ display: "flex", marginTop: "35px", fontWeight: "bolder" }}>
                <p className="total">TOTAL REQUISICIÓN: </p>
                <p className="detalle" style={{ marginLeft: "15px", placeSelf: "center", marginTop: "5px" }}>Q {total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>


        </div>
    );
};

export default PDFSolicitud;

export const getPDFStyles = () => {
    let styleString = '';
    // Convertir cada sub-objeto en baseStyles a una cadena CSS
    for (const [className, styleObj] of Object.entries(baseStyles)) {  // Usamos baseStyles directamente
        styleString += `.${className} { ${convertToCSSString(styleObj)} }\n`;  // Usamos convertToCSSString directamente
    }
    return styleString;
}
