import { db, auth, uploadFile, storage } from './firebase'; // Importamos 'db' que es nuestra referencia a Firestore desde el archivo original de firebase
import { collection, getDocs, addDoc, query, where, doc, deleteDoc, updateDoc } from 'firebase/firestore'; // Importamos los métodos necesarios de Firestore

// FUNCIÓN PARA OBTENER TODOS LOS REGÍMENES
export async function getRegimes() {
    try {
        const regimeCollection = collection(db, 'regime');
        const regimeSnapshot = await getDocs(regimeCollection);
        const regimeList = regimeSnapshot.docs.map(doc => doc.data().name);
        return regimeList;
    } catch (error) {
        console.error("Error al obtener los regímenes: ", error);
        return [];
    }
}

//FUNCION PARA ALMACENAR NUEVO PROYECTO
export async function addNewProvider(projectName, companyName, id_empresa, monto, entidad, fechaInicio, periodo) {
    try {
        // Accede a la colección 'project_detail'
        const projectCollection = collection(db, 'project_detail');

        // Crea una consulta para buscar proyectos con el mismo nombre
        const q = query(projectCollection, where("project_name", "==", projectName), where("project_company", "==", companyName));

        // Ejecuta la consulta
        const querySnapshot = await getDocs(q);

        // Verifica si ya existe un proyecto con el mismo nombre
        if (!querySnapshot.empty) {
            return {
                success: false,
                error: "No se puede agregar el proyecto",
                message: "Ya existe un proyecto con este nombre para la empresa seleccionada."
            };
        }

        // Agrega un nuevo documento con los detalles del proyecto
        const newProject = {
            project_name: projectName,
            project_company: companyName,
            project_company_id: id_empresa,
            project_monto: monto,
            project_entidad_contratante: entidad,
            project_fecha_inicio: fechaInicio,
            project_periodo_tiempo: periodo
        };

        const docRef = await addDoc(projectCollection, newProject);

        return { success: true, message: "Proyecto creado exitosamente." };
    } catch (error) {
        console.error("Error al agregar el proyecto: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al agregar el proyecto."
        };
    }
}

// FUNCIÓN PARA ELIMINAR UN PROVEEDOR POR ID
export async function deleteProviderById(providerId) {
    try {
        // Crea una referencia al documento usando el ID en la colección correcta
        const providerRef = doc(db, 'proveedor_detail', providerId);

        // Elimina el documento
        await deleteDoc(providerRef);

        // Retorna un mensaje de éxito
        return { success: true, message: "Proveedor eliminado exitosamente." };
    } catch (error) {
        console.error("Error al eliminar el proveedor: ", error);
        // Retorna un mensaje de error
        return { success: false, message: "Ocurrió un error al eliminar el proveedor.", error: error };
    }
}

// FUNCION PARA ELIMINAR VARIOS PROVEEDORES POR ID
export async function deleteProvidersByIds(providerIds) {
    const deleteResults = await Promise.allSettled(
        providerIds.map(providerId => {
            const providerRef = doc(db, 'proveedor_detail', providerId);
            return deleteDoc(providerRef)
                .then(() => ({ id: providerId, success: true }))
                .catch(error => ({ id: providerId, success: false, error }));
        })
    );

    // Mapea los resultados a un formato consistente, independientemente de si la promesa fue resuelta o rechazada
    const results = deleteResults.map(result =>
        result.status === 'fulfilled'
            ? result.value
            : { id: result.reason.id, success: false, error: result.reason.error }
    );

    return results;
}


// FUNCION PARA OBTENER TODOS LOS PROVEEDORES
export async function getAllProviders() {
    try {
        const providerCollection = collection(db, 'proveedor_detail');
        const providerSnapshot = await getDocs(providerCollection);

        // Verificar si hay documentos
        if (providerSnapshot.empty) {
            return [];
        }

        const providerList = providerSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        // Ordenar la lista alfabéticamente por el campo "name" sin importar mayúsculas o minúsculas
        providerList.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

        return providerList;

    } catch (error) {
        console.error("Error al obtener los proveedores: ", error);
        return [];
    }
}

// FUNCION PARA AGREGAR UN NUEVO PROVEEDOR
export async function addProvider(newProvider) {
    try {
        const providerCollection = collection(db, 'proveedor_detail');

        // Validar si ya existe un proveedor con ese nombre
        const q = query(providerCollection, where("name", "==", newProvider.name));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            // El proveedor ya existe
            return {
                success: false,
                error: "Error al agregar proveedor.",
                message: "El nombre del proveedor ya existe."
            };
        }

        // Si no existe, agregarlo on todos los detalles
        const docRef = await addDoc(providerCollection, newProvider);
        return {
            success: true,
            message: "Proveedor agregado exitosamente.",
            id: docRef.id,
            name: newProvider.name
        };

    } catch (error) {
        console.error("Error al agregar proveedor: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al agregar el proveedor."
        }
    }
}

// FUNCION PARA EDITAR UN PROVEEDOR EXISTENTE
export async function editProvider(providerId, updatedProvider) {
    try {
        const providerCollection = collection(db, 'proveedor_detail');

        // Verificar si se cambió el nombre y si el nuevo nombre ya está en uso
        if (updatedProvider.originalName !== updatedProvider.name) {
            const q = query(providerCollection, where("name", "==", updatedProvider.name));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                // Si existe algún documento que no sea el proveedor actual con el mismo nombre
                const isAnotherProvider = querySnapshot.docs.some(doc => doc.id !== providerId);
                if (isAnotherProvider) {
                    return {
                        success: false,
                        error: "Error al editar proveedor.",
                        message: "El nombre del proveedor ya existe."
                    };
                }
            }
        }

        // Si el nombre no existe o no fue cambiado, actualizar el proveedor
        const providerDoc = doc(providerCollection, providerId);
        await updateDoc(providerDoc, updatedProvider);
        return {
            success: true,
            message: "Proveedor actualizado exitosamente.",
            id: providerId,
            name: updatedProvider.name
        };

    } catch (error) {
        console.error("Error al editar proveedor: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al editar el proveedor."
        }
    }
}