import { db } from './firebase';
import { collection, addDoc, doc, setDoc } from 'firebase/firestore';

// Función para registrar errores
export async function logError(component, msj, errorMessage) {
    try {
        const errorCollection = collection(db, 'error_logs');

        const errorData = {
            timestamp: new Date(),
            component,
            message: msj,
            msjError: errorMessage
        };

        // Formatear la fecha y hora para usarla como ID del documento
        const errorId = formatDateTime();

        await setDoc(doc(errorCollection, errorId), errorData);

        console.log("Nuevo Error Registrado.");
    } catch (error) {
        console.error("Error al registrar el error: ", error);
    }
}

function formatDateTime() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Enero es 0!
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${day}${month}${year}_${hours}_${minutes}_${seconds}`;
}