import React, { useState } from "react";
import "./Modal-Crear-Rol.css";

import { createNewRole } from '../../firebase/usuarios_roles'

export default function ModalCrearRol({ handleClose, showErr, ShowDone, ShowWar }) {

    const [newRoleName, setNewRoleName] = useState("");

    const handleInputChange = (e) => {
        setNewRoleName(e.target.value);
    };

    //funcion para validar entrada
    const validateRoleName = () => {
        if (newRoleName.split(' ').length > 1) {
            ShowWar("El nombre del rol no debe contener espacios.");
            return false;
        }
        if (newRoleName.toLowerCase() === "admin") {
            ShowWar("El nombre del rol no puede ser 'admin'.");
            return false;
        }
        return true;
    };

    //Funcion para guardar el rol nuevo
    const handleCreateRole = async () => {
        if (validateRoleName()) {
            const result = await createNewRole(newRoleName);
            if (result.success) {
                ShowDone(result.message);
                handleClose();
            } else {
                showErr("Error al crear el rol.");
                ShowWar(result.message);
            }
        }
    };



    return (
        <>
            <div className="modal">
                <div className="overlay"></div>
                <div className="modal-content-rol">
                    <p>Nuevo Rol</p>
                    <input id='rol'
                        name='rol'
                        onChange={handleInputChange}
                        placeholder="Escribe el nombre del Rol">
                    </input>
                    <button className="close-modal" onClick={handleClose}>X</button>
                    <div className="modal-footer">
                        <button className="confirmation-button" onClick={handleCreateRole}>Crear</button>
                        <button className="close-button" onClick={handleClose}>Cancelar</button>
                    </div>
                </div>
            </div>
        </>
    );
}
