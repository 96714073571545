import React, { useState, useEffect } from 'react';

//import para navigate
import { useNavigate } from 'react-router-dom';

//iconos y estilo
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { FiShoppingCart } from 'react-icons/fi';
import { LiaNewspaperSolid } from 'react-icons/lia';
import { BsTruck } from 'react-icons/bs';
import { SlLock } from 'react-icons/sl';
import { FaRegBuilding, FaRegUser, FaRegMoneyBillAlt } from "react-icons/fa";

//consultas a bd
import { getPermissionsByRole } from '../firebase/permisos.js'

import './home/home.css';


function Menu(props) {

    const toggleRequisiciones = () => setIsRequisicionesOpen(!isRequisicionesOpen);
    const toggleVouchers = () => setIsVouchersOpen(!isVouchersOpen);
    const toggleProyecto = () => setIsProyectoOpen(!isProyectoOpen);

    const [isRequisicionesOpen, setIsRequisicionesOpen] = useState(false);
    const [isVouchersOpen, setIsVouchersOpen] = useState(false);
    const [isProyectoOpen, setIsProyectoOpen] = useState(false);


    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const perms = await getPermissionsByRole(props.userLoged.rol);
                setpermissions(perms);
            } catch (error) {
                console.error("Error fetching permissions:", error);
            }
        };

        fetchPermissions();
    }, []);



    let [permissions, setpermissions] = useState({});

    const [menuStates, setMenuStates] = useState({}); // Estado inicial vacío

    const toggleMenu = (menuName) => {
        setMenuStates(prevState => ({
            ...prevState,
            [menuName]: !prevState[menuName]
        }));
    };

    //ICONOS PARA CADA MENU
    const ICON_MAP = {
        "Requisiciones": <FiShoppingCart className="icon-left" />,
        "Vouchers": <LiaNewspaperSolid className="icon-left" />,
        "Proveedores": <FaRegUser className="icon-left" />,
        "Proyecto": <BsTruck className="icon-left" />,
        "Empresa": <FaRegBuilding className="icon-left" />,
        "Cta. Bancaria": <FaRegMoneyBillAlt className="icon-left" />,
        "Permisos": <SlLock className="icon-left" />
        // ... otros mapeos
    };

    //ORDEN DEL MENU
    const MENU_ORDER = ["Requisiciones", "Vouchers", "Proveedores", "Proyecto", "Empresa", "Cta. Bancaria", "Permisos"];

    // Orden de los permisos dentro de cada categoría
    const PERMISSION_ORDER = {
        "Requisiciones": ["Historial", "Solicitud", "Nueva Requisición", "Aprobación", "Rechazos", "Basurero"],
        "Vouchers": ["Historial", "Nuevo Voucher"],
        "Proveedores": ["Listado"],
        "Proyecto": ["Listado", "Nuevo Proyecto"],
        "Empresa": ["Listado"],
        "Cta. Bancaria": ["Listado"],
        "Permisos": ["Usuarios", "Roles"],
        // ... otros
    };

    // Orden del menú y títulos de sección
    const MENU_TITLES = {
        "Compras": ["Requisiciones", "Vouchers", "Proveedores"],
        "Proyecto": ["Proyecto"],
        "Finanzas": ["Empresa", "Cta. Bancaria"],
        "Configuración": ["Permisos"],
    };

    const navigate = useNavigate();

    const handleNavigation = (route) => {
        // Aquí puedes implementar cualquier lógica adicional antes de la navegación,
        // por ejemplo, limpiar estados específicos.

        navigate(route);
    };


    return (
        <>
            <div className='menuPrincipal'>
                {Object.entries(MENU_TITLES).map(([title, categories]) => {
                    const visibleCategories = categories.filter(category => permissions[category] && permissions[category].length > 0);
                    if (visibleCategories.length === 0) return null;

                    return (
                        <React.Fragment key={title}>
                            <h3>{title}</h3>
                            {visibleCategories.map(menuName => (
                                <div name={menuName} style={{ width: "100%", height: "33%" }}>
                                    <button onClick={() => toggleMenu(menuName)}>
                                        {ICON_MAP[menuName]} {menuName}
                                        {menuStates[menuName] ? <IoIosArrowDown className="icon-right" /> : <IoIosArrowForward className="icon-right" />}
                                    </button>
                                    {menuStates[menuName] && (
                                        <ul>
                                            {permissions[menuName]
                                                .sort((a, b) => {
                                                    const orderArray = PERMISSION_ORDER[menuName];
                                                    if (orderArray) {
                                                        const indexA = orderArray.indexOf(a.name);
                                                        const indexB = orderArray.indexOf(b.name);
                                                        return (indexA === -1 ? 999 : indexA) - (indexB === -1 ? 999 : indexB);
                                                    }
                                                    return 0; // Si no hay un orden definido, no cambiamos el orden
                                                })
                                                .map((perm, index) => (
                                                    <li key={index}><a href="" onClick={() => handleNavigation(`/${perm.route}`)}>{perm.name}</a></li>
                                                ))
                                            }
                                        </ul>
                                    )}
                                </div>
                            ))}
                        </React.Fragment>
                    );
                })}
            </div>
        </>
    );

}

export default Menu;