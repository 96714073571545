import { db, auth, uploadFile, storage } from './firebase'; // Importamos 'db' que es nuestra referencia a Firestore desde el archivo original de firebase
import { collection, getDocs, doc, setDoc, updateDoc, query, where, deleteDoc } from 'firebase/firestore'; // Importamos los métodos necesarios de Firestore
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// FUNCION PARA OBTENER TODAS LAS COMPAÑÍAS/EMPRESAS
export async function getAllCompanies() {
    try {
        const companyCollection = collection(db, 'companie_detail');
        const companySnapshot = await getDocs(companyCollection);

        // Verificar si hay documentos
        if (companySnapshot.empty) {
            return [];
        }

        const companyList = companySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Ordenar la lista alfabéticamente por el campo "nombre" sin importar mayúsculas o minúsculas
        companyList.sort((a, b) => a.nombre.localeCompare(b.nombre, undefined, { sensitivity: 'base' }));

        return companyList;
    } catch (error) {
        console.error("Error al obtener los datos de las empresas: ", error);
        throw error;
    }
}

//FUNCION PARA ALMACENAR LAS IMAGENES O LOGOS DE LAS EMPRESAS
export async function uploadCompanyImage(file, companyId) {
    try {
        const imageRef = ref(storage, `/companyImages/${companyId}`);
        await uploadBytes(imageRef, file);
        const imageUrl = await getDownloadURL(imageRef);
        return imageUrl;
    } catch (error) {
        console.error("Error al subir la imagen: ", error);
        return null; // Retorna null en caso de error
    }
}

//FUNCION PARA AGREGAR UNA NUEVA EMPRESA
export async function addCompany(companyData, imgFile) {
    try {

        // Verifica si ya existe una empresa con el mismo nombre y/o NIT
        const checkResult = await checkCompanyExists(companyData.nombre, companyData.nit);
        if (checkResult.exists) {
            if (checkResult.reason === "NIT") {
                return { success: false, message: "Ya existe una empresa con el mismo NIT." };
            } else if (checkResult.reason === "NOMBRE_NIT") {
                return { success: false, message: "Ya existe una empresa con el mismo nombre y NIT." };
            }
        }

        // Crea un nuevo documento con un ID automático en Firestore en la colección 'companie_detail'
        const companyRef = doc(collection(db, 'companie_detail'));

        // Primero, guarda el documento inicial sin la URL de la imagen
        await setDoc(companyRef, companyData);

        // Sube la imagen usando el UID del documento creado
        const imageUrl = await uploadCompanyImage(imgFile, companyRef.id);
        if (!imageUrl) {
            return { success: false, message: "Error al subir la imagen." };
        }

        // Actualiza el documento con la URL de la imagen
        await updateDoc(companyRef, { imageUrl: imageUrl });

        return { success: true, message: "Empresa agregada exitosamente.", imageUrl: imageUrl };
    } catch (error) {
        console.error("Error al agregar la Empresa: ", error);
        return { success: false, message: "Error al agregar la Empresa.", error: error };
    }
}

//FUNCION PARA VERIFICAR LA EXISTENCIA DE UNA EMPRESA EN CONCRETO
async function checkCompanyExists(name, nit) {
    const companiesRef = collection(db, 'companie_detail');

    // Verifica si el NIT ya existe
    const nitQuery = query(companiesRef, where("nit", "==", nit));
    const nitSnapshot = await getDocs(nitQuery);
    if (!nitSnapshot.empty) {
        return { exists: true, reason: "NIT" }; // NIT ya existe
    }

    // Verifica si la combinación de nombre y NIT ya existe
    const nameNitQuery = query(companiesRef, where("nombre", "==", name), where("nit", "==", nit));
    const nameNitSnapshot = await getDocs(nameNitQuery);
    if (!nameNitSnapshot.empty) {
        return { exists: true, reason: "NOMBRE_NIT" }; // Nombre y NIT juntos ya existen
    }

    return { exists: false }; // No hay coincidencias, es seguro proceder
}

//FUNCION PARA EDITAR UNA EMPRESA
export async function EditCompany(companyId, updatedCompany, imgFile = null) {
    try {
        const companyCollection = collection(db, 'companie_detail');

        // Verificar si se cambió el NIT y si el nuevo NIT ya está en uso
        if (updatedCompany.originalNit !== updatedCompany.nit) {
            const q = query(companyCollection, where("nit", "==", updatedCompany.nit));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                // Si existe algún documento que no sea la compañía actual con el mismo NIT
                const isAnotherCompany = querySnapshot.docs.some(doc => doc.id !== companyId);
                if (isAnotherCompany) {
                    return {
                        success: false,
                        error: "Error al editar compañía.",
                        message: "El NIT de la compañía ya existe."
                    };
                }
            }
        }

        const companyDoc = doc(companyCollection, companyId);

        // Si se ha proporcionado una nueva imagen, subirla y actualizar la URL de la imagen
        if (imgFile) {
            const imageUrl = await uploadCompanyImage(imgFile, companyId);
            if (imageUrl) {
                updatedCompany.imageUrl = imageUrl; // Actualizar la URL de la imagen en los datos de la compañía
            } else {
                return {
                    success: false,
                    error: "Error al actualizar la imagen.",
                    message: "No se pudo cargar la nueva imagen."
                };
            }
        }

        // Actualizar la compañía con los datos nuevos, incluyendo la nueva URL de la imagen si se proporcionó
        await updateDoc(companyDoc, updatedCompany);
        return {
            success: true,
            message: "Empresa actualizada exitosamente.",
            id: companyId,
            name: updatedCompany.name,
            imageUrl: updatedCompany.imageUrl || "Sin cambio de imagen"
        };

    } catch (error) {
        console.error("Error al editar la empresa: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al editar la Empresa."
        };
    }
}


// FUNCIÓN PARA ELIMINAR UNA EMPRESA POR ID
export async function deletecompanyById(companyId) {
    try {
        // Crea una referencia al documento usando el ID en la colección correcta
        const companyRef = doc(db, 'companie_detail', companyId);

        // Elimina el documento
        await deleteDoc(companyRef);

        // Retorna un mensaje de éxito
        return { success: true, message: "Empresa eliminada exitosamente." };
    } catch (error) {
        console.error("Error al eliminar la empresa: ", error);
        // Retorna un mensaje de error
        return { success: false, message: "Ocurrió un error al eliminar la empresa.", error: error };
    }
}

// FUNCIÓN PARA ELIMINAR VARIAS EMPRESAS POR ID
export async function deleteCompaniesByIds(companyIds) {
    const deleteResults = await Promise.allSettled(
        companyIds.map(companyId => {
            const companyRef = doc(db, 'companie_detail', companyId);
            return deleteDoc(companyRef)
                .then(() => ({ id: companyId, success: true }))
                .catch(error => ({ id: companyId, success: false, error }));
        })
    );

    // Mapea los resultados a un formato consistente, independientemente de si la promesa fue resuelta o rechazada
    const results = deleteResults.map(result =>
        result.status === 'fulfilled'
            ? result.value
            : { id: result.reason.id, success: false, error: result.reason.error }
    );

    return results;
}