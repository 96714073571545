import React, { useState, useEffect, useRef } from 'react';

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//import para funciones especificas de esta pantalla
import { getAllProjects, deleteProjectById, deleteProjectsByIds } from '../../firebase/proyectos.js'
import { getAllCompanies } from '../../firebase/empresas.js'
import ReactDatePicker from 'react-datepicker';

import { Link } from 'react-router-dom';

//modal de carga
import LoadingModal from '../Loading-Modal/loading.jsx'
import '../Usuarios/usuarios.css';
import '../Requisiciones/requisicion_solicitadas.css'
import '../Requisiciones/historial_requisiciones.css'

//import de iconos
import { AiFillCloseCircle } from 'react-icons/ai';
import { CiMenuKebab } from 'react-icons/ci';
import { GrFormEdit } from 'react-icons/gr';
import { FiTrash2 } from 'react-icons/fi';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';


import Modal from '../Modal/Modal.jsx'

//CLASE PARA MANEJAR LAS FECHAS
function FechaSelector({ fechaInicio, setFechaInicio, fechaFin, setFechaFin, onChange, onClose }) {

    const setLastWeek = () => {
        const today = new Date();
        const startLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7);
        const endLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 1);
        setFechaInicio(startLastWeek);
        setFechaFin(endLastWeek);
        onChange(startLastWeek, endLastWeek);
    };

    const setToday = () => {
        const today = new Date();
        setFechaInicio(today);
        setFechaFin(today);
        onChange(today, today);
    };

    const setCurrentMonth = () => {
        const today = new Date();
        setFechaInicio(new Date(today.getFullYear(), today.getMonth(), 1));
        setFechaFin(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        onChange(new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0));
    };

    const setPreviousMonth = () => {
        const today = new Date();
        setFechaInicio(new Date(today.getFullYear(), today.getMonth() - 1, 1));
        setFechaFin(new Date(today.getFullYear(), today.getMonth(), 0));
        onChange(new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0));
    };

    const ref = useRef();

    // Llamar a onChange cuando se actualizan las fechas
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose();
            }
        }

        // Agregar listener al montar
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Eliminar listener al desmontar
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div ref={ref} className="selector-fecha" style={{ width: "100%", height: "100%", justifyContent: "center", alignContent: "center" }}>
            <div className="custom-buttons-date" style={{ display: "-webkit-box", marginTop: "10px" }}>
                <label className='label-custom-fechas' style={{ marginRight: "5px" }}>Inicio:</label>
                <ReactDatePicker
                    selected={fechaInicio}
                    onChange={date => {
                        setFechaInicio(date);
                        if (fechaFin) { // Si ya se ha seleccionado la fecha de fin
                            onChange(date, fechaFin);
                        }
                    }}

                    selectsStart
                    startDate={fechaInicio}
                    endDate={fechaFin}
                    dateFormat="dd/MM/yyyy"
                />
                <label style={{ marginRight: "5px" }}>Fin:</label>
                <ReactDatePicker
                    selected={fechaFin}
                    onChange={date => {
                        setFechaFin(date);
                        onChange(fechaInicio, date); // Usando la prop onChange
                        onClose();
                    }}

                    selectsEnd
                    startDate={fechaInicio}
                    endDate={fechaFin}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div className="custom-buttons-fechas">
                <button className='button-custom-fechas' type="button" onClick={setToday}>Hoy</button>
                <button className='button-custom-fechas' type="button" onClick={setLastWeek}>Semana Pasada</button>
                <button className='button-custom-fechas' type="button" onClick={setCurrentMonth}>Mes Actual</button>
                <button className='button-custom-fechas' type="button" onClick={setPreviousMonth}>Mes Pasado</button>
            </div>

        </div>

    );
}


function HistorialProyectos(props) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar el modal de carga


    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);
    const [Projects, setProjects] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({});


    //Estado para manejar el listado de compañias o empresas a mostrar en el Dropdown
    const [companies, setCompanies] = useState([]);

    // Estado para rastrear qué proyecto han sido seleccionados
    const [selectedProjects, setselectedProjects] = useState([]);
    const [selectAll, setSelectAll] = useState(false); // Estado para el checkbox de la cabecera


    const toggleDropdown = (projectID) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [projectID]: !prevState[projectID]
        }));
    };

    const dropdownRef = useRef(null);

    //AQUI ESTA EL MODAL
    const [showModal, setShowModal] = useState(false);
    const [showModalForprojectID, setShowModalForProjectId] = useState(null);

    const [showMultiDeleteModal, setShowMultiDeleteModal] = useState(false);

    const handleTrashClick = (projectID) => {
        setShowModalForProjectId(projectID);
    };
    //AQUI TERMINA EL MODAL

    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleProjectStateChanged);
            hasCheckedPermission.current = true;
        }

        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                if (event.target.className !== 'dontCloseDropdown' && !showModal) {
                    setDropdownOpen({});
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, []);


    async function handleProjectStateChanged(user) {
        if (user) {
            setIsLoading(true);
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //cargar las empresas
                    const companyList = await getAllCompanies();
                    setCompanies(companyList);

                    setLoading(false); // para saber si termino de cargar los datos de las empresas

                    const canAccess = await hasPermission(res.rol, "proyecto/historial");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }

                    setProjects(await getAllProjects());

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
                setIsLoading(false);
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }


    //AQUI ESTA EL PAGINADOR
    const [currentPage, setCurrentPage] = useState(() => {
        const savedPage = localStorage.getItem('currentPageProyectos');
        return savedPage ? Number(savedPage) : 1;
    });
    const [itemsPerPage] = useState(4);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const totalItems = Projects.length;
    const [totalPages, setTotalPages] = useState(0);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        localStorage.setItem('currentPageProyectos', pageNumber);
    };

    const [searchName, setSearchName] = useState('');
    const [searchCompany, setsearchCompany] = useState('');


    const InputChangeUsr = (event) => {
        const { name, value } = event.target;
        if (name === 'nombre') {
            setSearchName(value);
            //local storage
            saveFiltersToLocalStorage({
                searchName: value,
                searchCompany,
                searchFecha,
                fechaInicio,
                fechaFin
            });

        } else if (name === 'rol') {
            setsearchCompany(value);
        }
        if (value) {
            document.getElementById(name).classList.add('input-active');
        } else {
            document.getElementById(name).classList.remove('input-active');
        }
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador
    };

    const [fechaActiva, setFechaActiva] = useState(false);
    const [mostrarSelectorFechas, setMostrarSelectorFechas] = useState(false);
    const [searchFecha, setSearchFecha] = useState('');
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);

    const mostrarSelector = () => {
        setFechaActiva(true);
        setMostrarSelectorFechas(true);
    };

    const cerrarSelectorFecha = () => {
        setMostrarSelectorFechas(false); // Asume que 'setMostrarSelectorFechas' es tu función para controlar la visibilidad
    };

    const manejadorCambioFecha = (inicio, fin) => {
        const formatoFecha = fecha => fecha.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const fechaFormateada = `${formatoFecha(inicio)} - ${formatoFecha(fin)}`;
        setSearchFecha(`${formatoFecha(inicio)} - ${formatoFecha(fin)}`);
        setFechaInicio(inicio);
        setFechaFin(fin);

        //local storage
        saveFiltersToLocalStorage({
            searchName,
            searchCompany,
            searchFecha: fechaFormateada,
            fechaInicio: inicio,
            fechaFin: fin
        });
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador

    };

    const filteredProjects = Projects.filter(project => {

        // Convertir la fecha de inicio de los proyectos desde un timestamp de Firebase a un objeto Date
        const fechaProyecto = new Date(project.project_fecha_inicio?.seconds * 1000);
        const fechaProyectoInicio = new Date(fechaProyecto.setHours(0, 0, 0, 0));
        const fechaProyectoFin = new Date(fechaProyecto.setHours(23, 59, 59, 999));

        // Establecer el inicio y fin del día para la fechaInicio
        const startOfDay = fechaInicio ? new Date(fechaInicio.setHours(0, 0, 0, 0)) : null;
        const endOfDay = fechaFin ? new Date(fechaFin.setHours(23, 59, 59, 999)) : null;


        // Verificar si la fecha de la requisición está dentro del rango seleccionado
        const enRangoDeFechas = !startOfDay || !endOfDay || (fechaProyectoFin >= startOfDay && fechaProyectoInicio <= endOfDay);


        return (
            project.project_name.toLowerCase().includes(searchName.toLowerCase()) &&
            enRangoDeFechas &&
            project.project_company.toLowerCase().includes(searchCompany.toLowerCase())
        );
    });

    useEffect(() => {
        setTotalPages(Math.ceil(filteredProjects.length / itemsPerPage));
    }, [filteredProjects, itemsPerPage]);

    const [executionCount, setExecutionCount] = useState(0);

    //Restaurar los Filtros desde localStorage al Cargar la Página
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('filtersProjects'));
        if (savedFilters) {
            setSearchName(savedFilters.searchName || '');
            setsearchCompany(savedFilters.searchCompany || '');
            setSelectedRole(savedFilters.selectedRole || '');
            setSearchFecha(savedFilters.searchFecha || '');
            setFechaInicio(savedFilters.fechaInicio ? new Date(savedFilters.fechaInicio) : null);
            setFechaFin(savedFilters.fechaFin ? new Date(savedFilters.fechaFin) : null);
        }

        const savedPage = localStorage.getItem('currentPageProyectos');

        if (savedPage) {
            setCurrentPage(Number(savedPage));
        }

    }, []);

    useEffect(() => {
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador

        const lastURL = localStorage.getItem('lastURL');
        if ((executionCount >= 3) || (lastURL != 'proyecto/historial')) {
            setCurrentPage(1);
            localStorage.setItem('currentPageProyectos', 1);
            localStorage.setItem('lastURL', 'proyecto/historial');
        }
    }, [searchName, searchCompany, fechaInicio, fechaFin]);

    //SECCION PARA MANEJAR HISTORIAL EN FILTROS POR LOCALSTORAGE

    //Guardar los Filtros en localStorage
    const saveFiltersToLocalStorage = (filters) => {
        localStorage.setItem('filtersProjects', JSON.stringify(filters));
    };

    const currentItems = filteredProjects.slice(indexOfFirstItem, indexOfLastItem);


    const [isRoleOpen, setRoleOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState("");

    const handleCompanyChange = (company) => {

        if (company === '') {
            setSelectedRole('Selecciona la Empresa');
            setsearchCompany('');
        } else {
            setSelectedRole(company);
            setsearchCompany(company);
        }

        setRoleOpen(false);

        // local storage
        saveFiltersToLocalStorage({
            searchName,
            searchCompany: company,
            selectedRole: company,
            searchFecha,
            fechaInicio,
            fechaFin
        });
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador

    };

    // Efecto para manejar el clic fuera del menú
    useEffect(() => {
        function handleDocumentClick(event) {

            // Verificar si el clic es fuera del menú de roles
            if (isRoleOpen && !event.target.closest('.field-container-usuarios')) {
                setRoleOpen(false);
            }

        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isRoleOpen]); // Dependencias del efecto



    const goToEditProject = (ProjectsSel) => {
        navigate('./editar', { state: { ProjectsSel } });
    };

    const deleteProjects = async (projectList) => {

        setIsLoading(true);
        try {
            const result = await deleteProjectById(projectList.id);
            if (result.success) {
                // Actualizar el estado para excluir el proyecto eliminado
                setProjects(prevProjects => prevProjects.filter(user => user.id !== projectList.id));
                props.ShowDone(`Se elimino el proyecto: ${projectList.project_name}`);
            } else {
                // Manejar el caso en que la eliminación falla
                props.showErr(`El proyecto ${projectList.project_name} no se pudo eliminar.`);
                props.ShowWar(result.message);
            }
            setIsLoading(false);

        } catch (error) {
            console.log(error);
            props.showErr(`El proyecto ${projectList.project_name} no se pudo eliminar.`);
            setIsLoading(false);
        }


    };

    // Función para manejar la selección de un proyecto
    const handleProjectsSelect = (proy, isSelected) => {
        if (isSelected) {
            setselectedProjects([...selectedProjects, proy]);
        } else {
            setselectedProjects(selectedProjects.filter(u => u.id !== proy.id));
            setSelectAll(false); // Desactivar el checkbox de la cabecera si un proyecto se deselecciona
        }
    };

    // Función para manejar el cambio en el checkbox de la cabecera
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);

        if (!selectAll) {
            setselectedProjects(currentItems);
        } else {
            setselectedProjects([]);
        }
    };

    const handleShowModalMulti = () => {
        setShowMultiDeleteModal(true);
        setDropdownOpen({});
    };

    // Función para manejar la eliminación de proyectos seleccionados
    const handleDeleteselectedProjects = async () => {

        setIsLoading(true);
        try {

            // Extrae solo los IDs de cada proyecto
            const projectIds = selectedProjects.map(project => project.id);

            const results = await deleteProjectsByIds(projectIds);
            const successfulDeletes = results.filter(result => result.success).map(result => result.id);
            const failedDeletes = results.filter(result => !result.success);

            // Actualizar el estado para excluir los proyectos eliminados exitosamente
            setProjects(prevProjects => prevProjects.filter(pr => !successfulDeletes.includes(pr.id)));

            // Desactivar el checkbox de la cabecera
            setSelectAll(false);

            // Limpiar la lista de proyectos seleccionados
            setselectedProjects([]);

            // Cerrar el modal una vez que se haya completado la eliminación
            setShowMultiDeleteModal(false);

            if ((successfulDeletes.length === selectedProjects.length) && (successfulDeletes.length > 0)) {
                props.ShowDone(`Se eliminaron ${successfulDeletes.length} proyectos`);
            } else {
                if (successfulDeletes.length > 0) {
                    props.ShowDone(`Se eliminaron ${successfulDeletes.length} proyectos`);
                }

                failedDeletes.forEach(failed => {
                    props.showErr(`El proyecto no se pudo eliminar.`);
                    props.ShowWar(failed.message);
                });
            }

            setIsLoading(false);

        } catch (error) {
            props.showErr('Ocurrio un error inesperado.');
            console.log(error);
            setIsLoading(false);
        }

    };

    const handleModalDelete = (projectID) => {
        const projectToDelete = Projects.find(u => u.id === projectID);
        if (projectToDelete) {
            deleteProjects(projectToDelete);
        }
        setShowModalForProjectId(null);  // Cerrar el modal
    };


    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />

                    <div className='bodyHome-user' style={{ height: "210vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div style={{ width: "100%" }}>
                            <p>Proyectos</p>
                            <div className='filter-use-p'>
                                <div className='filter-use'>
                                    <form style={{ width: "80%" }}>
                                        <div className='filter-userDetails'>
                                            <label>Nombre</label>
                                            <label>Empresa</label>
                                            <label>Fecha de inicio</label>
                                            <div className="input-with-icon">
                                                <input
                                                    id='nombre'
                                                    name='nombre'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el nombre del proyecto"
                                                    value={searchName}
                                                />
                                                {searchName && <AiFillCloseCircle className="close-icon-name" onClick={() => {
                                                    setSearchName('');
                                                    // limpiar local storage
                                                    saveFiltersToLocalStorage({
                                                        searchName: '',
                                                        searchCompany,
                                                        searchFecha,
                                                        fechaInicio,
                                                        fechaFin
                                                    });
                                                    document.getElementById('nombre').value = '';
                                                }} />}
                                            </div>
                                            <div className="field-container-usuarios">
                                                <button className="boton-desplegable-usuari" type="button" onClick={() => setRoleOpen(!isRoleOpen)} >
                                                    {selectedRole || "Selecciona la empresa"}
                                                    {isRoleOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                                </button>

                                                {isRoleOpen && (
                                                    <div className="dropdown-container-usuarios">
                                                        <ul>
                                                            <li key='12358d'>
                                                                <button className="boton-desplegable-usuari" type="button" onClick={() => handleCompanyChange('')}>
                                                                    Selecciona la empresa
                                                                </button>
                                                            </li>
                                                            {companies.map((company, index) => (
                                                                <li key={index}>
                                                                    <button className="boton-desplegable-usuari" type=" button" onClick={(e) => handleCompanyChange(company.nombre)}>
                                                                        {company.nombre}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="input-with-icon" style={{ display: "flex" }}>
                                                <input id='fecha' name='fecha' className={` ${fechaActiva ? '' : ''}`} onClick={(e) => {
                                                    e.stopPropagation(); // Detiene la propagación del evento
                                                    mostrarSelector();
                                                }}
                                                    placeholder='Seleccione una fecha' readOnly value={searchFecha} />
                                                {searchFecha && <AiFillCloseCircle style={{ top: "5px", right: "30px" }} className="historial-clear-icon" onClick={() => {
                                                    setSearchFecha('');
                                                    setFechaInicio(null);
                                                    setFechaFin(null);
                                                    setFechaActiva(false);
                                                    // limpiar local storage
                                                    saveFiltersToLocalStorage({
                                                        searchName,
                                                        searchCompany,
                                                        searchFecha: '',
                                                        fechaInicio: '',
                                                        fechaFin: ''
                                                    });
                                                }} />}
                                                {mostrarSelectorFechas ? (
                                                    <IoIosArrowUp className="icono" onClick={() => setMostrarSelectorFechas(false)} />
                                                ) : (
                                                    <IoIosArrowDown className="icono" onClick={() => setMostrarSelectorFechas(true)} />
                                                )}
                                                {mostrarSelectorFechas && (
                                                    <div className="fecha-selector-container" style={{ top: "30px" }}>
                                                        <FechaSelector
                                                            fechaInicio={fechaInicio}
                                                            setFechaInicio={setFechaInicio}
                                                            fechaFin={fechaFin}
                                                            setFechaFin={setFechaFin}
                                                            onChange={manejadorCambioFecha}
                                                            onClose={cerrarSelectorFecha}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <Link to="/proyecto/nuevo">
                                    <button className='filter-use-button'>+ Crear Proyecto</button>
                                </Link>
                            </div>
                            <div className='delete-users' >
                                <button onClick={handleShowModalMulti} style={{ cursor: "pointer" }}><FiTrash2></FiTrash2> Eliminar</button>
                            </div>
                            {showMultiDeleteModal &&
                                <Modal
                                    selectedProjects={selectedProjects}
                                    initialOpen={true}
                                    close={() => setShowMultiDeleteModal(false)}
                                    onDelete={handleDeleteselectedProjects}
                                />
                            }
                            <div className='user-details'>
                                <table className='tabla-usuarios'>
                                    <thead>
                                        <tr className='tabla-header'>
                                            <th style={{ paddingTop: "2%", paddingBottom: "2%", paddingLeft: "3%", width: "7%" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th>Nombre del proyecto</th>
                                            <th>Empresa</th>
                                            <th>Fecha de inicio</th>
                                            <th>Acciones</th>
                                        </tr>
                                        <tr className="separator">
                                            <td colSpan="5"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems.map((project, index) => (
                                            <React.Fragment key={project.id}>
                                                <tr key={project.id} >
                                                    <td style={{ paddingLeft: "3%" }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedProjects.some(u => u.id === project.id)}
                                                            onChange={e => handleProjectsSelect(project, e.target.checked)}
                                                        />
                                                    </td>
                                                    <td style={{ height: "9vh" }}>{project.project_name}</td>
                                                    <td>{project.project_company}</td>
                                                    <td>{project.project_fecha_inicio ? project.project_fecha_inicio.toDate().toLocaleString('es-GT', {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit'
                                                    }).replace(/(\d+)\/(\d+)\/(\d+)/i, '$1/$2/$3')
                                                        : ""}
                                                    </td>
                                                    <td style={{ paddingLeft: "2%" }}>
                                                        <div className='menu-usuarios' ref={dropdownRef} onClick={(e) => e.stopPropagation()}>
                                                            <button className="icon-button" onClick={() => toggleDropdown(project.id)}><CiMenuKebab /></button>

                                                            {!showMultiDeleteModal && dropdownOpen[project.id] && (
                                                                <div className="dropdownMenu">
                                                                    <ul>
                                                                        <li><a className="dontCloseDropdown" onClick={() => goToEditProject(project)}><GrFormEdit></GrFormEdit>Editar</a></li>
                                                                        <li><a className="dontCloseDropdown" onClick={() => { setShowModalForProjectId(project.id); setDropdownOpen({}); }}><FiTrash2></FiTrash2>Eliminar</a></li>
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="separator">
                                                    <td colSpan="5"></td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='paginador-usuarios'>
                                    <span>{currentPage} de {totalPages} </span>
                                    <div>
                                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}><MdArrowBackIosNew /> Anterior</button>
                                        {currentPage > 1 && <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>}
                                        <button style={{ fontWeight: "bold" }} className={currentPage === currentPage ? "selected-page" : ""}>{currentPage}</button>
                                        {currentPage < totalPages && <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>}
                                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Siguiente <MdArrowForwardIos /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            )
            }
            {
                showModalForprojectID &&
                <Modal
                    project={Projects.find(u => u.id === showModalForprojectID)}
                    initialOpen={true}
                    close={() => setShowModalForProjectId(null)}
                    onDelete={() => handleModalDelete(showModalForprojectID)}
                />
            }
            {isLoading && <LoadingModal />}
        </>
    );
}

export default HistorialProyectos;