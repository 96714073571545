import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, updateLastLogin } from '../firebase/firebase';
import { AiOutlineUser, AiOutlinePoweroff } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { HiOutlineCog } from 'react-icons/hi';


import './home/home.css';

function HeaderHome(props) {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);

    const [selectedMenu, setSelectedMenu] = useState(null);

    const handleMenuClick = (menu) => {
        setSelectedMenu(menu);
        switch (menu) {
            case 'profile':
                navigate('/user');
                break;
            case 'settings':
                navigate('/editusr');
                break;
            case 'logout':
                logOut();
                break;
            default:
                break;
        }
    };

    // Metodo para cerrar sesion
    const logOut = async () => {
        await updateLastLogin(auth.currentUser.uid);
        await signOut(auth);
    };

    return (
        <>
            <div className='headerHome'>
                <h2 onClick={() => navigate('/home')}>Codeguanet</h2>
                <div className='menuDesplegable'>
                    <button onClick={toggleOpen}>
                        <img src={props.userLoged.foto} alt='userPicture'></img>
                        {props.userLoged.nombre}
                        {isOpen ? <IoIosArrowUp className="icon-right-perfil" /> : <IoIosArrowDown className="icon-right-perfil" />}
                    </button>
                    {isOpen && (
                        <ul>
                            <li><a href="#" onClick={() => handleMenuClick('profile')} className={selectedMenu === 'profile' ? 'selected' : ''}><AiOutlineUser className="icon-left-perfil" />Mi Perfil</a></li>
                            <li><a href="#" onClick={() => handleMenuClick('settings')} className={selectedMenu === 'settings' ? 'selected' : ''}><HiOutlineCog className="icon-left-perfil" />Ajuste</a></li>
                            <li><a href="#" onClick={() => handleMenuClick('logout')} className={selectedMenu === 'logout' ? 'selected' : ''}><AiOutlinePoweroff className="icon-left-perfil" />Cerrar Sesión</a></li>
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
}

export default HeaderHome;
