import { db, auth, uploadFile, storage } from './firebase'; // Importamos 'db' que es nuestra referencia a Firestore desde el archivo original de firebase
import { collection, getDocs, doc, setDoc, updateDoc, query, where, deleteDoc, addDoc } from 'firebase/firestore'; // Importamos los métodos necesarios de Firestore
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// FUNCION PARA OBTENER TODAS LAS COMPAÑÍAS/EMPRESAS
export async function getAllAccounts() {
    try {
        const accountCollection = collection(db, 'accounts_detail');
        const accountSnapshot = await getDocs(accountCollection);

        // Verificar si hay documentos
        if (accountSnapshot.empty) {
            return [];
        }

        const accountList = accountSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Ordenar la lista alfabéticamente por el campo "name" sin importar mayúsculas o minúsculas
        accountList.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

        return accountList;

    } catch (error) {
        console.error("Error al obtener los datos de las cuentas: ", error);
        throw error;
    }
}

// FUNCION PARA OBTENER TODAS LAS MONEDAS
export async function getCurrencies() {
    try {
        const currencyCollection = collection(db, 'currency');
        const currencySnapshot = await getDocs(currencyCollection);
        const currencyList = currencySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return currencyList;
    } catch (error) {
        console.error("Error al obtener los datos de las monedas: ", error);
        throw error;
    }
}

// FUNCION PARA OBTENER TODOS LOS BANCOS
export async function getBanks() {
    try {
        const bankCollection = collection(db, 'bank');
        const bankSnapshot = await getDocs(bankCollection);

        // Verificar si hay documentos
        if (bankSnapshot.empty) {
            return [];
        }

        const bankList = bankSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Ordenar la lista alfabéticamente por el campo "name" sin importar mayúsculas o minúsculas
        bankList.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

        return bankList;

    } catch (error) {
        console.error("Error al obtener los datos de los bancos: ", error);
        throw error;
    }
}

// FUNCION PARA AGREGAR UNA NUEVA CUENTA
export async function addAccount(newAccount) {
    try {
        const accountCollection = collection(db, 'accounts_detail');

        // Validar si ya existe una cuenta con ese número de cuenta
        const q = query(accountCollection, where("account_number", "==", newAccount.account_number));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            // La cuenta ya existe
            return {
                success: false,
                error: "Error al agregar cuenta.",
                message: "El número de cuenta ya existe."
            };
        }

        // Si no existe, agregarla con todos los detalles
        const docRef = await addDoc(accountCollection, newAccount);
        return {
            success: true,
            message: "Cuenta agregada exitosamente.",
            id: docRef.id,
            account_number: newAccount.account_number
        };

    } catch (error) {
        console.error("Error al agregar cuenta: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al agregar la cuenta."
        }
    }
}

// FUNCION PARA EDITAR UNA CUENTA
export async function editAccount(updatedAccount) {
    try {
        // Accede a la colección 'accounts_detail'
        const accountCollection = collection(db, 'accounts_detail');

        // Crea una consulta para buscar cuentas con el mismo número de cuenta
        const q = query(accountCollection, where("account_number", "==", updatedAccount.account_number));

        // Ejecuta la consulta
        const querySnapshot = await getDocs(q);

        // Filtra las cuentas que no sean la cuenta actual
        const duplicateAccounts = querySnapshot.docs.filter(docSnap => docSnap.id !== updatedAccount.id);

        // Verifica si ya existe una cuenta con el mismo número de cuenta
        if (duplicateAccounts.length > 0) {
            return {
                success: false,
                error: "No se puede editar la cuenta",
                message: "El número de cuenta ya existe."
            };
        }

        // Crea una referencia al documento que se va a actualizar
        const accountRef = doc(db, 'accounts_detail', updatedAccount.id);

        // Objeto con los nuevos datos de la cuenta
        const { id, ...accountData } = updatedAccount; // Excluir el id de los datos a actualizar

        // Realiza la actualización del documento
        await updateDoc(accountRef, accountData);

        // Retorna un mensaje de éxito
        return { success: true, message: "Cuenta actualizada exitosamente." };
    } catch (error) {
        console.error("Error al editar la cuenta: ", error);
        // Retorna un mensaje de error
        return { success: false, message: "Ocurrió un error al editar la cuenta.", error: error };
    }
}

// FUNCION PARA ELIMINAR UNA CUENTA POR ID
export async function deleteAccountById(accountId) {
    try {
        // Crea una referencia al documento usando el ID
        const accountRef = doc(db, 'accounts_detail', accountId);

        // Elimina el documento
        await deleteDoc(accountRef);

        // Retorna un mensaje de éxito
        return { success: true, message: "Cuenta eliminada exitosamente." };
    } catch (error) {
        console.error("Error al eliminar la cuenta: ", error);
        // Retorna un mensaje de error
        return { success: false, message: "Ocurrió un error al eliminar la cuenta.", error: error };
    }
}

// FUNCION PARA ELIMINAR VARIAS CUENTAS POR ID
export async function deleteAccountsByIds(accountIds) {
    const deleteResults = await Promise.allSettled(
        accountIds.map(accountId => {
            const accountRef = doc(db, 'accounts_detail', accountId);
            return deleteDoc(accountRef)
                .then(() => ({ id: accountId, success: true }))
                .catch(error => ({ id: accountId, success: false, error }));
        })
    );

    // Mapea los resultados a un formato consistente, independientemente de si la promesa fue resuelta o rechazada
    const results = deleteResults.map(result =>
        result.status === 'fulfilled'
            ? result.value
            : { id: result.reason.id, success: false, error: result.reason.error }
    );

    return results;
}