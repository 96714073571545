import { db } from './firebase'; // Importamos 'db' que es nuestra referencia a Firestore desde el archivo original de firebase
import { doc, getDoc } from 'firebase/firestore'; // Importamos los métodos necesarios de Firestore


// Función para obtener permisos por rol
export async function getPermissionsByRole(roleName) {
    try {
        // Obtener la referencia al documento del rol dentro de la colección 'role_permissions'
        const roleRef = doc(db, "role_permissions", roleName);

        // Consultar el documento
        const roleSnapshot = await getDoc(roleRef);

        // Si el documento existe, devolver los datos (permisos), sino devolver null
        return roleSnapshot.exists() ? roleSnapshot.data() : null;
    } catch (error) {
        console.error("Error obteniendo permisos:", error);
        return null;
    }
}

export async function hasPermission(rol, desiredRoute) {
    try {
        //consulta toda la coleccion de permisos a la que deberia tener acceso ese usuario
        const permissions = await getPermissionsByRole(rol);

        for (const menuName in permissions) {
            const routes = permissions[menuName].map(permission => permission.route);
            if (routes.includes(desiredRoute)) {
                return true;
            }
        }
        return false;

    } catch (error) {
        console.error("Error Verificando los permisos:", error);
        return false;
    }
}
