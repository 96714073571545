import React, { useEffect, useState, useRef } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//import para funciones especificas de esta pantalla
import { useLocation } from 'react-router-dom';
import { EditCompany } from '../../firebase/empresas.js'
import defaultCompanyImage from '../../assets/images/Logo_Codegua.png';


import { IoIosArrowBack } from 'react-icons/io';
//import './proveedores.css'

function EditarEmpresa(props) {

    const navigate = useNavigate();

    // constante para recibir el state desde la pantalla que solicito la edicion
    const location = useLocation();
    const { CompanySel } = location.state || {};

    // Utiliza useEffect para manejar efectos secundarios
    useEffect(() => {
        // Verifica que CompanySel exista, si no, muestra error y navega
        if (!CompanySel) {
            props.showErr('Usted no tiene permisos para acceder a esta función.');
            navigate('/home');
        }
    }, [navigate, props, CompanySel]); // Incluye solo las dependencias que afectan este efecto


    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);
    const [touchedFields, setTouchedFields] = useState({});

    const [imgfile, setImgfile] = useState(defaultCompanyImage);
    const [ImgTemp, setImgTemp] = useState();
    const [UpdateImg, setUpdateImg] = useState(false);

    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));


                    fetch(defaultCompanyImage)
                        .then(res => res.blob())
                        .then(blob => {
                            setImgfile(blob);
                        })
                        .catch(error => {
                            console.error("Error al convertir la imagen predeterminada en Blob:", error);
                        });

                    setLoading(false); // para saber si termino de cargar los datos del usuario

                    const canAccess = await hasPermission(res.rol, "empresas/listado");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }

                    //Cargar datos del proyecto
                    loadDataProject();

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    const [update, setUpdate] = useState(false);
    const [nombreP, setnombreP] = useState('');
    const [Propietario, setPropietario] = useState('');
    const [Correo, setCorreo] = useState('');
    const [Nit, setNit] = useState('');
    const [Telefono, setTelefono] = useState('');
    const [Direccion, setDireccion] = useState('');
    const [Foto, setFoto] = useState('');

    //funcion para cargar datos de la empres  hacia los estados:
    const loadDataProject = () => {

        try {
            setnombreP(CompanySel.nombre);
            setPropietario(CompanySel.propietario ? CompanySel.propietario : '');
            setCorreo(CompanySel.email ? CompanySel.email : '');
            setNit(CompanySel.nit ? CompanySel.nit : '');
            setTelefono(CompanySel.telefono ? CompanySel.telefono : '');
            setDireccion(CompanySel.direccion ? CompanySel.direccion : '');

            // Asegurar que se use la imagen por defecto si CompanySel.imageUrl no está disponible
            setImgfile(CompanySel.imageUrl || defaultCompanyImage);


            setUpdate(false);

        } catch (error) {
            props.showErr('Usted no tiene permisos para acceder a esta función.')
            navigate('/home');
            return;

        }

    }


    const InputChangeUsr = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'nombreP':
                setnombreP(value);
                break;
            case 'propietario':
                setPropietario(value);
                break;
            case 'correo':
                setCorreo(value);
                break;
            case 'nit':
                setNit(value);
                break;
            case 'telefono':
                if (/^[0-9]*$/.test(value) || value === '') { // Permite solo números

                    setTelefono(value);

                } else {

                    props.ShowWar('El campo solo admite números.');
                }

                break;
            case 'direccion':
                setDireccion(value);
                break;
            default:
                setFoto(value);
                break;
        }
        setTouchedFields(prevState => ({ ...prevState, [name]: true }));
        setUpdate(true);
    };

    // useEffect para verificar que los datos necesarios contengan informacion
    useEffect(() => {
        // Comprobar si todos los campos requeridos tienen datos
        const allFieldsFilled = nombreP &&
            Propietario &&
            Nit &&
            update;

        // Habilitar o deshabilitar el botón basado en si todos los campos requeridos están llenos
        setUpdate(allFieldsFilled);
    }, [nombreP, Propietario, Nit]);

    // Función para validar el correo electrónico
    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    //enviar a guardar el proyecto
    const handleSaveChanges = async () => {

        try {

            if (!nombreP || !Propietario || !Nit) {
                props.ShowWar('Por favor llena los campos minimos necesarios "*"');

            } else {
                if (Correo != '' && !validateEmail(Correo)) {
                    props.ShowWar('Verifique el formato del Correo Electrónico.');
                    return;
                } else if (nombreP.trim().length < 1) {
                    props.ShowWar('Debes indicar un "Nombre" valido.');
                    return;
                }

                //Creando la estructura para la compañia
                const updatedCompany = {
                    originalNit: CompanySel.nit || '', // Asigna un valor vacío si no existe
                    nombre: nombreP.trim(),
                    propietario: Propietario.trim(),
                    email: Correo.trim(),
                    nit: Nit.trim(),
                    telefono: Telefono.trim(),
                    direccion: Direccion.trim(),
                };

                let imageToSend = null;

                if (UpdateImg) {
                    // Si se debe actualizar la imagen, usa el nuevo archivo imgFile
                    imageToSend = imgfile;
                } else if (!UpdateImg && (!CompanySel.imageUrl || CompanySel.imageUrl === '')) {
                    // Si no se actualiza la imagen y no hay imagen existente, establece la imagen por defecto
                    // Asegúrate de que "defaultCompanyImage" es accesible, quizás necesites cargarlo o definir cómo obtener esta imagen
                    imageToSend = await convertImageToBlob(defaultCompanyImage);

                } else {
                    // Si no se actualiza la imagen y ya existe una, envía null
                    imageToSend = null;
                }

                const { success, message, error } = await EditCompany(CompanySel.id, updatedCompany, imageToSend);

                if (success) {
                    setnombreP('');
                    setPropietario('');
                    setCorreo('');
                    setNit('');
                    setTelefono('');
                    setDireccion('');


                    props.ShowDone(message);
                    setUpdate(false);

                    navigate("/empresas/listado")

                } else {
                    props.showErr(error);
                    props.ShowWar(message);
                }
            }


        } catch (error) {
            props.showErr("No es posible crear la empresa.");
        }
    };

    // Convierte una imagen de URL a Blob
    const convertImageToBlob = async (imageUrl) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        return new File([blob], "default_image.png", { type: "image/png" });
    };

    //para cargar imagen

    const SelectImg = async (e) => {
        //console.log(e.target.files[0]);
        const file = e.target.files[0];
        if (file) {
            setImgfile(file);
            setImgTemp(URL.createObjectURL(file));
        }

        setUpdateImg(true);
        setUpdate(true);

    };

    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' style={{ height: "210vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div style={{ width: "100%" }}>
                            <a className="back-button-requis" onClick={() => navigate("/empresas/listado")}> <IoIosArrowBack style={{ marginRight: "10px" }} /> Regresar</a>
                            <p style={{ fontWeight: "500", marginTop: "10vh" }}>Editar Empresa</p>
                            <form><div className='userContainer-body'>                                    <div className='card-nuevo-proyecto-div'>
                                <div className='userContainer'>
                                    <div className='column1'>
                                        <img src={ImgTemp || CompanySel.imageUrl || defaultCompanyImage} alt="Preview" />
                                        <input type="file" name="srcimg" id="srcimg" accept="image/*" style={{ display: 'none' }} onChange={SelectImg} />
                                        <label htmlFor="srcimg" style={{ cursor: 'pointer' }}>
                                            <span style={{ color: '#3A93F7' }}>Cambiar logo</span>
                                        </label>
                                    </div>
                                    <div className='column2'>
                                        <span className="span-empresa">
                                            Nombre
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                        <input
                                            style={{ borderColor: touchedFields['nombreP'] ? '#3A93F7' : '' }}
                                            className="columns-input-empresa"
                                            id='nombreP'
                                            name='nombreP'
                                            onChange={InputChangeUsr}
                                            placeholder="Escribe el nombre de la empresa"
                                            value={nombreP}
                                        />
                                        <span className="span-empresa">
                                            Propietario/Rep. legal
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                        <input
                                            style={{ borderColor: touchedFields['propietario'] ? '#3A93F7' : '' }}
                                            className="columns-input-empresa"
                                            id='propietario'
                                            name='propietario'
                                            onChange={InputChangeUsr}
                                            placeholder="Escribe el nombre dele propietario"
                                            value={Propietario}
                                        />
                                        <span className="span-empresa">Correo Electrónico</span>
                                        <input
                                            style={{ borderColor: touchedFields['correo'] ? '#3A93F7' : '' }}
                                            className="columns-input-empresa"
                                            type='email'
                                            id='correo'
                                            name='correo'
                                            onChange={InputChangeUsr}
                                            placeholder="Escribe el correo electrónico"
                                            value={Correo}
                                        />
                                    </div>
                                    <div className='column3'>
                                        <span className="span-empresa">
                                            NIT
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                        <input
                                            style={{ borderColor: touchedFields['nit'] ? '#3A93F7' : '' }}
                                            className="columns-input-empresa"
                                            id='nit'
                                            name='nit'
                                            onChange={InputChangeUsr}
                                            placeholder="Escribe el número de NIT"
                                            value={Nit}
                                        />
                                        <span className="span-empresa">Número de teléfono</span>
                                        <input
                                            style={{ borderColor: touchedFields['telefono'] ? '#3A93F7' : '' }}
                                            className="columns-input-empresa"
                                            id='telefono'
                                            name='telefono'
                                            onChange={InputChangeUsr}
                                            placeholder="Escribe el número de teléfono"
                                            value={Telefono}
                                        />
                                        <span className="span-empresa">Dirección</span>
                                        <input
                                            style={{ borderColor: touchedFields['direccion'] ? '#3A93F7' : '' }}
                                            className="columns-input-empresa"
                                            id='direccion'
                                            name='direccion'
                                            onChange={InputChangeUsr}
                                            placeholder="Escribe la dirección"
                                            value={Direccion}
                                        />
                                    </div>
                                </div>
                            </div>
                            </div></form>
                            <div className='button-container-proyecto'>
                                {update ? (
                                    <button className='botonPrincipal-proyecto' onClick={handleSaveChanges}>Guardar</button>
                                ) : (
                                    <button className='botonSecondary-proyecto' disabled={true}>Guardar</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </>
    );

}

export default EditarEmpresa;