import React, { useEffect, useState, useRef } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//import para funciones especificas de esta pantalla
import { useLocation } from 'react-router-dom';
import { UpdateProject } from '../../firebase/proyectos.js'
import { getAllCompanies } from '../../firebase/empresas.js'
import { Timestamp } from 'firebase/firestore';


import './nuevo_proyecto.css'
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack } from 'react-icons/io';


function EditarProyecto(props) {

    const navigate = useNavigate();

    // constante para recibir el state desde la pantalla que solicito la edicion
    const location = useLocation();

    const { ProjectsSel } = location.state || {};

    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);
    const [companies, setCompanies] = useState([]);
    const [touchedFields, setTouchedFields] = useState({});

    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    const companyList = await getAllCompanies();
                    setCompanies(companyList);

                    setLoading(false); // para saber si termino de cargar los datos del usuario

                    const canAccess = await hasPermission(res.rol, "proyecto/nuevo");
                    if (!canAccess || !ProjectsSel) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/proyecto/historial');
                        return;
                    }

                    //Cargar datos del proyecto
                    loadDataProject();

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    const [update, setUpdate] = useState(false);
    const [nombreP, setnombreP] = useState('');
    const [empresa, setempresa] = useState('');
    const [empresaID, setempresaID] = useState('');
    const [Monto, setMonto] = useState('');
    const [Entidad, setEntidad] = useState('');
    const [FechaInicio, setFechaInicio] = useState(Timestamp.fromDate(new Date()));
    const [PeriodoTiempo, setPeriodoTiempo] = useState('');

    const montoRef = useRef(null);

    //funcion para cargar datos de la requisicion hacia los estados:
    const loadDataProject = () => {
        setnombreP(ProjectsSel.project_name)
        setempresa(ProjectsSel.project_company)
        setempresaID(ProjectsSel.project_company_id)
        setMonto(ProjectsSel.project_monto ? ProjectsSel.project_monto : 0)
        montoRef.current.value = ProjectsSel.project_monto ? ProjectsSel.project_monto : 0;
        formatInputValue()
        setEntidad(ProjectsSel.project_entidad_contratante ? ProjectsSel.project_entidad_contratante : "")
        setPeriodoTiempo(ProjectsSel.project_periodo_tiempo ? ProjectsSel.project_periodo_tiempo : "")

        if (ProjectsSel.project_fecha_inicio?.seconds !== undefined) {
            // Si nanoseconds es undefined o 0, asignarlo como 0.
            const nanoseconds = ProjectsSel.project_fecha_inicio?.nanoseconds || 0;

            // Crear un objeto Timestamp de Firebase usando los valores 'seconds' y 'nanoseconds'
            const timestamp = new Timestamp(ProjectsSel.project_fecha_inicio?.seconds, nanoseconds);

            setFechaInicio(timestamp);

        }

        setUpdate(false);
    }

    //cambio de color en bordes
    const [montoTouched, setMontoTouched] = useState(false);


    const InputChangeUsr = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'nombreP':
                setnombreP(value);
                break;
            case 'monto':
                // Eliminar caracteres no deseados excepto números y punto decimal
                let cleanedValue = value.replace(/[^0-9.]/g, '');

                // Limitar a dos decimales pero permitir escribir el punto
                const parts = cleanedValue.split('.');
                if (parts.length > 2) {
                    cleanedValue = parts[0] + '.' + parts[1];
                }
                if (parts.length === 2 && parts[1].length > 2) {
                    cleanedValue = parts[0] + '.' + parts[1].substr(0, 2);
                    props.ShowWar('Esta campo solo admite números con hasta dos decimales.');
                }

                const regex = /^\d*\.?\d{0,2}$/;

                if (regex.test(cleanedValue)) {
                    setMonto(cleanedValue);
                    formatInputValue();
                } else {
                    props.ShowWar('No se pueden ingresar más de dos decimales');
                }

                break;
            case 'entidad':
                setEntidad(value);
                break;
            case 'fechainicio':

                const dateParts = value.split('-').map(part => parseInt(part, 10));
                const dateObject = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
                const timestamp = Timestamp.fromDate(dateObject);
                setFechaInicio(timestamp);
                break;
            case 'periodotiempo':
                setPeriodoTiempo(value);
                break;
            default:
                setempresa(value);
                break;
        }
        setTouchedFields(prevState => ({ ...prevState, [name]: true }));
        setUpdate(true);
    };

    //funciones para formato de moneda
    function formatCurrencyLive(value) {

        //solo quitar las comas del value
        value = value.replace(/,/g, '');

        // Verificar si el valor contiene caracteres no permitidos
        const regex = /[^0-9.]/g;
        if (regex.test(value)) {
            props.ShowWar('Esta campo solo admite números con hasta dos decimales');
        }

        // Eliminar caracteres no deseados excepto números y punto decimal
        value = value.replace(/[^0-9.]/g, '');

        // Separar la parte entera y decimal
        let [integerPart, decimalPart] = value.split('.');

        // Limitar la parte decimal a dos dígitos
        if (decimalPart) {
            decimalPart = decimalPart.substring(0, 2);
        }

        // Formatear la parte entera y reconstruir el valor
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    }

    const formatInputValue = () => {
        if (!montoRef.current) return;

        const input = montoRef.current;
        let cursorPosition = input.selectionStart;
        const originalLength = input.value.length;

        input.value = formatCurrencyLive(input.value);

        const newLength = input.value.length;
        cursorPosition = cursorPosition + (newLength - originalLength);

        // Ajustar la posición del cursor en caso de eliminación o adición de comas
        if (input.value[cursorPosition - 1] === ',') {
            cursorPosition--; // Mover el cursor antes de la coma
        }

        input.setSelectionRange(cursorPosition, cursorPosition);
    };

    //Funcion para el formato de la fecha
    function formatDate(timestamp) {
        // Convertir el Timestamp de Firebase a un objeto Date
        const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);

        // Formateo de la fecha
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        return `${year}-${month}-${day}`;
    }

    // useEffect para verificar que los datos necesarios contengan informacion
    useEffect(() => {
        // Comprobar si todos los campos tienen datos
        const allFieldsFilled = nombreP &&
            empresa;

        // Habilitar o deshabilitar el botón basado en si todos los campos están llenos
        setUpdate(allFieldsFilled && update);
    }, [nombreP, empresa]);

    const [isCompanyOpen, setCompanyOpen] = useState(false);
    const handleCompanyButtonClick = () => {
        setCompanyOpen(!isCompanyOpen);
        // Marcar el botón como "tocado" al interactuar con él
        setTouchedFields(prevState => ({ ...prevState, empresa: true }));
    };

    //Seleccion de la compañia
    const handleCompanyChange = (company, event) => {

        event.preventDefault(); // Esto previene la recarga de la página

        setCompanyOpen(false);
        setempresa(company.nombre);
        setempresaID(company.id)
        setUpdate(true);
    };

    // Efecto para manejar el clic fuera del menú
    useEffect(() => {
        function handleDocumentClick(event) {
            // Verificar si el clic es fuera del menú "field-container-proyecto"
            if (isCompanyOpen && !event.target.closest('.field-proyecto')) {
                setCompanyOpen(false);
            }
        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isCompanyOpen]); // Dependencias del efecto



    //enviar a guardar el proyecto
    const handleSaveChanges = async () => {

        try {

            if (!nombreP || !empresa) {
                props.ShowWar('Por favor llena los campos Nombre y Empresa.');

            } else {
                const { success, message, error } = await UpdateProject(ProjectsSel.id, nombreP, empresa, empresaID, Number(Monto), Entidad, FechaInicio, PeriodoTiempo);
                console.log(success);
                if (success) {
                    setnombreP('');
                    setempresa('');
                    setempresaID('');
                    setMonto('');
                    setEntidad('');
                    setFechaInicio(Timestamp.fromDate(new Date()));
                    setPeriodoTiempo('');

                    if (montoRef.current) {
                        montoRef.current.value = '';
                    }

                    props.ShowDone(message);
                    setUpdate(false);

                    navigate("/proyecto/historial")

                } else {
                    props.showErr(error);
                    props.ShowWar(message);
                }
            }


        } catch (error) {
            props.showErr("No es posible crear el proyecto.");
        }
    };

    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' style={{ height: "100vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div style={{ width: "100%" }}>
                            <a className="back-button-requis" onClick={() => navigate("/proyecto/historial")}> <IoIosArrowBack style={{ marginRight: "10px" }} /> Regresar</a>
                            <p style={{ fontWeight: "500", marginTop: "10vh" }}>Editar Proyecto</p>
                            <div className='card-nuevo-proyecto'>
                                <a>Información Básica</a>
                                <form>
                                    <div className='card-nuevo-proyecto-div'>
                                        <div style={{ flex: 1 }}>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>Nombre</label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['nombreP'] ? '#3A93F7' : '' }}
                                                    id='nombreP'
                                                    name='nombreP'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el nombre del nuevo proyecto"
                                                    value={nombreP}
                                                />
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>Monto</label>
                                                <span style={{ marginTop: "-7px" }} className="input-with-q-wrapper">
                                                    <input
                                                        ref={montoRef}
                                                        id='monto'
                                                        name='monto'
                                                        className="input-with-q"
                                                        onChange={InputChangeUsr}
                                                        style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', paddingLeft: "29px", paddingTop: "6px", borderColor: touchedFields['monto'] ? '#3A93F7' : '' }}
                                                        placeholder="Escribe el monto del proyecto"
                                                    />
                                                </span>
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>Fecha de Inicio</label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['fechainicio'] ? '#3A93F7' : '' }}
                                                    className="form-fecha-proyecto"
                                                    type="date"
                                                    id='fechainicio'
                                                    name='fechainicio'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Selecciona la fecha de inicio del proyecto"
                                                    value={formatDate(FechaInicio)}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div className="field-proyecto">
                                                <label style={{ color: 'black' }}>Empresa</label>
                                                <button
                                                    className="boton-desplegable"
                                                    type="button"
                                                    onClick={handleCompanyButtonClick}
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['empresa'] ? '#3A93F7' : '' }}>
                                                    {empresa || "Selecciona el nombre de la empresa"}
                                                    {isCompanyOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                                </button>
                                                {isCompanyOpen && (
                                                    <div className="dropdown-container-proyecto">
                                                        <ul>
                                                            {companies.map((company, index) => (
                                                                <li key={company.id}>
                                                                    <button className="boton-desplegable" type="button" onClick={(e) => handleCompanyChange(company, e)}>
                                                                        {company.nombre}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>Entidad Contratante</label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['entidad'] ? '#3A93F7' : '' }}
                                                    id='entidad'
                                                    name='entidad'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el nombre de la Entidad Contratante"
                                                    value={Entidad}
                                                />
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>Período de Tiempo</label>
                                                <input
                                                    style={{ marginBottom: '40px', background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['periodotiempo'] ? '#3A93F7' : '' }}
                                                    id='periodotiempo'
                                                    name='periodotiempo'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el período de tiempo del proyecto"
                                                    value={PeriodoTiempo}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='button-container-proyecto'>
                                {update ? (
                                    <button className='botonPrincipal-proyecto' onClick={handleSaveChanges}>Guardar</button>
                                ) : (
                                    <button className='botonSecondary-proyecto' disabled={true}>Guardar</button>
                                )}
                            </div>
                        </div>

                    </div>
                </div>

            )}
        </>
    );

}

export default EditarProyecto;