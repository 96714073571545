import React from 'react'
import ReactDOM from 'react-dom/client'

import './components/login/login.css'

import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/routes.jsx';


const testProp = (getVal) => {
  console.log('tambien puedo recibir valores como user: ' + getVal)
}


// Define tu componente App
export default function Main() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <React.Fragment>    {/* sirve para poder agregar dos elementos como por ejemplo dos o mas Button */}
      {/* <App /> */}
      <Main />
    </React.Fragment>
  </React.StrictMode>

)
