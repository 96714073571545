import { db, auth, uploadFile, storage } from './firebase'; // Importamos 'db' que es nuestra referencia a Firestore desde el archivo original de firebase
import { getFirestore, doc, setDoc, getDoc, collection, getDocs, deleteDoc } from 'firebase/firestore'; // Importamos los métodos necesarios de Firestore
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { updateEmail, signInWithEmailAndPassword, signOut, deleteUser as firebaseDeleteUser } from "firebase/auth"; // Importa los métodos necesarios
import { updateDoc } from 'firebase/firestore'; // Importa el método necesario para actualizar documentos en Firestore
import { deleteObject, ref } from "firebase/storage";

import CryptoJS from 'crypto-js';

//import para una segunda instancia solo para registrar usuarios nuevos
import { initializeApp, getApps, getApp } from "firebase/app";

let secondaryApp;

// Verifica si la app secundaria ya existe
const apps = getApps();
const secondary = apps.find(app => app.name === "Secondary");

if (!secondary) {
    // Tu configuración de Firebase aquí
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_APIKEY,
        authDomain: process.env.REACT_APP_AUTHDOMMAIN,
        projectId: process.env.REACT_APP_PROJECTID,
        storageBucket: process.env.REACT_APP_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_APPID,
        measurementId: process.env.REACT_APP_MEASUREMENTID
    };
    secondaryApp = initializeApp(firebaseConfig, "Secondary");
} else {
    secondaryApp = getApp("Secondary");
}

const secondaryAuth = getAuth(secondaryApp);


// Función para cifrar la contraseña
function encryptPassword(password, secretKey) {
    return CryptoJS.AES.encrypt(password, secretKey).toString();
}

// Función para descifrar la contraseña
function decryptPassword(ciphertext, secretKey) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
}

//FUNCION PARA OBTENER LA LISTA DE TODOS LOS USUARIOS REGISTRADOS
export async function getAllUsers() {
    try {
        const userCollection = collection(db, 'users_detail');
        const userSnapshot = await getDocs(userCollection);

        // Verificar si hay documentos
        if (userSnapshot.empty) {
            return [];
        }

        const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Ordenar la lista alfabéticamente por el campo "nombre" sin importar mayúsculas o minúsculas
        userList.sort((a, b) => a.nombre.localeCompare(b.nombre, undefined, { sensitivity: 'base' }));

        return userList;

    } catch (error) {
        console.error("Error al numerar los Usuarios: ", error);
        throw error;  // O puedes manejar el error de alguna otra manera.
    }
}

//FUNCION PARA REGISTRAR UN NUEVO USUARIO
export async function registerUser(User, Pass, userDetails, imgfile) {
    try {

        // Encriptar la contraseña
        const secretKey = process.env.REACT_APP_ECRP;  // Asegúrate de almacenar esta clave en un lugar seguro
        const encryptedPassword = encryptPassword(Pass, secretKey);

        // Crear usuario en la autenticación de Firebase usando la segunda instancia de Auth
        const userCredential = await createUserWithEmailAndPassword(secondaryAuth, User, Pass);
        const user = userCredential.user;

        //SUBIR LA IMAGEN PRIMERO
        const usravatar = await uploadFile(imgfile, user.uid);

        // Usar una variable local para almacenar el nuevo estado
        const newUserState = {
            ...userDetails,
            foto: usravatar,
            ecrp: encryptedPassword
        };

        // Obtener la referencia al documento del usuario dentro de la colección 'users_detail'
        const userRef = doc(db, "users_detail", user.uid);

        // Agregar detalles del usuario en Firestore
        await setDoc(userRef, newUserState);

        // Cerrar la sesión en la segunda instancia de Auth
        secondaryAuth.signOut();

        return { success: true, message: "Usuario creado exitosamente." };

    } catch (error) {
        console.error("Error al registrar el usuario:", error);
        if (error.code === 'auth/too-many-requests') {

            return {
                success: false,
                error: "Acceso a esta cuenta ha sido temporalmente deshabilitado debido a muchos intentos fallidos de inicio de sesión. Puedes restaurarlo inmediatamente al restablecer tu contraseña o puedes intentarlo nuevamente más tarde.",
                message: "Ocurrió un error al registrar el nuevo usuario."
            };
        } else if (error.code === 'auth/email-already-in-use') {
            return {
                success: false,
                error: "El correo electrónico ya está registrado.",
                message: "Ocurrió un error al registrar el nuevo usuario."
            };
        } else if (error.code === 'auth/weak-password') {
            return {
                success: false,
                error: "La contraseña debe tener al menos 6 caracteres.",
                message: "Ocurrió un error al registrar el nuevo usuario."
            };

        } else if (error.code === 'auth/missing-password') {
            return {
                success: false,
                error: "La contraseña debe tener al menos 6 caracteres.",
                message: "Ocurrió un error al registrar el nuevo usuario."
            };

        } else if (error.code === 'auth/invalid-email') {
            return {
                success: false,
                error: "El correo electronico no tiene el formato correcto.",
                message: "Ocurrió un error al registrar el nuevo usuario."
            };

        } else {

            return { success: false, error: error.message, message: "Ocurrió un error al registrar el nuevo usuario." };
        }
    }
}

//FUNCION PARA OBTENER TODOS LOS ROLES
export async function getRoles() {
    try {
        const roleCollection = collection(db, 'role_permissions');
        const roleSnapshot = await getDocs(roleCollection);
        const roleList = roleSnapshot.docs.map(doc => doc.id).filter(role => role !== "admin");
        return roleList;
    } catch (error) {
        console.error("Error al obtener los roles: ", error);
        return [];
    }
}

// FUNCION PARA EDITAR UN USUARIO EXISTENTE
export async function editUser(uid, previousEmail, encryptedPassword, userDetails, imgfile) {
    try {
        // Descifrar la contraseña
        const secretKey = process.env.REACT_APP_ECRP;  // Asegúrate de almacenar esta clave en un lugar seguro
        const decryptedPassword = decryptPassword(encryptedPassword, secretKey);

        // Iniciar sesión con el usuario a editar usando la segunda instancia de Auth
        await signInWithEmailAndPassword(secondaryAuth, previousEmail, decryptedPassword);

        // 1. Subir la nueva imagen si es diferente a null
        if (imgfile) {
            const usravatar = await uploadFile(imgfile, uid);
            userDetails.foto = usravatar;
        }

        // 2. Cambiar el correo electrónico del usuario usando la segunda instancia de Auth
        const user = secondaryAuth.currentUser;
        await updateEmail(user, userDetails.email);

        // 3. Actualizar el resto de campos en "users_detail"
        const userRef = doc(db, "users_detail", uid);
        await updateDoc(userRef, userDetails);

        // 4. Cerrar la sesión en la segunda instancia de Auth
        signOut(secondaryAuth);

        return { success: true, message: "Usuario actualizado exitosamente." };

    } catch (error) {
        console.error("Error al actualizar el usuario:", error);
        if (error.code === 'auth/too-many-requests') {

            return {
                success: false,
                error: "Acceso a esta cuenta ha sido temporalmente deshabilitado debido a muchos intentos fallidos de inicio de sesión. Puedes restaurarlo inmediatamente al restablecer tu contraseña o puedes intentarlo nuevamente más tarde.",
                message: "Ocurrió un error al actualizar el usuario."
            };
        } else if (error.code === 'auth/email-already-in-use') {
            return {
                success: false,
                error: "El correo electrónico ya está registrado.",
                message: "Ocurrió un error al actualizar el usuario."
            };
        } else {

            return { success: false, error: error.message, message: "Ocurrió un error al actualizar el usuario." };
        }
    }
}

//FUNCION PARA ELIMINAR USUARIO
export async function deleteUserAccount(uid, email, encryptedPassword, currentUserId) {
    try {

        // Evitar eliminar el usuario que está actualmente logueado
        if (uid === currentUserId) {
            return { email, success: false, message: "No se puede eliminar el usuario logueado actualmente." }
        }
        // Descifrar la contraseña
        const secretKey = process.env.REACT_APP_ECRP;  // Asegúrate de almacenar esta clave en un lugar seguro
        const decryptedPassword = decryptPassword(encryptedPassword, secretKey);

        // Iniciar sesión con el usuario a eliminar usando la segunda instancia de Auth
        await signInWithEmailAndPassword(secondaryAuth, email, decryptedPassword);

        // 1. Eliminar la imagen del usuario del almacenamiento de Firebase
        const imageRef = ref(storage, '/avatars/' + uid);
        await deleteObject(imageRef);

        // 2. Eliminar la información del usuario de la colección 'users_detail' en Firestore
        const userRef = doc(db, "users_detail", uid);
        await deleteDoc(userRef);

        // 3. Eliminar el usuario de Firebase Authentication
        const user = secondaryAuth.currentUser;
        await firebaseDeleteUser(user);

        // 4. Cerrar la sesión en la segunda instancia de Auth (opcional, dependiendo de tu flujo)
        signOut(secondaryAuth);

        return { success: true, message: "Usuario eliminado exitosamente." };

    } catch (error) {
        console.error("Error al eliminar el usuario:", error);
        return { success: false, error: error.message, message: "Ocurrió un error al eliminar el usuario." };
    }
}

// FUNCION PARA ELIMINAR VARIOS USUARIOS
export async function deleteMultipleUserAccounts(userList, currentUserId) {
    const results = [];

    for (const { id, email, ecrp } of userList) {

        // Evitar eliminar el usuario que está actualmente logueado
        if (id === currentUserId) {
            results.push({ email, success: false, message: "No se puede eliminar el usuario logueado actualmente." });
            continue;
        }

        try {
            // Descifrar la contraseña
            const secretKey = process.env.REACT_APP_ECRP;
            const decryptedPassword = decryptPassword(ecrp, secretKey);

            // Iniciar sesión con el usuario a eliminar usando la segunda instancia de Auth
            await signInWithEmailAndPassword(secondaryAuth, email, decryptedPassword);

            // 1. Eliminar la imagen del usuario del almacenamiento de Firebase
            const imageRef = ref(storage, '/avatars/' + id);
            await deleteObject(imageRef);

            // 2. Eliminar la información del usuario de la colección 'users_detail' en Firestore
            const userRef = doc(db, "users_detail", id);
            await deleteDoc(userRef);

            // 3. Eliminar el usuario de Firebase Authentication
            const user = secondaryAuth.currentUser;
            await firebaseDeleteUser(user);

            // 4. Cerrar la sesión en la segunda instancia de Auth
            signOut(secondaryAuth);

            results.push({ id, success: true, message: "Usuario eliminado exitosamente." });
        } catch (error) {
            console.error(`Error al eliminar el usuario con UID ${id}:`, error);
            results.push({ email, success: false, error: error.message, message: "Ocurrió un error al eliminar el usuario." });
        }
    }

    return results;
}

//FUNCION PARA VER TODOS LOS ROLES Y SUS PERMISOS
export async function fetchRolesAndPermissions() {
    try {
        const rolePermissionsCollection = collection(db, "role_permissions");
        const rolePermissionsSnapshot = await getDocs(rolePermissionsCollection);
        const roles = {};

        rolePermissionsSnapshot.forEach((doc) => {
            roles[doc.id] = doc.data();
        });


        // Ordenar los roles
        const orderedRoles = {};
        const customOrder = ["Gerente", "Administrador"]; // Puedes añadir más roles aquí si lo necesitas

        // Primero, añadir los roles en el orden personalizado
        customOrder.forEach((roleName) => {
            if (roles[roleName]) {
                orderedRoles[roleName] = roles[roleName];
            }
        });

        // Luego, añadir los roles restantes
        Object.keys(roles).forEach((roleName) => {
            if (!customOrder.includes(roleName)) {
                orderedRoles[roleName] = roles[roleName];
            }
        });

        return orderedRoles;

    } catch (error) {
        console.error("Error al obtener los roles y sus permisos: ", error);
    }
}

//FUNCION PARA GUARDAR LOS ROLES MODIFICADOS
export async function saveRolesToFirestore(checkedPermissions, adminRole) {
    try {

        // Iterar sobre cada rol en checkedPermissions
        for (const [roleName, categories] of Object.entries(checkedPermissions)) {
            const roleData = {};

            // Iterar sobre cada categoría de permisos para ese rol
            for (const [category, permissions] of Object.entries(categories)) {
                const activePermissions = [];

                // Iterar sobre cada permiso en esa categoría
                for (const [permissionName, isChecked] of Object.entries(permissions)) {
                    if (isChecked) {
                        // Buscar el objeto de permiso correspondiente en adminRole
                        const adminPermission = adminRole[category].find(perm => perm.name === permissionName);
                        if (adminPermission) {
                            activePermissions.push(adminPermission);
                        }
                    }
                }

                // Solo añadir la categoría si tiene al menos un permiso activo
                if (activePermissions.length > 0) {
                    roleData[category] = activePermissions;
                }
            }

            // Actualizar Firestore con los nuevos permisos para ese rol
            const roleDoc = doc(db, "role_permissions", roleName);
            await setDoc(roleDoc, roleData);
        }
        return { success: true, message: "Permisos actualizados exitosamente." };

    } catch (error) {
        console.error("Error al actualizar los roles y permisos:", error);
        return { success: false, error: error.message, message: "Ocurrió un error al actualizar los Permisos." };
    }
}

//FUNCION PARA CREAR UN NUEVO ROL
export async function createNewRole(roleName) {
    try {
        const roleDoc = doc(db, "role_permissions", roleName);
        const docSnap = await getDoc(roleDoc);

        if (docSnap.exists()) {
            return { success: false, message: "El rol ya existe." };
        }

        await setDoc(roleDoc, {});
        return { success: true, message: "Rol creado exitosamente." };
    } catch (error) {
        console.error("Error al crear el rol:", error);
        return { success: false, message: "Ocurrió un error al crear el rol." };
    }
}
