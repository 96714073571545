import React, { useEffect, useState, useRef } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//import para funciones especificas de esta pantalla
import { getCurrencies, getBanks, addAccount } from '../../firebase/cuentas.js'

import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack } from 'react-icons/io';

import './cuentas.css'

function NuevACuenta(props) {

    const navigate = useNavigate();

    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);
    const [Banks, setBanks] = useState([]);
    const [Currencies, setCurrencies] = useState([]);
    const [touchedFields, setTouchedFields] = useState({});

    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    const currencyList = await getCurrencies();
                    setCurrencies(currencyList);

                    const bankList = await getBanks();
                    setBanks(bankList);

                    setLoading(false); // para saber si termino de cargar los datos del usuario

                    const canAccess = await hasPermission(res.rol, "cuentas/listado");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    const [update, setUpdate] = useState(false);
    const [nombreP, setnombreP] = useState('');
    const [Cuenta, setCuenta] = useState('');
    const [Moneda, setMoneda] = useState('');
    const [Banco, setBanco] = useState('');
    const [TipoCuenta, setTipoCuenta] = useState('');
    const [Saldo, setSaldo] = useState('');

    const montoRef = useRef(null);

    const InputChangeUsr = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'nombreP':
                setnombreP(value);
                break;
            case 'banco':
                setBanco(value);
                break;
            case 'cuenta':
                setCuenta(value);
                break;
            case 'moneda':
                setMoneda(value);
                break;
            case 'saldo':
                // Eliminar caracteres no deseados excepto números y punto decimal
                let cleanedValue = value.replace(/[^0-9.]/g, '');

                // Limitar a dos decimales pero permitir escribir el punto
                const parts = cleanedValue.split('.');
                if (parts.length > 2) {
                    cleanedValue = parts[0] + '.' + parts[1];
                }
                if (parts.length === 2 && parts[1].length > 2) {
                    cleanedValue = parts[0] + '.' + parts[1].substr(0, 2);
                    props.ShowWar('Esta campo solo admite números con hasta dos decimales.');
                }

                const regex = /^\d*\.?\d{0,2}$/;

                if (regex.test(cleanedValue)) {

                    setSaldo(cleanedValue);
                    formatInputValue();
                } else {
                    props.ShowWar('No se pueden ingresar más de dos decimales');
                }

                break;
            default:

                break;
        }
        setTouchedFields(prevState => ({ ...prevState, [name]: true }));
        //setUpdate(true);
    };

    //funcion para formato del saldo
    function formatCurrencyLive(value) {

        //solo quitar las comas del value
        value = value.replace(/,/g, '');

        // Verificar si el valor contiene caracteres no permitidos
        const regex = /[^0-9.]/g;
        if (regex.test(value)) {
            props.ShowWar('Esta campo solo admite números con hasta dos decimales');
        }

        // Eliminar caracteres no deseados excepto números y punto decimal
        value = value.replace(/[^0-9.]/g, '');

        // Separar la parte entera y decimal
        let [integerPart, decimalPart] = value.split('.');

        // Limitar la parte decimal a dos dígitos
        if (decimalPart) {
            decimalPart = decimalPart.substring(0, 2);
        }

        // Formatear la parte entera y reconstruir el valor
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    }

    const formatInputValue = () => {
        if (!montoRef.current) return;

        const input = montoRef.current;
        let cursorPosition = input.selectionStart;
        const originalLength = input.value.length;

        input.value = formatCurrencyLive(input.value);

        const newLength = input.value.length;
        cursorPosition = cursorPosition + (newLength - originalLength);

        // Ajustar la posición del cursor en caso de eliminación o adición de comas
        if (input.value[cursorPosition - 1] === ',') {
            cursorPosition--; // Mover el cursor antes de la coma
        }

        input.setSelectionRange(cursorPosition, cursorPosition);
    };


    // useEffect para verificar que los datos necesarios contengan informacion
    useEffect(() => {
        // Comprobar si todos los campos tienen datos
        const allFieldsFilled = nombreP &&
            Banco &&
            TipoCuenta &&
            Cuenta;

        // Habilitar o deshabilitar el botón basado en si todos los campos están llenos
        setUpdate(allFieldsFilled);
    }, [nombreP, Banco, Cuenta, TipoCuenta]);

    const [isMonedaOpen, setMonedaOpen] = useState(false);
    const [isBancoOpen, setBancoOpen] = useState(false);
    const [isTipoOpen, setTipoOpen] = useState(false);

    // Estado con los elementos quemados
    const [accountType] = useState([
        { name: 'Monetaria' },
        { name: 'Ahorro' },
    ]);

    //para el menu desplegable de moneda
    const handleMonedaButtonClick = () => {
        setMonedaOpen(!isMonedaOpen);
        // Marcar el botón como "tocado" al interactuar con él
        setTouchedFields(prevState => ({ ...prevState, moneda: true }));
    };

    //para el menu desplegable de banco
    const handleBancoButtonClick = () => {
        setBancoOpen(!isBancoOpen);
        // Marcar el botón como "tocado" al interactuar con él
        setTouchedFields(prevState => ({ ...prevState, banco: true }));
    };

    //para el menu desplegable de tipo
    const handleTipoButtonClick = () => {
        setTipoOpen(!isMonedaOpen);
        // Marcar el botón como "tocado" al interactuar con él
        setTouchedFields(prevState => ({ ...prevState, tipo: true }));
    };


    //Seleccion de Moneda
    const handlecurrencyChange = (currency, event) => {

        event.preventDefault(); // Esto previene la recarga de la página

        setMonedaOpen(false);
        setMoneda(currency.abbreviation);
    };

    //Seleccion de Banco
    const handleBancoChange = (bank, event) => {

        event.preventDefault(); // Esto previene la recarga de la página

        setBancoOpen(false);
        setBanco(bank.name);
    };

    //Seleccion de Tipo
    const handleTipoChange = (type, event) => {

        event.preventDefault(); // Esto previene la recarga de la página

        setTipoOpen(false);
        setTipoCuenta(type);
    };

    // Efecto para manejar el clic fuera del menú
    useEffect(() => {
        function handleDocumentClick(event) {
            // Verificar si el clic es fuera del menú "field-moneda"
            if (isMonedaOpen && !event.target.closest('.field-moneda')) {
                setMonedaOpen(false);
            }

            // Verificar si el clic es fuera del menú "field-banco"
            if (isBancoOpen && !event.target.closest('.field-banco')) {
                setBancoOpen(false);
            }

            // Verificar si el clic es fuera del menú "field-tipo"
            if (isTipoOpen && !event.target.closest('.field-tipo')) {
                setTipoOpen(false);
            }
        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isMonedaOpen, isBancoOpen, isTipoOpen]); // Dependencias del efecto


    //enviar a guardar el proyecto
    const handleSaveChanges = async () => {

        try {

            if (!nombreP || !Banco || !Cuenta || !TipoCuenta) {
                props.ShowWar('Por favor llena los campos minimos necesarios "*"');

            } else {
                if (nombreP.trim().length < 1) {
                    props.ShowWar('Debes indicar un "Nombre" valido.');
                    return;
                }

                //Creando la estructura para la cuenta 
                const newAccount = {
                    name: nombreP.trim(),
                    account_number: Cuenta.trim(),
                    currency: Moneda.trim(),
                    bank: Banco.trim(),
                    type: TipoCuenta.trim(),
                    balance: Saldo.trim(),
                };

                const { success, message, error } = await addAccount(newAccount);
                if (success) {
                    setnombreP('');
                    setBanco('');
                    setCuenta('');
                    setMoneda('');
                    setBanco('');
                    setTipoCuenta('');
                    setSaldo('');


                    props.ShowDone(message);
                    setUpdate(false);

                    navigate("/cuentas/listado")

                } else {
                    props.showErr(error);
                    props.ShowWar(message);
                }
            }


        } catch (error) {
            props.showErr("No es posible crear la cuenta.");
        }
    };

    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' style={{ height: "210vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div style={{ width: "100%" }}>
                            <a className="back-button-requis" onClick={() => navigate("/cuentas/listado")}> <IoIosArrowBack style={{ marginRight: "10px" }} /> Regresar</a>
                            <p style={{ fontWeight: "500", marginTop: "10vh" }}>Nueva Cuenta Bancaria</p>
                            <div className='card-proveedores'>
                                <a style={{ marginBottom: "2.5%", fontSize: "large" }}>Información Básica</a>
                                <form>
                                    <div className='card-nuevo-proyecto-div'>
                                        <div style={{ flex: 1 }}>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>
                                                    Nombre
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['nombreP'] ? '#3A93F7' : '' }}
                                                    id='nombreP'
                                                    name='nombreP'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el nombre de la cuenta bancaria"
                                                    value={nombreP}
                                                />
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>
                                                    Número de la cuenta
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['cuenta'] ? '#3A93F7' : '' }}
                                                    id='cuenta'
                                                    name='cuenta'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el número de la cuenta"
                                                    value={Cuenta}
                                                />
                                            </div>
                                            <div className="field-moneda">
                                                <label style={{ color: 'black', marginBottom: "1%" }}>Moneda</label>
                                                <button
                                                    className="boton-desplegable"
                                                    type="button"
                                                    onClick={handleMonedaButtonClick}
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', textAlign: "left", borderColor: touchedFields['moneda'] ? '#3A93F7' : '' }}>
                                                    {Moneda || "Selecciona la moneda"}
                                                    {isMonedaOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                                </button>

                                                {isMonedaOpen && (
                                                    <div style={{ overflowY: 'auto', height: "15%", width: "25%" }} className="dropdown-container-proyecto">
                                                        <ul>
                                                            {Currencies.map((currency, index) => (
                                                                <li key={index}>
                                                                    <button className="boton-desplegable" type="button" onClick={(e) => handlecurrencyChange(currency, e)}>
                                                                        {currency.name} - {currency.abbreviation}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div className="field-banco">
                                                <label style={{ color: 'black', marginBottom: "1%" }}>
                                                    Banco
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <button
                                                    className="boton-desplegable"
                                                    type="button"
                                                    onClick={handleBancoButtonClick}
                                                    style={{ marginBottom: "2%", background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', textAlign: "left", borderColor: touchedFields['banco'] ? '#3A93F7' : '' }}>
                                                    {Banco || "Selecciona la institución bancaria"}
                                                    {isBancoOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                                </button>

                                                {isBancoOpen && (
                                                    <div className="dropdown-container-proyecto">
                                                        <ul>
                                                            {Banks.map((bank, index) => (
                                                                <li key={index}>
                                                                    <button className="boton-desplegable" type="button" onClick={(e) => handleBancoChange(bank, e)}>
                                                                        {bank.name}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="field-tipo">
                                                <label style={{ color: 'black', marginBottom: "1%" }}>
                                                    Tipo de Cuenta
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <button
                                                    className="boton-desplegable"
                                                    type="button"
                                                    onClick={handleTipoButtonClick}
                                                    style={{ marginBottom: "2%", background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', textAlign: "left", borderColor: touchedFields['tipo'] ? '#3A93F7' : '' }}>
                                                    {TipoCuenta || "Selecciona el tipo de cuenta"}
                                                    {isTipoOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                                </button>

                                                {isTipoOpen && (
                                                    <div className="dropdown-container-regimen">
                                                        <ul>
                                                            {accountType.map((account, index) => (
                                                                <li key={index}>
                                                                    <button className="boton-desplegable" type="button" onClick={(e) => handleTipoChange(account.name, e)}>
                                                                        {account.name}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>Saldo</label>
                                                <input
                                                    style={{ marginBottom: "45px", width: "95%", background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['saldo'] ? '#3A93F7' : '' }}
                                                    id='saldo'
                                                    name='saldo'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el saldo"
                                                    ref={montoRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='button-container-proyecto'>
                                {update ? (
                                    <button className='botonPrincipal-proyecto' onClick={handleSaveChanges}>Crear</button>
                                ) : (
                                    <button className='botonSecondary-proyecto' disabled={true}>Crear</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </>
    );

}

export default NuevACuenta;