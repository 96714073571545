import React, { useState } from "react";
import "./Modal-Eliminar-Requi.css";

export default function ModalEliminarRequi({ handleClose, showErr, ShowDone, ShowWar, close, onDelete, requi, voucher }) {

    let displayText;

    if (requi != null) {
        if (requi) {
            displayText = `¿Estás seguro que quieres eliminar la Requisición # ${requi.correlativo}?`;
        } else {
            displayText = "No hay requisiciones seleccionadas para ser eliminadas.";
        }
    }

    if (voucher != null) {
        if (voucher) {
            displayText = `¿Estás seguro que quieres eliminar el Voucher # ${voucher.correlativo}?`;
        } else {
            displayText = "No hay vouchers seleccionados para ser eliminadas.";
        }
    }



    return (
        <>
            <div className="modal">
                <div className="overlay"></div>
                <div className="modal-content-eliminar">
                    <p>{displayText}</p>
                    <button className="close-modal" onClick={handleClose}>X</button>
                    <div className="modal-footer">
                        <button className="confirmation-button" onClick={onDelete}>Aceptar</button>
                        <button className="close-button" onClick={handleClose}>Cancelar</button>
                    </div>
                </div>
            </div>
        </>
    );
}
