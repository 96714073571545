import React, { useState, useEffect } from 'react';

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

import './user.css';

function User() {

    const navigate = useNavigate();

    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);


    //para validar si esta iniciada la sesion
    useEffect(() => {
        onAuthStateChanged(auth, handleUserStateChanged);
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    setLoading(false); // <-- Añadir esto


                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
            }

        } else {
            navigate('/login');
        }
    }


    return (
        <>
            {!loading && (
                <div >

                    < HeaderHome
                        userLoged={useUser}
                    />

                    <div className='bodyHome-user' style={{ height: "135vh" }}>
                        < Menu
                            userLoged={useUser}
                        />

                        <form onSubmit={() => navigate('/editusr')} className='form-user'>
                            <div>
                                <p>Mi Perfil</p>
                            </div>
                            <div className='userContainer-body'>
                                <div className='userContainer'>
                                    <div className='column1'>
                                        <img src={useUser.foto} alt='ProfilePicture' />
                                        <h3>{useUser.nombre}</h3>
                                        <span>{useUser.rol}</span>
                                    </div>
                                    <div className='column2'>
                                        <label>Género</label>
                                        <input className='columns-input' id='genero' name='genero' placeholder={useUser.genero} disabled />
                                        <label>Correo Electrónico</label>
                                        <input className='columns-input' id='email' name='email' placeholder={useUser.email} disabled />
                                        <label>Fecha de inicio en Codegua</label>
                                        <input className='span-columns-input' id='dateIni' name='dateIni' placeholder={useUser.fechaIni} disabled />
                                    </div>
                                    <div className='column3'>
                                        <label>Fecha de Cumpleaños</label>
                                        <input className='columns-input' id='cumple' name='cumple' placeholder={useUser.cumple} disabled />
                                        <label>Número de teléfono</label>
                                        <input className='columns-input' id='tel' name='tel' placeholder={useUser.tel} disabled />
                                    </div>
                                </div>

                            </div>
                            <div className='botonPrincipal-user-container'>
                                <button type='submit' className='botonPrincipal-user'>Editar</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default User;