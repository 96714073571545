import React from 'react'
import { useState, useEffect } from 'react'

import ImagenInicio from '../../assets/images/ImagenInicio.png'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { MdError, MdOutlineArrowBackIosNew } from 'react-icons/md';

import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth' //Esto es para registrar usuarios
//import de mi clase para conectarme a Firebase
import { auth, updateLastLogin } from '../../firebase/firebase';

//para rutas
import { useNavigate } from 'react-router-dom';

function Login(props) {

    const [User, setUser] = useState("")
    const [Pass, setPass] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [showforget, setShowforget] = useState(false);
    const [UsuarioL, setUsuarioL] = useState();

    const navigate = useNavigate();


    // METODO PARA CERRAR SESION
    const logOut = async () => {
        await signOut(auth);
    }

    // METODO PARA REGISTRAR USUARIOS
    const register = async (e) => {
        try {
            e.preventDefault();

            const usr = await createUserWithEmailAndPassword(auth, User, Pass);

        } catch (error) {
            console.log(error.message);
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        console.log('user: ' + User)
        console.log('pass: ' + Pass)
        //test para recibir la funcion
        //props.gtFunction(User);
    }


    useEffect(() => {
        onAuthStateChanged(auth, handleUserStateChanged);
    }, []);

    async function handleUserStateChanged(UsuarioL) {
        if (UsuarioL) {

            await updateLastLogin(UsuarioL.uid);
            navigate('/home');

        } else {
            navigate('/login');
        }
    }

    //aqui valida si puede o no iniciar sesion
    const [errorMessage, setErrorMessage] = useState("");

    const loginvalidate = async (e) => {
        try {
            e.preventDefault();

            const usr = await signInWithEmailAndPassword(auth, User, Pass);
            setUsuarioL(usr);

            props.showMsj('Sesion Iniciada 🚜');
            setErrorMessage("");

        } catch (error) {

            console.log(error.message);
            let errorMessage;
            console.log(error.code)

            switch (error.code) {
                case 'auth/invalid-email':
                    errorMessage = 'El correo electrónico no es válido.';
                    break;
                case 'auth/user-disabled':
                    errorMessage = 'La cuenta de usuario ha sido deshabilitada.';
                    break;
                case 'auth/missing-password':
                    errorMessage = 'La contraseña no es valida.';
                    break;
                case 'auth/user-not-found':
                    errorMessage = 'No existe una cuenta de usuario correspondiente a este correo electrónico.';
                    break;
                case 'auth/wrong-password':
                    errorMessage = 'La contraseña es incorrecta. Vuelve a intentarlo o haz clic en “¿Olvidaste la contraseña?” para restablecerla.';
                    break;
                case 'auth/too-many-requests':
                    errorMessage = 'Has hecho demasiados intentos de inicio de sesión seguidos. Por favor, espera unos minutos antes de intentarlo de nuevo.';
                    break;
                case 'auth/invalid-login-credentials':
                    errorMessage = 'Lo sentimos, su correo o contraseña son incorrectos. Por favor, vuelva a comprobar sus datos.';
                    break;
                default:
                    errorMessage = 'Ocurrió un error desconocido.';
            }

            setErrorMessage(errorMessage);

        }
    }

    const HandleinputChange = (e) => {
        const { name, value } = e.target;
        name === 'User' ? setUser(value) : setPass(value);
    }


    const forgetPass = async (e) => {
        try {
            e.preventDefault();
            if (User === '') {
                props.ShowWar('Por favor ingrese un usuario');
            } else {
                sendPasswordResetEmail(auth, User)
                props.showMsj('Se envió un correo electrónico 📧');
                setUser('');
            }

        } catch (error) {
            console.log(error)
            props.showErr(error);
        };
    }

    return (
        <>
            <div className='inicioContainer'>
                <div className='headerInicio'>
                    <h2>Codeguanet</h2>
                </div>
                <div className='bodyInicio'>
                    <div>
                        <img src={ImagenInicio} alt='imagenInicio' />
                    </div>
                    <div className='containerFlip'>
                        <div className={`loginContainer ${showforget ? 'showBack' : 'showFront'}`}>
                            <h1>¡Bienvenido!</h1>
                            <p>Llena los siguientes datos para ingresar a tu cuenta</p>
                            <form onSubmit={loginvalidate} className='formlogin'>
                                <label>Correo Electrónico</label>
                                <input type='email' id='correo' name='User' onChange={HandleinputChange} placeholder='ejemplo@mail.com' />
                                <label>Contraseña</label>
                                <div className='inputIcon'>
                                    <input type={showPassword ? "text" : "password"} id='pass' name='Pass' onChange={HandleinputChange} placeholder='Ingresa tu contraseña' />
                                    {showPassword ? (
                                        <AiOutlineEyeInvisible className='eyeIcon' onClick={() => setShowPassword(!showPassword)} />
                                    ) : (
                                        <AiOutlineEye className='eyeIcon' onClick={() => setShowPassword(!showPassword)} />
                                    )}
                                </div>
                                {errorMessage && <h2><MdError className='mdError' /> {errorMessage}</h2>}
                                <p onClick={() => setShowforget(!showforget)} >¿Olvidaste la contraseña? </p>
                                <button type='submit' className='botonPrincipal-login'>Ingresar</button>
                            </form>
                        </div>
                        <div className={`loginContainerForget ${showforget ? 'showFront' : 'showBack'}`}>
                            <h2 onClick={() => setShowforget(!showforget)} >
                                <MdOutlineArrowBackIosNew className='arrowBackIcon' />Regresar a log in
                            </h2>
                            <h1>¿Olvidaste la contraseña?</h1>
                            <p>No te preocupes, te mandaremos un mensaje que te ayudará a recuperar tu contraseña</p>
                            <form onSubmit={forgetPass} className='formlogin'>
                                <label>Correo Electrónico</label>
                                <input type='email' id='correo' name='User' onChange={HandleinputChange} placeholder='ejemplo@mail.com' value={User} />
                                <button type='submit' className='botonPrincipal-login'>Recuperar contraseña</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;