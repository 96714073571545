import { db, storage } from './firebase'; // Importamos 'db' que es nuestra referencia a Firestore desde el archivo original de firebase
import { collection, getDocs, addDoc, query, where, doc, getDoc, runTransaction, setDoc, writeBatch, updateDoc, orderBy, deleteDoc } from 'firebase/firestore'; // Importamos los métodos necesarios de Firestore
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';


//funcion para ver el coorelativo de Voucher
export async function getNextVoucherNumber() {
    try {
        const counterRef = doc(db, 'counters', 'vouchers');
        const counterDoc = await getDoc(counterRef);

        if (!counterDoc.exists()) {
            throw new Error("El documento de contador no existe.");
        }

        const currentCount = counterDoc.data().count;
        return currentCount + 1;  // Devuelve el número siguiente

    } catch (error) {
        console.error("Error al obtener el número de vouchers: ", error);
        throw error; // Para que cualquier llamada a la función pueda manejar el error también
    }
}

// Función para obtener e incrementar el correlativo de voucher
export async function incrementVoucherCounter() {
    const counterRef = doc(db, 'counters', 'vouchers');

    try {
        const newCount = await runTransaction(db, async (transaction) => {
            const counterDoc = await transaction.get(counterRef);

            if (!counterDoc.exists()) {
                throw Error("El documento de contador no existe.");
            }

            const currentCount = counterDoc.data().count;
            const newCount = currentCount + 1;

            transaction.update(counterRef, { count: newCount });

            return newCount;
        });

        return newCount;

    } catch (error) {
        console.error("Error al incrementar el contador de vouchers: ", error);
        throw error;
    }
}

// FUNCION PARA OBTENER LOS DETALLES DE LAS CUENTAS
export async function getAccountsDetail() {
    try {
        const accountsCollection = collection(db, 'accounts_detail');
        const accountsSnapshot = await getDocs(accountsCollection);

        // Verificar si hay documentos
        if (accountsSnapshot.empty) {
            return [];
        }

        const accountsList = accountsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        // Ordenar la lista alfabéticamente por el campo "name" sin importar mayúsculas o minúsculas
        accountsList.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

        return accountsList;

    } catch (error) {
        console.error("Error al obtener los detalles de las cuentas: ", error);
        return [];
    }
}

//FUNCION PARA AGREGAR UN NUEVO VOUCHER
export async function addVoucher(VoucherData) {
    try {

        // Paso 0: Obtener el correlativo y asignarlo a la requisición
        const nextCorrelativo = await incrementVoucherCounter();
        VoucherData.correlativo = nextCorrelativo;

        // Paso 1: Agregar el documento del voucher
        const newVoucherRef = await addDoc(collection(db, 'vouchers'), VoucherData);

        // Si la inserción fue exitosa, puedes devolver información sobre el éxito y el ID del nuevo documento
        return {
            success: true,
            message: "Voucher almacenado exitosamente.",
            id: newVoucherRef.id,
        };
    } catch (error) {
        console.error("Error al agregar el voucher: ", error);
        // En caso de error, devuelve información sobre el fracaso
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al almacenar el Voucher."
        };
    }
}

// FUNCION PARA OBTENER TODOS LOS VOUCHERS
export async function getAllVouchers() {
    try {
        const vouchersCollection = collection(db, 'vouchers');
        // Primero ordenamos por estado para cumplir con las reglas de Firestore
        // Luego ordenamos por correlativo
        const q = query(
            vouchersCollection,
            where("estado", "!=", "Eliminado"),
        );
        const vouchersSnapshot = await getDocs(q);
        const vouchersList = vouchersSnapshot.docs.map(voucherDoc => ({
            id: voucherDoc.id,
            ...voucherDoc.data()
        }));


        // Ordenar por 'numero de cheque' en la aplicación
        vouchersList.sort((a, b) => {
            const aNumber = a.check?.number ?? 0;
            const bNumber = b.check?.number ?? 0;
            return bNumber - aNumber;
        });

        return vouchersList;
    } catch (error) {
        console.error("Error al obtener todos los vouchers: ", error);
        return [];
    }
}

// FUNCIÓN PARA ACTUALIZAR EL ESTADO DE UNO O MÁS VOUCHERS A "Eliminado"
export async function updateVouchersToDeleted(vouchers) {
    const batch = writeBatch(db);

    try {
        // Extrae los IDs de los objetos de voucher
        const idsToUpdate = Array.isArray(vouchers) ? vouchers.map(voucher => voucher.id) : [vouchers.id];

        // Itera sobre cada ID y prepara la actualización en lote
        idsToUpdate.forEach(voucherId => {
            const voucherRef = doc(db, 'vouchers', voucherId);
            batch.update(voucherRef, { estado: 'Eliminado' });
        });

        // Realiza la actualización en lote
        await batch.commit();

        if (Array.isArray(vouchers)) {

            if (vouchers.length == 1) {
                return {
                    success: true,
                    message: `Voucher # ${vouchers[0].correlativo} eliminado con éxito.`,
                    id: idsToUpdate, // Retornar los IDs de las requisiciones eliminadas
                };
            } else {
                return {
                    success: true,
                    message: `${vouchers.length} vouchers han sido eliminados.`,
                    id: idsToUpdate, // Retornar los IDs de las requisiciones eliminadas
                };
            }

        } else {
            return {
                success: true,
                message: `Voucher # ${vouchers.correlativo} eliminado con éxito.`,
                id: vouchers.id, // Retornar el ID de la requisición eliminada
            };
        }

    } catch (error) {
        console.error("Error al eliminar el voucher: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al eliminar el voucher."
        };
    }
}


// FUNCIÓN PARA ACTUALIZAR EL ESTADO DE UN VOUCHER A "ENTREGADO"
export async function updateVouchersToDelivered(vouchers) {
    const batch = writeBatch(db);

    try {
        // Extrae los IDs de los objetos de voucher
        const idsToUpdate = Array.isArray(vouchers) ? vouchers.map(voucher => voucher.id) : [vouchers.id];

        // Itera sobre cada ID y prepara la actualización en lote
        idsToUpdate.forEach(voucherId => {
            const voucherRef = doc(db, 'vouchers', voucherId);
            batch.update(voucherRef, { estado: 'Entregado' });
        });

        // Realiza la actualización en lote
        await batch.commit();

        if (Array.isArray(vouchers)) {

            if (vouchers.length == 1) {
                return {
                    success: true,
                    message: `Voucher # ${vouchers[0].correlativo} marcado como entregado.`,
                    id: idsToUpdate, // Retornar los IDs de las requisiciones eliminadas
                };
            } else {
                return {
                    success: true,
                    message: `${vouchers.length} vouchers han sido marcados como entregados.`,
                    id: idsToUpdate, // Retornar los IDs de las requisiciones eliminadas
                };
            }

        } else {
            return {
                success: true,
                message: `Voucher # ${vouchers.correlativo} marcado como entregado.`,
                id: vouchers.id, // Retornar el ID de la requisición eliminada
            };
        }

    } catch (error) {
        console.error("Error al marcado como entregado el voucher: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al marcado como entregado el voucher."
        };
    }
}

//FUNCION PARA OBTENER LOS DATOS DE UN VOUCHER CON REQUISICION ASOCIADA
export async function getVoucherByRequisition(correlativo) {
    try {
        const vouchersCollection = collection(db, 'vouchers');

        // Crear la consulta
        const q = query(
            vouchersCollection,
            where("voucher_requisition", "==", true),
            where("project.requisition", "==", correlativo),
        );

        // Ejecutar la consulta
        const snapshot = await getDocs(q);

        // Iterar sobre los documentos para encontrar el primer "Pendiente" o "Entregado"
        for (const doc of snapshot.docs) {
            const voucher = doc.data();
            if (voucher.estado === "Pendiente" || voucher.estado === "Entregado") {
                return { id: doc.id, ...voucher };
            }
        }

        // Si no se encuentra ninguno, retornar null
        return null;
    } catch (error) {
        console.error("Error al obtener el voucher: ", error);
        return null; // o false
    }
}

// FUNCION PARA EDITAR UN VOUCHER EXISTENTE
export async function editVoucher(voucherId, updatedVoucherData) {
    try {
        // Paso 1: Obtener una referencia al documento del voucher existente
        const voucherDocRef = doc(db, 'vouchers', voucherId);

        // Paso 2: Actualizar el documento del voucher
        await updateDoc(voucherDocRef, updatedVoucherData);

        // Si la actualización fue exitosa, puedes devolver información sobre el éxito
        return {
            success: true,
            message: `Voucher # ${updatedVoucherData.correlativo} actualizado exitosamente.`
        };
    } catch (error) {
        console.error("Error al actualizar el voucher: ", error);
        // En caso de error, devuelve información sobre el fracaso
        return {
            success: false,
            error: error,
            message: `Ocurrió un error al actualizar el Voucher # ${updatedVoucherData.correlativo} `
        };
    }
}
