import React, { useEffect, useState, useRef, useMemo } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//modal de carga
import LoadingModal from '../Loading-Modal/loading.jsx'


//import para funciones especificas de esta pantalla
import { getRequisitions_approved, updateRequisitionsToDeleted, getRequisitionWithDetailsById, getProjects, getProviders } from '../../firebase/requisiciones'
import * as XLSX from 'xlsx';
import { getVoucherByRequisition } from '../../firebase/vouchers'

import ReactDatePicker from 'react-datepicker';


//import de iconos
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { CiMenuKebab } from 'react-icons/ci';
import { FaSearch } from "react-icons/fa";
import { FiTrash2, FiDownload, FiPrinter } from 'react-icons/fi';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';


//modal para eliminar
import ModalEliminarRequi from '../Modal-Eliminar-Requi/Modal-Eliminar-Requi.jsx'
import ModalMotivoEliminacion from '../Modal-Eliminar-Requi/Modal-Motivo-Eliminacion.jsx'

import './requisicion_solicitadas.css'
import './historial_requisiciones.css'

function FechaSelector({ fechaInicio, setFechaInicio, fechaFin, setFechaFin, onChange, onClose }) {

    const setLastWeek = () => {
        const today = new Date();
        const startLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7);
        const endLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 1);
        setFechaInicio(startLastWeek);
        setFechaFin(endLastWeek);
        onChange(startLastWeek, endLastWeek);
    };

    const setToday = () => {
        const today = new Date();
        setFechaInicio(today);
        setFechaFin(today);
        onChange(today, today);
    };

    const setCurrentMonth = () => {
        const today = new Date();
        setFechaInicio(new Date(today.getFullYear(), today.getMonth(), 1));
        setFechaFin(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        onChange(new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0));
    };

    const setPreviousMonth = () => {
        const today = new Date();
        setFechaInicio(new Date(today.getFullYear(), today.getMonth() - 1, 1));
        setFechaFin(new Date(today.getFullYear(), today.getMonth(), 0));
        onChange(new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0));
    };

    const ref = useRef();

    // Llamar a onChange cuando se actualizan las fechas
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose();
            }
        }

        // Agregar listener al montar
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Eliminar listener al desmontar
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div ref={ref} className="selector-fecha">
            <div className="custom-buttons-date">
                <label style={{ marginRight: "5px", marginLeft: "10px" }}>Inicio:</label>
                <ReactDatePicker
                    selected={fechaInicio}
                    onChange={date => {
                        setFechaInicio(date);
                        if (fechaFin) { // Si ya se ha seleccionado la fecha de fin
                            onChange(date, fechaFin);
                        }
                    }}

                    selectsStart
                    startDate={fechaInicio}
                    endDate={fechaFin}
                    dateFormat="dd/MM/yyyy"
                />
                <label style={{ marginRight: "5px", marginLeft: "10px" }}>Fin:</label>
                <ReactDatePicker
                    selected={fechaFin}
                    onChange={date => {
                        setFechaFin(date);
                        onChange(fechaInicio, date); // Usando la prop onChange
                        onClose();
                    }}

                    selectsEnd
                    startDate={fechaInicio}
                    endDate={fechaFin}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div className="custom-buttons-fechas">
                <button type="button" onClick={setToday}>Hoy</button>
                <button type="button" onClick={setLastWeek}>Semana Pasada</button>
                <button type="button" onClick={setCurrentMonth}>Mes Actual</button>
                <button type="button" onClick={setPreviousMonth}>Mes Pasado</button>
            </div>

        </div>

    );
}


function Historial(props) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar el modal de carga
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedProveedorId, setSelectedProveedorId] = useState(null);


    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);

    //Estado para almacenar las requisiciones a mostrar
    const [requisiciones, setRequisiciones] = useState([]);
    const [proyectos, setProyectos] = useState([]);
    const [proveedores, setProveedores] = useState();

    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                setIsLoading(true);
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //Cargar datos para usar en la pantalla al cargar
                    setRequisiciones(await getRequisitions_approved());

                    setLoading(false); // para saber si termino de cargar los datos del usuario
                    setIsLoading(false);

                    const canAccess = await hasPermission(res.rol, "requisicion/historial");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }
                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                    setIsLoading(false);
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    const [dropdownOpen, setDropdownOpen] = useState({});

    //AQUI ESTA EL PAGINADOR
    const [currentPage, setCurrentPage] = useState(() => {
        const savedPage = localStorage.getItem('currentPage');
        return savedPage ? Number(savedPage) : 1;
    });

    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // Calcula las requisiciones actuales para la página actual
    const totalItems = requisiciones.length;
    //const totalPages = Math.ceil(totalItems / itemsPerPage);
    const [totalPages, setTotalPages] = useState(1);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        localStorage.setItem('currentPage', pageNumber);
    };

    //seccion para filtros

    const [searchRequi, setSearchRequi] = useState('');
    const [searchProject, setSearchProject] = useState('');
    const [searchProveedor, setSearchProveedor] = useState('');
    const [searchFecha, setSearchFecha] = useState('');

    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);

    const [isProjectOpen, setProjectOpen] = useState(false);
    const [isProveedorOpen, setProveedorOpen] = useState(false);
    const [mostrarSelectorFechas, setMostrarSelectorFechas] = useState(false);


    const InputChangeReq = (event) => {
        event.preventDefault();

        const { name, value } = event.target;
        if (name === 'requisition') {
            if (/^\d*$/.test(value)) {
                setSearchRequi(value);
                //local storage
                saveFiltersToLocalStorage({
                    searchRequi: value,
                    searchFecha,
                    searchProject,
                    searchProveedor,
                    fechaInicio,
                    fechaFin
                });
                setCurrentPage(1);

            } else {
                props.ShowWar('Esta campo solo admite números')
            }
        } else if (name === 'fecha') {
            setSearchFecha(value);
        } else if (name === 'proyecto') {
            setSearchProject(value);
        }
        if (value) {
            document.getElementById(name).classList.add('input-active');
        } else {
            document.getElementById(name).classList.remove('input-active');
        }
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador
    };

    const [fechaActiva, setFechaActiva] = useState(false);
    const mostrarSelector = () => {
        setFechaActiva(true);
        setMostrarSelectorFechas(true);
    };

    const cerrarSelectorFecha = () => {
        setMostrarSelectorFechas(false); // Asume que 'setMostrarSelectorFechas' es tu función para controlar la visibilidad
    };

    const manejadorCambioFecha = (inicio, fin) => {
        const formatoFecha = fecha => fecha.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const fechaFormateada = `${formatoFecha(inicio)} - ${formatoFecha(fin)}`;

        setSearchFecha(`${formatoFecha(inicio)} - ${formatoFecha(fin)}`);
        setFechaInicio(inicio);
        setFechaFin(fin);

        //local storage
        saveFiltersToLocalStorage({
            searchRequi,
            searchFecha: fechaFormateada,
            searchProject,
            searchProveedor,
            fechaInicio: inicio,
            fechaFin: fin
        });
        setCurrentPage(1);
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador

    };

    const filteredRequis = useMemo(() => requisiciones.filter(requi => {
        const fechaRequisicion = new Date(requi.fecha.seconds * 1000);
        const fechaRequisicionInicio = new Date(fechaRequisicion.setHours(0, 0, 0, 0));
        const fechaRequisicionFin = new Date(fechaRequisicion.setHours(23, 59, 59, 999));

        const startOfDay = fechaInicio ? new Date(fechaInicio.setHours(0, 0, 0, 0)) : null;
        const endOfDay = fechaFin ? new Date(fechaFin.setHours(23, 59, 59, 999)) : null;

        const enRangoDeFechas = (!startOfDay || !endOfDay) || (fechaRequisicionFin >= startOfDay && fechaRequisicionInicio <= endOfDay);

        return (
            String(requi.correlativo).includes(String(searchRequi)) &&
            enRangoDeFechas &&
            (searchProject === '' || requi.proyecto.nombre === searchProject) &&
            (searchProveedor === '' || requi.proveedor.nombre === searchProveedor)
        );
    }), [requisiciones, searchRequi, searchProject, searchProveedor, fechaInicio, fechaFin]);


    useEffect(() => {
        const filteredProjects = [];
        const filteredProviders = [];

        filteredRequis.forEach(requi => {
            if (requi.proyecto && !filteredProjects.some(proj => proj.project_name === requi.proyecto.nombre)) {
                filteredProjects.push({
                    id: requi.proyecto.id,
                    project_name: requi.proyecto.nombre,
                });
            }
            if (requi.proveedor && !filteredProviders.some(prov => prov.name === requi.proveedor.nombre)) {
                filteredProviders.push({
                    id: requi.proveedor.id,
                    name: requi.proveedor.nombre
                });
            }
        });

        // Ordenar alfabeticamente
        filteredProjects.sort((a, b) => a.project_name.localeCompare(b.project_name));
        filteredProviders.sort((a, b) => a.name.localeCompare(b.name));

        setProyectos(filteredProjects);
        setProveedores(filteredProviders);

    }, [filteredRequis]);


    useEffect(() => {
        setTotalPages(Math.ceil(filteredRequis.length / itemsPerPage) || 1);
    }, [filteredRequis, itemsPerPage]);

    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [executionCount, setExecutionCount] = useState(0);

    //Restaurar los Filtros desde localStorage al Cargar la Página
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('filters'));
        if (savedFilters) {
            setSearchRequi(savedFilters.searchRequi || '');
            setSearchFecha(savedFilters.searchFecha || '');
            setSearchProject(savedFilters.searchProject || '');
            setSearchProveedor(savedFilters.searchProveedor || '');
            setFechaInicio(savedFilters.fechaInicio ? new Date(savedFilters.fechaInicio) : null);
            setFechaFin(savedFilters.fechaFin ? new Date(savedFilters.fechaFin) : null);
        }

        const savedPage = localStorage.getItem('currentPage');

        if (savedPage) {
            setCurrentPage(Number(savedPage));
        }


        setIsFirstLoad(false);
        //localStorage.setItem('lastURL', 'requisicion/historial');
    }, []);

    useEffect(() => {
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador

        const lastURL = localStorage.getItem('lastURL');
        if ((executionCount >= 3) || (lastURL != 'requisicion/historial')) {
            setCurrentPage(1);
            localStorage.setItem('currentPage', 1);
            localStorage.setItem('lastURL', 'requisicion/historial');
        }

    }, [searchRequi, searchFecha, searchProject, searchProveedor, fechaInicio, fechaFin]);



    //SECCION PARA MANEJAR HISTORIAL EN FILTROS POR LOCALSTORAGE

    //Guardar los Filtros en localStorage
    const saveFiltersToLocalStorage = (filters) => {
        localStorage.setItem('filters', JSON.stringify(filters));
    };

    const currentRequisiciones = filteredRequis.slice(indexOfFirstItem, indexOfLastItem);

    // Calcular el total de elementos filtrados
    const totalElementosFiltrados = filteredRequis.length;

    // Calcular la suma total en Quetzales
    const sumaTotalQuetzales = filteredRequis.reduce((total, requi) => {
        return total + requi.total;
    }, 0);

    //funcion para actualizar el estado SearchProject
    const handleProjectChange = (event, projectId) => {
        event.preventDefault();
        event.stopPropagation();

        let projectName = '';

        if (projectId === null) {
            setSearchProject('');
        } else {
            const selectedProject = proyectos.find(p => p.id === projectId);
            if (selectedProject) {
                setSearchProject(selectedProject.project_name);
                projectName = selectedProject.project_name;
            }
        }
        setSelectedProjectId(projectId);
        setProjectOpen(false);

        //local storage
        saveFiltersToLocalStorage({
            searchRequi,
            searchFecha,
            searchProject: projectName,
            searchProveedor,
            fechaInicio,
            fechaFin
        });
        setCurrentPage(1);
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador
    };

    //funcion para actualizar el estado de SearchProveedor
    const handleProveedorChange = (event, proveedorId) => {
        event.preventDefault();

        let proveedorName = '';

        if (proveedorId === null) {
            setSearchProveedor('');
        } else {
            const selectedProveedor = proveedores.find(p => p.id === proveedorId);
            if (selectedProveedor) {
                setSearchProveedor(selectedProveedor.name);
                proveedorName = selectedProveedor.name;
            }
        }
        setSelectedProveedorId(proveedorId);
        setProveedorOpen(false);

        //local storage
        saveFiltersToLocalStorage({
            searchRequi,
            searchFecha,
            searchProject,
            searchProveedor: proveedorName,
            fechaInicio,
            fechaFin
        });
        setCurrentPage(1);
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador
    };

    const toggleDropdown = (requiId) => {
        setDropdownOpen(prevState => {
            const isCurrentlyOpen = !!prevState[requiId];
            const newState = {};

            // Close all dropdowns
            Object.keys(prevState).forEach(key => {
                newState[key] = false;
            });

            // If the dropdown was not already open, then open it
            if (!isCurrentlyOpen) {
                newState[requiId] = true;
            }

            return newState;
        });
    };

    const dropdownRefs = useRef({});
    const proyectoRef = useRef(null);
    const proveedorRef = useRef(null);


    const setDropdownRef = (node, id) => {
        if (node) {
            dropdownRefs.current[id] = node;
        }
    };

    //useeffect para manejar el click afuera del menu
    useEffect(() => {
        const handleDocumentClick = event => {
            // Verificar si se hizo clic fuera de los menús desplegables existentes
            const isDropdownClicked = Object.keys(dropdownRefs.current).some(key => {
                return dropdownRefs.current[key] && dropdownRefs.current[key].contains(event.target);
            });

            // Verificar si se hizo clic fuera del menú de proyecto
            const isProjectClicked = proyectoRef.current && proyectoRef.current.contains(event.target);

            // Verificar si se hizo clic fuera del menú de proveedor
            const isProveedorClicked = proveedorRef.current && proveedorRef.current.contains(event.target);

            // Cerrar los menús si es necesario
            if (!isDropdownClicked) {
                setDropdownOpen({});
            }
            if (!isProjectClicked) {
                setProjectOpen(false);
            }
            if (!isProveedorClicked) {
                setProveedorOpen(false);
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);


    //AQUI ESTA EL MODAL
    const [showModal, setShowModal] = useState(false);
    const [selectedRequi, setSelectedRequi] = useState();
    const [showModalForOneId, setShowModalForOneId] = useState(null);
    const [isModal2Open, setIsModal2Open] = useState(false);
    const [motivoEliminacion, setMotivoEliminacion] = useState(""); // Estado local para el motivo de eliminación


    const [showMultiDeleteModal, setShowMultiDeleteModal] = useState(false);

    //FUNCION PARA ELIMINAR SOLO UNA REQUISICION
    const deleteRequi = async (requi, motivo) => {

        setIsLoading(true);
        try {

            const result = await updateRequisitionsToDeleted(requi, motivo);

            if (result.success) {

                // Actualizar el estado para excluir las requisiciones eliminados exitosamente
                setRequisiciones(prevRequisiciones => prevRequisiciones.filter(requi => !result.id.includes(requi.id)));

                // Limpiar la lista de requisiciones seleccionados
                setSelectedRequi([]);

                // Cerrar el modal una vez que se haya completado la eliminación
                setShowMultiDeleteModal(false);

                props.ShowDone(result.message)

            } else {
                props.showErr(result.message);

            }
            setIsLoading(false);

        } catch (error) {
            props.showErr('Ocurrio un error inesperado.');
            console.log(error);
            setIsLoading(false);
        }
    };

    const handleModalDelete = (requiID, motivo) => {

        const ToDelete = requisiciones.find(u => u.id === requiID);

        if (ToDelete) {

            deleteRequi(ToDelete, motivo);
        }
        setShowModalForOneId(null);  // Cerrar el modal
    };

    //funciones para abrir y cerrar modal
    const handleOpenModal = (requi) => {
        setSelectedRequi(requi);
        setShowModal(true);
    };

    const handleCloseModal = async () => {
        setShowModal(false);
        //cargar los Roles  y Permisos
        setRequisiciones(await getRequisitions_approved());
    };


    function truncateText(text, wordLimit) {
        const wordsArray = text.split(' ');
        if (wordsArray.length > wordLimit) {
            return wordsArray.slice(0, wordLimit).join(' ') + '...';
        } else {
            return text;
        }
    }

    function formatDateOnly(timestamp) {
        // Crear un objeto Date usando el valor de seconds
        const date = new Date(timestamp.seconds * 1000);

        // Opciones para formatear solo la fecha
        const options = {
            year: 'numeric', month: '2-digit', day: '2-digit'
        };

        // Formatear y devolver solo la fecha
        return date.toLocaleDateString('es', options);
    }


    const goToEditRequi = async (req) => {

        const requiSel = await getRequisitionWithDetailsById(req.id)
        navigate('./ver', { state: { requiSel } });
    };

    const goToPrintRequi = async (req) => {

        const requiSel = await getRequisitionWithDetailsById(req.id)
        navigate('./print', { state: { requiSel } });
    };

    const goToVoucher = async (req) => {

        //console.log(req)
        const requiSel = await getRequisitionWithDetailsById(req.id)
        const vou = await getVoucherByRequisition(requiSel.correlativo)
        //console.log(requiSel)

        if (vou) {
            if (vou.estado === 'Pendiente') {
                navigate('/voucher/edit', { state: { vou, requiSel } });
            } else {
                navigate('/voucher/ver', { state: { vou, requiSel } });
            }
        } else {
            navigate('/voucher/nuevo', { state: { requiSel } });
        }

    };


    function onClickExport(data) {

        // Mapear y formatear los datos para la exportación
        const formattedData = data.map(item => ({
            "CORRELATIVO": item.correlativo,
            "FECHA": new Date(item.fecha.seconds * 1000).toLocaleDateString('es-GT'),
            "PROYECTO": item.proyecto.nombre,
            "PROVEEDOR": item.proveedor.nombre,
            "SOLICITANTE": item.solicitante,
            "EMISOR": item.emisor,
            "DESCRIPCIÓN": item.descripcion,
            "TOTAL": {
                v: item.total,
                t: 'n',
                z: '"Q"#,##0.00' // Formato de moneda para Quetzales
            }
        }));

        const d = new Date();
        const dateString = [
            d.getDate().toString().padStart(2, '0'),
            (d.getMonth() + 1).toString().padStart(2, '0'),
            d.getFullYear().toString().substring(2)
        ].join('-') + '_' + [
            d.getHours().toString().padStart(2, '0'),
            d.getMinutes().toString().padStart(2, '0'),
            d.getSeconds().toString().padStart(2, '0')  // Incluir los segundos aquí
        ].join('');

        const fileName = "Historial_Requisiciones_" + dateString;

        exportToExcel(fileName, formattedData);
    }

    //funcion para generar el archivo en Excel
    function exportToExcel(datedoc, formattedData) {

        // Crear una hoja de trabajo con los datos formateados
        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Obtener el rango de celdas de la hoja de trabajo
        const range = XLSX.utils.decode_range(worksheet['!ref']);

        // Aplicar negritas a la primera fila (encabezados)
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const headerCell = XLSX.utils.encode_cell({ c: C, r: 0 });
            worksheet[headerCell].s = {
                font: {
                    bold: true
                }
            };
        }

        // Definir los anchos de las columnas 
        worksheet['!cols'] = [
            { wch: 5 }, // # columna
            { wch: 15 }, // Fecha columna
            { wch: 20 }, // Proyecto columna
            { wch: 25 }, // Proveedor columna
            { wch: 15 }, // Solicitante columna
            { wch: 15 }, // Emisor columna
            { wch: 40 }, // Descripción columna
            { wch: 20 } // Total columna
        ];

        // Crear un libro de trabajo y añadir la hoja
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Requisiciones");

        // Escribe el libro de trabajo en formato binario
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Función para convertir la cadena de datos binarios a un Blob
        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        // Crear un Blob con los datos en formato binario
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });


        // Crear un enlace y hacer clic en él para descargar
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = datedoc + '.xlsx'; // Sugerir un nombre de archivo predeterminado
        document.body.appendChild(link); // Necesario para que Firefox funcione correctamente
        link.click();
        document.body.removeChild(link); // Limpiar el DOM

    }
    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' style={{ height: "215vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div style={{ width: "100%" }}>
                            <div className='titulo-container-solicitud'>
                                <p style={{ fontWeight: "500" }}>Historial de Requisiciones</p>
                                <button onClick={() => onClickExport(filteredRequis)}><FiDownload style={{ marginRight: "10px" }} />Exportar</button>
                            </div>
                            <div className='filter-users-historial'>
                                <form onSubmit={(e) => e.preventDefault()} >
                                    <div className="form-row">
                                        <div className="form-column">
                                            <label># de Requisición</label>
                                            <div className="historial-icon-container">
                                                <input id='requisition' name='requisition' onChange={InputChangeReq} placeholder="Escribe el # de requisición" value={searchRequi} />
                                                {searchRequi &&
                                                    <AiFillCloseCircle className="historial-clear-icon" onClick={() => {
                                                        setSearchRequi('');
                                                        //limpiar local storage
                                                        saveFiltersToLocalStorage({
                                                            searchRequi: '',
                                                            searchFecha,
                                                            searchProject,
                                                            searchProveedor,
                                                            fechaInicio,
                                                            fechaFin
                                                        });
                                                        document.getElementById('requisition').classList.remove('input-active');
                                                    }} />}
                                            </div>
                                        </div>
                                        <div className="form-column form-column-fecha">
                                            <label>Fecha</label>
                                            <div className="historial-icon-container"><input id='fecha' name='fecha' className={` ${fechaActiva ? 'input-active' : ''}`} onClick={(e) => {
                                                e.stopPropagation(); // Detiene la propagación del evento
                                                mostrarSelector();
                                            }}
                                                placeholder='Seleccione una fecha' readOnly value={searchFecha} />
                                                {searchFecha &&
                                                    <AiFillCloseCircle className="historial-clear-icon" onClick={() => {
                                                        setSearchFecha('');
                                                        setFechaInicio(null);
                                                        setFechaFin(null);
                                                        setFechaActiva(false);
                                                        //limpiar local storage
                                                        saveFiltersToLocalStorage({
                                                            searchRequi,
                                                            searchFecha: '',
                                                            searchProject,
                                                            searchProveedor,
                                                            fechaInicio: '',
                                                            fechaFin: ''
                                                        });
                                                    }} />}
                                            </div>

                                            {mostrarSelectorFechas && (
                                                <div className="fecha-selector-container">
                                                    <FechaSelector
                                                        fechaInicio={fechaInicio}
                                                        setFechaInicio={setFechaInicio}
                                                        fechaFin={fechaFin}
                                                        setFechaFin={setFechaFin}
                                                        onChange={manejadorCambioFecha}
                                                        onClose={cerrarSelectorFecha}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-column">
                                            <label>Proyecto</label>

                                            <button className="boton-form-column" style={{
                                                marginTop: "5px",
                                                borderColor: searchProject ? "#3A93F7" : "#647787"
                                            }}
                                                onClick={() => setProjectOpen(!isProjectOpen)} >
                                                {searchProject || "Selecciona un proyecto"}
                                                {isProjectOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                            </button>

                                            {isProjectOpen && (
                                                <div className="dropdown-historial" ref={proyectoRef}>
                                                    <ul>
                                                        <li>
                                                            <button className="boton-form-column" type="button" onClick={(e) => handleProjectChange(e, null)}>
                                                                Selecciona un proyecto
                                                            </button>
                                                        </li>
                                                        {proyectos.map(project => (
                                                            <li key={project.id}>
                                                                <button className="boton-form-column" type="button" onClick={(e) => handleProjectChange(e, project.id)}>
                                                                    {project.project_name}
                                                                </button>
                                                            </li>
                                                        ))}

                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-column">
                                            <label>Proveedor</label>

                                            <button className="boton-form-column" style={{ marginTop: "5px", marginTop: "5px", borderColor: searchProveedor ? "#3A93F7" : "#647787" }} type="button" onClick={() => setProveedorOpen(!isProveedorOpen)} >
                                                {searchProveedor || "Selecciona un proveedor"}
                                                {isProveedorOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                            </button>
                                            {isProveedorOpen && (
                                                <div className="dropdown-historial" ref={proveedorRef}>
                                                    <ul>
                                                        <li>
                                                            <button className="boton-form-column" type="button" onClick={(e) => handleProveedorChange(e, null)}>
                                                                Selecciona un proveedor
                                                            </button>
                                                        </li>
                                                        {proveedores.map(proveedor => (
                                                            <li key={proveedor.id}>
                                                                <button className="boton-form-column" type="button" onClick={(e) => handleProveedorChange(e, proveedor.id)}>
                                                                    {proveedor.name}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="label-historial">
                                <label>Total: Q {sumaTotalQuetzales.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label>
                                <label>Cantidad: {totalElementosFiltrados}</label>
                            </div>

                            <div className='user-details-requis-solicitadas'>
                                <table className='tabla-requis-solicitadas'>
                                    <thead>
                                        <tr className='tabla-header'>
                                            <th style={{ paddingTop: "2%", paddingBottom: "2%", paddingLeft: "3%", width: "7%" }}>#</th>
                                            <th style={{ width: "10%" }}>Fecha</th>
                                            <th style={{ width: "13%" }}>Proyecto</th>
                                            <th style={{ width: "13%" }}>Proveedor</th>
                                            <th style={{ width: "25%" }}>Descripción</th>
                                            <th style={{ width: "14%" }}>Total</th>
                                            <th style={{ width: "12%" }}>Voucher</th>
                                            <th style={{ width: "10%" }}></th>
                                        </tr>
                                        <tr className="separator">
                                            <td colSpan="8"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentRequisiciones.slice(0, 10).map((requi) => (

                                            <React.Fragment key={requi.id}>
                                                <tr key={requi.id} style={{ height: "75px" }}>
                                                    <td style={{ paddingLeft: "3%" }}>{requi.correlativo}</td>
                                                    <td>{formatDateOnly(requi.fecha)}</td>
                                                    <td>{requi.proyecto.nombre}</td>
                                                    <td>{requi.proveedor.nombre}</td>
                                                    <td style={{ paddingRight: "5%" }}>{truncateText(requi.descripcion, 18)}</td>
                                                    <td>Q {requi.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>

                                                    <td><button className="icon-button-requis-aprobar"
                                                        onClick={() => goToVoucher(requi)}>
                                                        Voucher
                                                    </button>
                                                    </td>
                                                    <td>
                                                        <div className='menu-usuarios-requis-solicitadas'
                                                            ref={node => setDropdownRef(node, requi.id)}
                                                            onClick={(e) => e.stopPropagation()}>
                                                            <button className="icon-button-requis-solicitadas"
                                                                onClick={() => toggleDropdown(requi.id)}>
                                                                <CiMenuKebab /></button>

                                                            {!showMultiDeleteModal && dropdownOpen[requi.id] && (
                                                                <div className="dropdownMenu-requis-solicitadas">
                                                                    <ul>
                                                                        <li><a style={{ marginRight: "3px" }} className="dontCloseDropdown-requis-solicitadas" onClick={() => goToEditRequi(requi)}><FaSearch style={{ marginRight: "3px", marginLeft: "5px" }}></FaSearch >Ver</a></li>
                                                                        <li><a style={{ marginRight: "3px" }} className="dontCloseDropdown-requis-solicitadas" onClick={() => goToPrintRequi(requi)}><FiPrinter style={{ marginRight: "3px", marginLeft: "5px" }}></FiPrinter>Imprimir</a></li>
                                                                        <li style={{ borderBottom: "none" }}><a className="dontCloseDropdown-requis-solicitadas" onClick={() => { setShowModalForOneId(requi.id); setDropdownOpen({}); }}><FiTrash2 style={{ marginRight: "3px", marginLeft: "5px" }}></FiTrash2>Eliminar</a></li>
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="separator">
                                                    <td colSpan="8"></td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='paginador-usuarios'>
                                    <span>{currentPage} de {totalPages} </span>
                                    <div>
                                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}><MdArrowBackIosNew /> Anterior</button>
                                        {currentPage > 1 && <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>}
                                        <button style={{ fontWeight: "bold" }} className={currentPage === currentPage ? "selected-page" : ""}>{currentPage}</button>
                                        {currentPage < totalPages && <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>}
                                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Siguiente <MdArrowForwardIos /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showModalForOneId && (
                <ModalEliminarRequi
                    requi={requisiciones.find((u) => u.id === showModalForOneId)}
                    initialOpen={true}
                    handleClose={() => setShowModalForOneId(null)}
                    onDelete={() => {

                        setIsModal2Open(true); // Abre el Modal 2
                    }}
                />
            )}

            {isModal2Open && (
                <ModalMotivoEliminacion
                    handleClose={() => {
                        setIsModal2Open(false)
                        setShowModalForOneId(null)
                    }} // Cierra el Modal 2
                    onAceptar={(motivo) => {
                        // Actualiza el estado local con el motivo de eliminación
                        setMotivoEliminacion(motivo);
                        // Llama a handleModalDelete pasando el motivo

                        handleModalDelete(showModalForOneId, motivo);
                        setIsModal2Open(false); // Cierra el Modal 2 después de ejecutar la lógica
                    }}
                    ShowWar={props.ShowWar}
                />
            )}
            {isLoading && <LoadingModal />}
        </>
    );
}

export default Historial;