import React, { useEffect, useState, useRef } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//para generar PDF
import PDFComponent, { getPDFStyles } from './pdfCreate';

//modal de carga
import LoadingModal from '../Loading-Modal/loading.jsx'

//import para funciones especificas de esta pantalla
import { useLocation } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { getNextVoucherNumber, getAccountsDetail, editVoucher } from '../../firebase/vouchers'
import { getProviders, addNewProvider, getProjects, getRequisitionWithDetailsById } from '../../firebase/requisiciones'
import { FiPrinter } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack } from 'react-icons/io';

import './nuevo_voucher.css'


function PrintVoucher(props) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar el modal de carga

    // constante para recibir el state desde la pantalla que solicito 
    const location = useLocation();

    const [requiSel, setRequiSel] = useState(
        location.state && location.state.requiSel ? location.state.requiSel : null
    );

    const [VouSel, setVouSel] = useState(
        location.state && location.state.vou ? location.state.vou : null
    );

    // Efecto para actualizar/clear el estado cuando cambia la ubicación
    useEffect(() => {
        // Esta lógica se ejecutará cada vez que 'location' cambie
        setRequiSel(
            location.state && location.state.requiSel ? location.state.requiSel : null
        );
        setVouSel(
            location.state && location.state.vou ? location.state.vou : null
        );

    }, [location]); // Dependencia en 'location'




    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);

    //estados para cargar informacion
    const [accounts, setAccounts] = useState();
    const [proveedores, setProveedores] = useState([]);
    const [proyectos, setProyectos] = useState([]);
    const [correlativo, setCorrelativo] = useState(0);
    const [RequisicionLoad, setRequisicionLoad] = useState();

    //cambio de color en bordes
    const [buttonClicked, setButtonClicked] = useState(false);
    const [buttonClickedB, setButtonClickedB] = useState(false);
    const [numCheckTouched, setNumCheckTouched] = useState(false);
    const [dateCheckTouched, setDateCheckTouched] = useState(false);
    const [descripcionTouched, setDescripcionTouched] = useState(false);
    const [montoTouched, setMontoTouched] = useState(false);

    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //Cargar datos para usar en la pantalla al cargar
                    setAccounts(await getAccountsDetail());
                    setProveedores(await getProviders());
                    setProyectos(await getProjects());
                    setCorrelativo(await getNextVoucherNumber());

                    if (VouSel.voucher_requisition) {
                        setRequisicionLoad(await getRequisitionWithDetailsById(VouSel.project.id_requi));
                    }

                    setLoading(false); // para saber si termino de cargar los datos del usuario

                    const canAccess = await hasPermission(res.rol, "voucher/nuevo");
                    if (!canAccess || !VouSel) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }

                    setUpdate(false)

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    //Menú desplegable 
    const [isOpen, setIsOpen] = useState(false);
    const [isAccountOpen, setAccountOpen] = useState(false);
    const [isProjectOpen, setProjectOpen] = useState(false);


    const [update, setUpdate] = useState(false);

    //Campos State para uso de funcionalidades
    const [selectedAccount, setSelectedAccount] = useState(''); // Guarda el ID de la cuenta seleccionada.
    const [selectedAccountName, setSelectedAccountName] = useState('');
    const [selectedAccountNumber, setSelectedAccountNumber] = useState(''); // Guarda el numero de cuenta seleccionada.
    const [selectedAccountBank, setSelectedAccountBank] = useState('');
    const [selectedAccountCurrency, setSelectedAccountCurrency] = useState('');
    const [selectedProject, setSelectedProject] = useState(''); // Guarda el ID del proyecto seleccionado.
    const [selectedProjectName, setSelectedProjectName] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(''); // Guarda la empresa relacionada con el proyecto seleccionado.


    const [numCheckVal, setNumCheckVal] = useState('');
    const [dateCheckVal, setDateCheckVal] = useState(VouSel ? new Timestamp(VouSel.check.date.seconds, VouSel.check.date.nanoseconds) : Timestamp.fromDate(new Date()));
    const [montoVal, setMontoVal] = useState('');
    const montoRef = useRef(null);

    const [selectedProvider, setSelectedProvider] = useState('');
    const [selectedProviderName, setSelectedProviderName] = useState('');


    const [descripcion, setDescripcion] = useState('');
    const [currentDate, setCurrentDate] = useState(new Date()); //fecha en la que se esta creando el voucher

    //USEFFECT PARA CARGAR VALORES DESDE REQUISICION SELECCIONADA
    useEffect(() => {
        // ejecuta la lógica solo cuando la carga haya finalizado
        if (!loading && VouSel !== null && proveedores.length > 0 && proyectos.length > 0 && accounts.length > 0) {

            //LLENAR DATOS DESDE VouSel
            setSelectedAccount(VouSel.account.id)
            setSelectedAccountBank(VouSel.account.bank)
            setSelectedAccountCurrency(VouSel.account.currency)
            setSelectedAccountNumber(VouSel.account.account_number)
            setSelectedAccountName(VouSel.account.name)

            setNumCheckVal(VouSel.check.number)

            if (VouSel.check.date.seconds && VouSel.check.date.nanoseconds) {
                // Mantener dateCheckVal como un objeto Timestamp de Firebase
                const timestamp = new Timestamp(VouSel.check.date.seconds, VouSel.check.date.nanoseconds);
                setDateCheckVal(timestamp);
            }

            setSelectedProvider(VouSel.check.beneficiary_id)
            setSelectedProviderName(VouSel.check.beneficiary)
            setDescripcion(VouSel.check.descripcion)
            setMontoVal(VouSel.check.amount)

            setCorrelativo(VouSel.correlativo)
            montoRef.current.value = VouSel.check.amount
            setMontoVal(VouSel.check.amount)
            formatInputValue()

            // Buscar el proveedor en la lista basado en informacion del voucher
            const proveedorEncontrado = proveedores.find(prov => prov.name === VouSel.check.beneficiary);
            if (proveedorEncontrado) {
                setSelectedProvider(proveedorEncontrado.id);
                setSelectedProviderName(proveedorEncontrado.name);
            }

            // Buscar los datos de la cuenta en la lista de cuentaws
            const CuentaEncontrado = accounts.find(account => account.account_number === VouSel.account.account_number);
            if (CuentaEncontrado) {
                setSelectedAccount(CuentaEncontrado.id)
                setSelectedAccountBank(CuentaEncontrado.bank)
                setSelectedAccountCurrency(CuentaEncontrado.currency)
                setSelectedAccountNumber(CuentaEncontrado.account_number)
                setSelectedAccountName(CuentaEncontrado.name)
            }

            if (VouSel.voucher_requisition) {
                const project = proyectos.find(p => p.id === VouSel.project.id_project);

                if (project) {
                    setSelectedProject(project.id);
                    setSelectedProjectName(project.project_name);
                    setSelectedCompany(project.project_company);
                }
            }
        }

    }, [loading, VouSel, proveedores, proyectos, accounts]);

    //USEEFFECT PARA CARGAR LA IMPRECION DE MANERA AUTOMATICA
    const [isDataLoaded, setIsDataLoaded] = useState(false); // Estado para rastrear si los datos están cargados

    // Este useEffect maneja la carga de datos y la impresión automática
    useEffect(() => {
        if (!loading && !isDataLoaded) {
            // Cuando los datos se han cargado y la impresión aún no se ha realizado
            setTimeout(() => {
                mostrarVistaPreviaImpresion();
                setIsDataLoaded(true); // Marca los datos como cargados para evitar repeticiones
            }, 700);
        }
    }, [loading, isDataLoaded]);


    const InputChangeNumcheck = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        if (name === 'monto') {
            // Eliminar caracteres no deseados excepto números y punto decimal
            let cleanedValue = value.replace(/[^0-9.]/g, '');

            // Limitar a dos decimales pero permitir escribir el punto
            const parts = cleanedValue.split('.');
            if (parts.length > 2) {
                cleanedValue = parts[0] + '.' + parts[1];
            }
            if (parts.length === 2 && parts[1].length > 2) {
                cleanedValue = parts[0] + '.' + parts[1].substr(0, 2);
                props.ShowWar('Esta campo solo admite números con hasta dos decimales.');
            }


            const regex = /^\d*\.?\d{0,2}$/;

            if (regex.test(cleanedValue)) {
                setMontoVal(cleanedValue);
                formatInputValue();
            } else {
                props.ShowWar('No se pueden ingresar más de dos decimales');
            }
        }

        switch (name) {
            case 'descripcion':
                setDescripcion(value);
                break;

            case 'Numcheck':
                if (/^\d*$/.test(value)) {
                    setNumCheckVal(value);
                } else {
                    props.ShowWar('Esta campo solo admite números')
                }
                break;

            case 'fecha':
                const dateParts = value.split('-').map(part => parseInt(part, 10));
                const dateObject = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
                const timestamp = Timestamp.fromDate(dateObject);
                setDateCheckVal(timestamp);
                break;

            case 'monto':


                break;

            default:
                // Manejo para otros casos o si no coincide ninguno
                break;
        }

        if (event.target.id === 'Numcheck') {
            setNumCheckTouched(true);
        } else if (event.target.id === 'fecha') {
            setDateCheckTouched(true);
        } else if (event.target.id === 'descripcion') {
            setDescripcionTouched(true);
        } else if (event.target.id === 'monto') {
            setMontoTouched(true);
        }

    }

    function formatDate(timestamp) {
        // Convertir el Timestamp de Firebase a un objeto Date
        const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);

        // Formateo de la fecha
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        return `${day}-${month}-${year}`;
    }

    //funcion para controlar el cambio en el drop down de Cuenta
    const handleAccountChange = (accountID) => {

        const account = accounts.find(p => p.id === accountID);

        if (account) {
            setSelectedAccount(account.id);
            setSelectedAccountName(account.name);
            setSelectedAccountNumber(account.account_number);
            setSelectedAccountBank(account.bank);
            setSelectedAccountCurrency(account.currency);
        } else {
            setSelectedAccount(null);
            setSelectedAccountName('');
            setSelectedAccountNumber('');
            setSelectedAccountBank('');
            setSelectedAccountCurrency('');
        }
        setButtonClicked(true);
        setAccountOpen(false);

    };


    //Para imprimir
    const pdfRef = useRef(null);

    const mostrarVistaPreviaImpresion = () => {

        const pdfStyles = getPDFStyles();
        if (pdfRef.current) {
            const contenido = pdfRef.current.innerHTML;
            const printWindow = window.open('', '_blank');
            if (printWindow) {  // Verificar que la ventana se haya abierto

                printWindow.document.write('<html><head><title>VOUCHER</title>');
                // Inyecta los estilos CSS
                printWindow.document.write(`<style>${pdfStyles}</style>`);
                printWindow.document.write('</head><body>');
                printWindow.document.write(contenido);
                printWindow.document.write('</body></html>');
                printWindow.document.close();

                // Evento que detecta el cierre de la ventana de impresión
                const checkWindowClosed = setInterval(() => {
                    if (printWindow.closed) {
                        window.focus();  // Devuelve el foco a la ventana principal
                        clearInterval(checkWindowClosed);  // Detiene la verificación
                        // Aquí se navega a la página deseada después de que se cierre la ventana de impresión
                        navigate("/voucher/historial");
                        printWindow.close();
                    }
                }, 100);

                printWindow.addEventListener('afterprint', function () {
                    printWindow.close();
                });
                printWindow.print();
            } else {
                console.error("No se pudo abrir la ventana de impresión. Verifica los bloqueadores de ventanas emergentes.");
            }

        } else {
            console.error("Referencia no disponible");
        }
    };

    //menu desplegable de proveedores
    const handleProviderClick = (providerId) => {
        setSelectedProvider(providerId);
        setButtonClickedB(true);
        setIsOpen(false); // Cierra el menú después de seleccionar
        const provider = proveedores.find(p => p.id === providerId);
        if (provider) {
            setSelectedProviderName(provider.name);
        }
    };

    //estados para agregar nuevo proveedor
    const [isAddingProvider, setIsAddingProvider] = useState(false);
    const [newProviderName, setNewProviderName] = useState('');

    //funcion para agregar nuevo proveedor
    const handleAddNewProvider = async () => {

        if (newProviderName.trim() === '') {
            props.ShowWar("El nombre del proveedor no puede estar vacío");
            return;
        }
        try {
            const newProvider = await addNewProvider(newProviderName);
            if (newProvider.success) {
                // Actualiza el estado de los proveedores con el nuevo proveedor.
                setProveedores(prevProviders => [...prevProviders, { id: newProvider.id, name: newProvider.name }]);

                setNewProviderName('');
                setIsAddingProvider(false);
                props.ShowDone(newProvider.message)
            } else {
                props.showErr(newProvider.error)
                props.ShowWar(newProvider.message)
            }

        } catch (error) {
            console.error("Error al agregar proveedor: ", error);
            props.showErr("Error inesperado al agregar proveedor.")
        }
    }

    //funcion para controlar el cambio en el drop down de Proyecto
    const handleProjectChange = (projectId) => {

        const project = proyectos.find(p => p.id === projectId);

        if (project) {
            setSelectedProject(project.id);
            setSelectedProjectName(project.project_name);
            setSelectedCompany(project.project_company);
        } else {
            setSelectedProject(null);
            setSelectedProjectName("");
            setSelectedCompany('');
        }
        setProjectOpen(false);
    };

    //cerrar el menu al darle click fuera
    useEffect(() => {
        function handleDocumentClick(event) {

            // Verificar si el clic es fuera del menú "card-informacion-solicitud-cheque"
            if (isOpen && !event.target.closest('.card-informacion-solicitud-voucher')) {
                setIsOpen(false);
            }

            // Verificar si el clic es fuera del menú "field-container-proyecto"
            if (isAccountOpen && !event.target.closest('.field-container-proyecto')) {
                setAccountOpen(false);
            }

            // Verificar si el clic es fuera del menú "field-container-proyecto"
            if (isProjectOpen && !event.target.closest('.field-container-proyecto')) {
                setProjectOpen(false);
            }
        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isOpen, isAccountOpen, isProjectOpen]);


    // useEffect para verificar que los datos necesarios contengan informacion
    useEffect(() => {
        // Comprobar si todos los campos tienen datos
        const allFieldsFilled = selectedAccount &&
            selectedAccountName &&
            selectedAccountNumber &&
            selectedProvider &&
            selectedProviderName &&
            descripcion &&
            dateCheckVal &&
            numCheckVal &&
            montoVal &&
            (selectedProjectName || selectedProjectName === '');

        // Habilitar o deshabilitar el botón basado en si todos los campos están llenos
        setUpdate(allFieldsFilled);
    }, [selectedAccount, selectedAccountName, selectedAccountNumber, selectedProvider, descripcion, dateCheckVal, numCheckVal, montoVal, selectedProjectName]);


    //enviar Voucher a almacenar
    const handleSaveChanges = async () => {

        setIsLoading(true);
        try {

            //Creando la estructura del voucher
            const newVoucherData = {
                account: {
                    id: selectedAccount,
                    bank: selectedAccountBank,
                    currency: selectedAccountCurrency,
                    account_number: selectedAccountNumber,
                    name: selectedAccountName
                },
                check: {
                    number: numCheckVal,
                    date: dateCheckVal,
                    beneficiary_id: selectedProvider,
                    beneficiary: selectedProviderName,
                    descripcion: descripcion,
                    amount: Number(montoVal)
                },
                correlativo: correlativo,
                user_creator: useUser.nombre,
                date_creation: currentDate,
                estado: VouSel.estado,
                voucher_requisition: VouSel.voucher_requisition,
            };

            // Agregar propiedad si viene de proyecto
            if (VouSel.project) {
                newVoucherData.voucher_requisition = true;
                newVoucherData.project = {
                    id_requi: VouSel.project.id_requi,
                    requisition: VouSel.project.requisition,
                    id_project: selectedProject,
                    compania: selectedCompany,
                    nombre: selectedProjectName
                };
            }

            const result = await editVoucher(VouSel.id, newVoucherData);

            if (result.success) {
                //Limpiar todos los datos
                setSelectedAccount('')
                setSelectedAccountBank('')
                setSelectedAccountCurrency('')
                setSelectedAccountNumber('')
                setSelectedAccountName('')

                setSelectedProject(null);
                setSelectedProjectName("");
                setSelectedCompany('');

                setNumCheckVal('')
                setDateCheckVal(Timestamp.fromDate(new Date()))
                setSelectedProvider('')
                setSelectedProviderName('')
                setDescripcion('')
                setMontoVal('')

                setButtonClicked(false);
                setButtonClickedB(false);
                setNumCheckTouched(false);
                setDateCheckTouched(false);
                setDescripcionTouched(false);
                setMontoTouched(false);

                if (montoRef.current) {
                    montoRef.current.value = '';
                }

                setCorrelativo(await getNextVoucherNumber())
                setCurrentDate(new Date())

                setUpdate(false);
                setIsLoading(false);

                props.ShowDone(result.message)
                navigate('/voucher/historial')

            } else {
                props.showErr(result.error)
                props.ShowWar(result.message)
                setIsLoading(false);
            }

        } catch (error) {
            props.showErr("No es posible crear el voucher.");
            props.ShowWar(error)
            setIsLoading(false);
        }
    };


    function formatCurrencyLive(value) {

        //solo quitar las comas del value
        value = value.replace(/,/g, '');

        // Verificar si el valor contiene caracteres no permitidos
        const regex = /[^0-9.]/g;
        if (regex.test(value)) {
            props.ShowWar('Esta campo solo admite números con hasta dos decimales');
        }

        // Eliminar caracteres no deseados excepto números y punto decimal
        value = value.replace(/[^0-9.]/g, '');

        // Separar la parte entera y decimal
        let [integerPart, decimalPart] = value.split('.');

        // Limitar la parte decimal a dos dígitos
        if (decimalPart) {
            decimalPart = decimalPart.substring(0, 2);
        }

        // Formatear la parte entera y reconstruir el valor
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    }


    const formatInputValue = () => {
        if (!montoRef.current) return;

        const input = montoRef.current;
        let cursorPosition = input.selectionStart;
        const originalLength = input.value.length;

        input.value = formatCurrencyLive(input.value);

        const newLength = input.value.length;
        cursorPosition = cursorPosition + (newLength - originalLength);

        // Ajustar la posición del cursor en caso de eliminación o adición de comas
        if (input.value[cursorPosition - 1] === ',') {
            cursorPosition--; // Mover el cursor antes de la coma
        }

        input.setSelectionRange(cursorPosition, cursorPosition);
    };

    //Para saber donde retroceder
    const gotoBack = () => {
        if (requiSel) {
            navigate('/requisicion/historial');
        } else {
            navigate("/voucher/historial")
        }
    };


    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' >

                        < Menu
                            userLoged={useUser}
                        />

                        <div style={{ width: "100%" }}>
                            <a className="back-button-requis" onClick={() => gotoBack()}> <IoIosArrowBack style={{ marginRight: "10px" }} /> Regresar</a>
                            <div className="titulo-container-solicitud">
                                <p style={{ fontWeight: "500" }}>Voucher #{correlativo}</p>
                                <div ref={pdfRef} hidden={true}>
                                    <PDFComponent
                                        fecha={dateCheckVal.toDate().toLocaleString('es-GT', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        }).replace(/(\d+)\/(\d+)\/(\d+)/i, '$1/$2/$3')}
                                        numero={correlativo}
                                        cuenta_num={selectedAccountNumber}
                                        cuenta_name={selectedAccountName}
                                        check={numCheckVal}
                                        proveedor={selectedProviderName}
                                        descripcion={descripcion}
                                        total={montoVal}
                                        proyecto={VouSel?.project ? selectedProjectName : null}
                                        items={VouSel?.project ? RequisicionLoad.details : null}
                                    />

                                </div>

                            </div>
                            <div className='header-solicitud'>
                                {VouSel?.voucher_requisition ? <span>Requisición # {VouSel.project.requisition}</span> : ""}
                            </div>
                            <div className='card-informacion-solicitud'>
                                <div className="card-informacion-solicitud-span">
                                    <span>Información Básica</span>
                                </div>
                                <div className="card-informacion-solicitud-voucher">
                                    <div className="field-container-proyecto">
                                        <label>Cuenta Bancaria</label>
                                        <input className="card-informacion-solicitud-voucher-input" type="text" disabled={true} value={selectedAccountName} />
                                    </div>

                                    <div className="field-container-proyecto">
                                        <label>No. de cuenta</label>
                                        <input className="card-informacion-solicitud-voucher-input" type="text" disabled={true} value={selectedAccountNumber} />
                                    </div>
                                </div>
                            </div>
                            <div className='card-detalle-voucher'>
                                <div className='card-detalle-voucher-span'>
                                    <span>Descripción del Cheque</span>
                                </div>
                                <div className="card-informacion-solicitud-voucher">
                                    <div className="field-container-proyecto">
                                        <label>No. de Cheque</label>
                                        <input className="card-informacion-solicitud-voucher-input" type="text" disabled={true} value={numCheckVal} />
                                    </div>
                                    <div className="field-container-proyecto">
                                        <label>Fecha</label>
                                        <input className="card-informacion-solicitud-voucher-input" type="text" disabled={true} value={formatDate(dateCheckVal)} />
                                    </div>
                                </div>
                                <div className="card-informacion-solicitud-voucher">
                                    <div className="field-container-proyecto">
                                        <label>Beneficiario</label>
                                        <input className="card-informacion-solicitud-voucher-input" type="text" disabled={true} value={selectedProviderName} />
                                    </div>
                                </div>

                                {VouSel?.voucher_requisition ?
                                    <div className="card-informacion-solicitud-voucher">
                                        <div className="field-container-proyecto">
                                            <label>Financiamiento</label>
                                            <input className="card-informacion-solicitud-voucher-input" type="text" disabled={true} value={selectedProjectName} />
                                        </div>
                                    </div>
                                    :
                                    ""
                                }

                                <div className="card-informacion-solicitud-voucher">
                                    <label>Descripción</label>
                                    <textarea className="card-informacion-solicitud-voucher-input" type="text" disabled={true} value={descripcion} >
                                    </textarea>
                                </div>

                                <div className="card-informacion-solicitud-voucher">
                                    <label>Monto</label>
                                    <span className="input-with-q-wrapper">
                                        <input ref={montoRef} className="card-informacion-solicitud-voucher-input" type="text" disabled={true} />
                                    </span>
                                </div>


                            </div>
                            <div className='button-container-solicitud'>
                                <button className='botonPrincipal-solicitud' onClick={mostrarVistaPreviaImpresion}><FiPrinter style={{ marginRight: "10px" }} /> Imprimir</button>
                            </div>
                        </div>
                    </div >
                </div >
            )
            }
            {isLoading && <LoadingModal />}
        </>
    );
}

export default PrintVoucher;