import React, { useState, useEffect, useRef } from 'react';

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//import para funciones especificas de esta pantalla
import { getAllCompanies, deletecompanyById, deleteCompaniesByIds } from '../../firebase/empresas.js'

import { Link } from 'react-router-dom';

//modal de carga
import LoadingModal from '../Loading-Modal/loading.jsx'
import '../Usuarios/usuarios.css';
import '../Requisiciones/requisicion_solicitadas.css'
import '../Requisiciones/historial_requisiciones.css'

//import de iconos
import { AiFillCloseCircle } from 'react-icons/ai';
import { CiMenuKebab } from 'react-icons/ci';
import { GrFormEdit } from 'react-icons/gr';
import { FiTrash2 } from 'react-icons/fi';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';


import Modal from '../Modal/Modal.jsx'


function Empresas(props) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar el modal de carga


    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);
    const [Empresas, setEmpresas] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({});

    // Estado para rastrear qué proyecto han sido seleccionados
    const [selectedEmpresas, setselectedEmpresas] = useState([]);
    const [selectAll, setSelectAll] = useState(false); // Estado para el checkbox de la cabecera

    const toggleDropdown = (companyID) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [companyID]: !prevState[companyID]
        }));
    };

    const dropdownRef = useRef(null);

    //AQUI ESTA EL MODAL
    const [showModal, setShowModal] = useState(false);
    const [showModalForcompanyID, setShowModalForcompanyId] = useState(null);

    const [showMultiDeleteModal, setShowMultiDeleteModal] = useState(false);


    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleEmpresastateChanged);
            hasCheckedPermission.current = true;
        }

        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                if (event.target.className !== 'dontCloseDropdown' && !showModal) {
                    setDropdownOpen({});
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, []);


    async function handleEmpresastateChanged(user) {
        if (user) {
            setIsLoading(true);
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //cargar las empresas
                    const companyList = await getAllCompanies();
                    setEmpresas(companyList);

                    setLoading(false); // para saber si termino de cargar los datos de las empresas

                    const canAccess = await hasPermission(res.rol, "empresas/listado");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
                setIsLoading(false);
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }


    //AQUI ESTA EL PAGINADOR
    const [currentPage, setCurrentPage] = useState(() => {
        const savedPage = localStorage.getItem('currentPageEmpresas');
        return savedPage ? Number(savedPage) : 1;
    });
    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const totalItems = Empresas.length;
    const [totalPages, setTotalPages] = useState(0);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        localStorage.setItem('currentPageEmpresas', pageNumber);
    };
    const [searchName, setSearchName] = useState('');
    const [SearchContacto, setSearchContacto] = useState('');
    const [SearchGiro, setSearchGiro] = useState('');

    const InputChangeUsr = (event) => {
        const { name, value } = event.target;
        if (name === 'nombre') {
            setSearchName(value);
        } else if (name === 'searchContacto') {
            setSearchContacto(value);
        } else if (name === 'searchGiro') {
            setSearchGiro(value);
        }
        if (value) {
            document.getElementById(name).classList.add('input-active');
        } else {
            document.getElementById(name).classList.remove('input-active');
        }

        // Guardar los filtros actualizados en localStorage
        saveFiltersToLocalStorage({
            searchName: name === 'nombre' ? value : searchName,
            SearchContacto: name === 'searchContacto' ? value : SearchContacto,
            SearchGiro: name === 'searchGiro' ? value : SearchGiro
        });
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador
    };

    const filteredEmpresas = Empresas.filter(company => {
        const nameMatches = company.nombre.toLowerCase().includes(searchName.toLowerCase());

        // Solo incluirá empresas cuyo contacto coincida o, si SearchContacto está vacío, aquellos que tengan el campo vacío.
        const contactMatches = SearchContacto.trim() === '' ?
            (!company.propietario || company.propietario.toLowerCase().includes(SearchContacto.toLowerCase())) :
            (company.propietario && company.propietario.toLowerCase().includes(SearchContacto.toLowerCase()));

        // Solo incluirá empresas cuyo giro coincida o, si SearchGiro está vacío, aquellos que tengan el campo vacío.
        const giroMatches = SearchGiro.trim() === '' ?
            (!company.nit || company.nit.toLowerCase().includes(SearchGiro.toLowerCase())) :
            (company.nit && company.nit.toLowerCase().includes(SearchGiro.toLowerCase()));

        return nameMatches && contactMatches && giroMatches;
    });

    useEffect(() => {
        setTotalPages(Math.ceil(filteredEmpresas.length / itemsPerPage));
    }, [filteredEmpresas, itemsPerPage]);

    const [executionCount, setExecutionCount] = useState(0);

    //Restaurar los Filtros desde localStorage al Cargar la Página
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('filtersEmpresas'));
        if (savedFilters) {
            setSearchName(savedFilters.searchName || '');
            setSearchContacto(savedFilters.SearchContacto || '');
            setSearchGiro(savedFilters.SearchGiro || '');
        }

        const savedPage = localStorage.getItem('currentPageEmpresas');

        if (savedPage) {
            setCurrentPage(Number(savedPage));
        }

    }, []);

    useEffect(() => {
        setExecutionCount(prevCount => prevCount + 1); // Incrementa el contador

        const lastURL = localStorage.getItem('lastURL');
        if ((executionCount >= 3) || (lastURL != 'empresas/listado')) {
            setCurrentPage(1);
            localStorage.setItem('currentPageEmpresas', 1);
            localStorage.setItem('lastURL', 'empresas/listado');
        }
    }, [searchName, SearchContacto, SearchGiro]);

    //SECCION PARA MANEJAR HISTORIAL EN FILTROS POR LOCALSTORAGE

    //Guardar los Filtros en localStorage
    const saveFiltersToLocalStorage = (filters) => {
        localStorage.setItem('filtersEmpresas', JSON.stringify(filters));
    };

    const currentItems = filteredEmpresas.slice(indexOfFirstItem, indexOfLastItem);


    const [isRoleOpen, setRoleOpen] = useState(false);

    // Efecto para manejar el clic fuera del menú
    useEffect(() => {
        function handleDocumentClick(event) {

            // Verificar si el clic es fuera del menú de roles
            if (isRoleOpen && !event.target.closest('.field-container-usuarios')) {
                setRoleOpen(false);
            }

        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isRoleOpen]); // Dependencias del efecto



    const goToEditcompany = (CompanySel) => {
        navigate('./editar', { state: { CompanySel } });
    };

    const deleteEmpresas = async (companyU) => {

        setIsLoading(true);
        try {
            const result = await deletecompanyById(companyU.id);
            if (result.success) {
                // Actualizar el estado para excluir la empresa eliminada
                setEmpresas(prevEmpresas => prevEmpresas.filter(pr => pr.id !== companyU.id));
                props.ShowDone(`Se elimino la empresa: ${companyU.nombre}`);
            } else {
                // Manejar el caso en que la eliminación falla
                props.showErr(`La Empresa ${companyU.nombre} no se pudo eliminar.`);
                props.ShowWar(result.message);
            }
            setIsLoading(false);

        } catch (error) {
            console.log(error);
            props.showErr(`La Empresa ${companyU.nombre} no se pudo eliminar.`);
            setIsLoading(false);
        }

    };

    // Función para manejar la selección de una empresa
    const handleEmpresasSelect = (prov, isSelected) => {
        if (isSelected) {
            setselectedEmpresas([...selectedEmpresas, prov]);
        } else {
            setselectedEmpresas(selectedEmpresas.filter(u => u.id !== prov.id));
            setSelectAll(false); // Desactivar el checkbox de la cabecera si un proyecto se deselecciona
        }
    };

    // Función para manejar el cambio en el checkbox de la cabecera
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);

        if (!selectAll) {
            setselectedEmpresas(currentItems);
        } else {
            setselectedEmpresas([]);
        }
    };

    const handleShowModalMulti = () => {
        setShowMultiDeleteModal(true);
        setDropdownOpen({});
    };

    // Función para manejar la eliminación de empresas seleccionados
    const handleDeleteselectedEmpresas = async () => {

        setIsLoading(true);
        try {

            // Extrae solo los IDs de cada empresa
            const EmpresasIds = selectedEmpresas.map(company => company.id);

            const results = await deleteCompaniesByIds(EmpresasIds);
            const successfulDeletes = results.filter(result => result.success).map(result => result.id);
            const failedDeletes = results.filter(result => !result.success);

            // Actualizar el estado para excluir las empresas eliminados exitosamente
            setEmpresas(prevEmpresas => prevEmpresas.filter(pr => !successfulDeletes.includes(pr.id)));

            // Desactivar el checkbox de la cabecera
            setSelectAll(false);

            // Limpiar la lista de empresas seleccionados
            setselectedEmpresas([]);

            // Cerrar el modal una vez que se haya completado la eliminación
            setShowMultiDeleteModal(false);

            if ((successfulDeletes.length === selectedEmpresas.length) && (successfulDeletes.length > 0)) {
                props.ShowDone(`Se eliminaron ${successfulDeletes.length} empresas`);
            } else {

                if (successfulDeletes.length === 1) {
                    props.ShowDone(`Se elimino la empresa: ${successfulDeletes[0].nombre} `);
                } else if (successfulDeletes.length > 0) {
                    props.ShowDone(`Se eliminaron ${successfulDeletes.length} empresas`);
                }

                failedDeletes.forEach(failed => {
                    props.showErr(`La empresa no se pudo eliminar.`);
                    props.ShowWar(failed.message);
                });
            }

            setIsLoading(false);

        } catch (error) {
            props.showErr('Ocurrio un error inesperado.');
            console.log(error);
            setIsLoading(false);
        }

    };

    const handleModalDelete = (provID) => {
        const companyToDelete = Empresas.find(u => u.id === provID);
        if (companyToDelete) {
            deleteEmpresas(companyToDelete);
        }
        setShowModalForcompanyId(null);  // Cerrar el modal
    };


    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />

                    <div className='bodyHome-user' style={{ height: "210vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div style={{ width: "100%" }}>
                            <p>Empresas</p>
                            <div className='filter-use-p'>
                                <div className='filter-use'>
                                    <form style={{ width: "80%" }}>
                                        <div className='filter-userDetails'>
                                            <label>Nombre</label>
                                            <label>Propietario/Rep. Legal</label>
                                            <label>NIT</label>
                                            <div className="input-with-icon">
                                                <input
                                                    id='nombre'
                                                    name='nombre'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el nombre de la Empresa"
                                                    value={searchName}
                                                />
                                                {searchName &&
                                                    <AiFillCloseCircle className="close-icon-name" onClick={() => {
                                                        setSearchName('');
                                                        // limpiar local storage
                                                        saveFiltersToLocalStorage({
                                                            searchName: '',
                                                            SearchContacto,
                                                            SearchGiro
                                                        });
                                                        document.getElementById('nombre').value = '';
                                                        document.getElementById('nombre').classList.remove('input-active');
                                                    }} />}
                                            </div>
                                            <div className="input-with-icon">
                                                <input
                                                    id='searchContacto'
                                                    name='searchContacto'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el nombre del Propietario"
                                                    value={SearchContacto}
                                                />
                                                {SearchContacto &&
                                                    <AiFillCloseCircle className="close-icon-name" onClick={() => {
                                                        setSearchContacto('');
                                                        // limpiar local storage
                                                        saveFiltersToLocalStorage({
                                                            searchName,
                                                            SearchContacto: '',
                                                            SearchGiro
                                                        });
                                                        document.getElementById('searchContacto').value = '';
                                                        document.getElementById('searchContacto').classList.remove('input-active');
                                                    }} />}
                                            </div>
                                            <div className="input-with-icon">
                                                <input
                                                    id='searchGiro'
                                                    name='searchGiro'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el nit de la Empresa"
                                                    value={SearchGiro}
                                                />
                                                {SearchGiro &&
                                                    <AiFillCloseCircle className="close-icon-name" onClick={() => {
                                                        setSearchGiro('');
                                                        // limpiar local storage
                                                        saveFiltersToLocalStorage({
                                                            searchName,
                                                            SearchContacto,
                                                            SearchGiro: ''
                                                        });
                                                        document.getElementById('searchGiro').value = '';
                                                        document.getElementById('searchGiro').classList.remove('input-active');
                                                    }} />}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <Link to="/empresas/nuevo">
                                    <button className='filter-use-button'>+ Crear Empresa</button>
                                </Link>
                            </div>
                            <div className='delete-users' >
                                <button onClick={handleShowModalMulti} style={{ cursor: "pointer" }}><FiTrash2></FiTrash2> Eliminar</button>
                            </div>
                            {showMultiDeleteModal &&
                                <Modal
                                    selectedCompanies={selectedEmpresas}
                                    initialOpen={true}
                                    close={() => setShowMultiDeleteModal(false)}
                                    onDelete={handleDeleteselectedEmpresas}
                                />
                            }
                            <div className='user-details'>
                                <table className='tabla-usuarios'>
                                    <thead>
                                        <tr className='tabla-header'>
                                            <th style={{ paddingTop: "2%", paddingBottom: "2%", paddingLeft: "3%", width: "7%" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th></th>
                                            <th>Nombre</th>
                                            <th>Propietario/Rep. Legal</th>
                                            <th>NIT</th>
                                            <th>Acciones</th>
                                        </tr>
                                        <tr className="separator">
                                            <td colSpan="6"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems.map((company, index) => (
                                            <React.Fragment key={company.id}>
                                                <tr key={company.id} >
                                                    <td style={{ paddingLeft: "3%" }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedEmpresas.some(u => u.id === company.id)}
                                                            onChange={e => handleEmpresasSelect(company, e.target.checked)}
                                                        />
                                                    </td>
                                                    <td style={{ height: "9vh" }}>
                                                        {company.imageUrl ?
                                                            <img width={50} className="user-img" src={company.imageUrl || "default-image-url.jpg"} alt={`${company.nombre}'s foto`} />
                                                            : ' '}

                                                    </td>
                                                    <td>{company.nombre}</td>
                                                    <td>{company.propietario ? company.propietario : ''}</td>
                                                    <td>{company.nit ? company.nit : ''}</td>
                                                    <td style={{ paddingLeft: "2%" }}>
                                                        <div className='menu-usuarios' ref={dropdownRef} onClick={(e) => e.stopPropagation()}>
                                                            <button className="icon-button" onClick={() => toggleDropdown(company.id)}><CiMenuKebab /></button>

                                                            {!showMultiDeleteModal && dropdownOpen[company.id] && (
                                                                <div className="dropdownMenu">
                                                                    <ul>
                                                                        <li><a className="dontCloseDropdown" onClick={() => goToEditcompany(company)}><GrFormEdit></GrFormEdit>Editar</a></li>
                                                                        <li><a className="dontCloseDropdown" onClick={() => { setShowModalForcompanyId(company.id); setDropdownOpen({}); }}><FiTrash2></FiTrash2>Eliminar</a></li>
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="separator">
                                                    <td colSpan="6"></td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='paginador-usuarios'>
                                    <span>{currentPage} de {totalPages} </span>
                                    <div>
                                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}><MdArrowBackIosNew /> Anterior</button>
                                        {currentPage > 1 && <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>}
                                        <button style={{ fontWeight: "bold" }} className={currentPage === currentPage ? "selected-page" : ""}>{currentPage}</button>
                                        {currentPage < totalPages && <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>}
                                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Siguiente <MdArrowForwardIos /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            )
            }
            {
                showModalForcompanyID &&
                <Modal
                    company={Empresas.find(u => u.id === showModalForcompanyID)}
                    initialOpen={true}
                    close={() => setShowModalForcompanyId(null)}
                    onDelete={() => handleModalDelete(showModalForcompanyID)}
                />
            }
            {isLoading && <LoadingModal />}
        </>
    );
}

export default Empresas;