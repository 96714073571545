import React, { useEffect, useState, useRef } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//modal de carga
import LoadingModal from '../Loading-Modal/loading.jsx'


//import para funciones especificas de esta pantalla
import { getRequisitions_Rejected, getRequisitionWithDetailsById, updateRequisitionStatus } from '../../firebase/requisiciones'


//import de iconos
import { MdArrowBackIosNew, MdArrowForwardIos, MdOutlineModeEditOutline } from 'react-icons/md';

import './requisicion_solicitadas.css'

function RequisRechazadas(props) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar el modal de carga

    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);

    //Estado para almacenar las requisiciones a mostrar
    const [requisiciones, setRequisiciones] = useState([]);


    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                setIsLoading(true);
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    //Cargar datos para usar en la pantalla al cargar
                    setRequisiciones(await getRequisitions_Rejected());

                    setLoading(false); // para saber si termino de cargar los datos del usuario
                    setIsLoading(false);

                    const canAccess = await hasPermission(res.rol, "requisicion/rechazadas");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }
                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                    setIsLoading(false);
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }


    //AQUI ESTA EL PAGINADOR
    const [currentPage, setCurrentPage] = useState(() => {
        const savedPage = localStorage.getItem('currentPageRechazadas');
        return savedPage ? Number(savedPage) : 1;
    });
    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // Calcula las requisiciones actuales para la página actual
    const currentRequisiciones = requisiciones.slice(indexOfFirstItem, indexOfLastItem);

    const totalItems = requisiciones.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage) || 1;

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        localStorage.setItem('currentPageRechazadas', pageNumber);
    };


    //Restaurar los Filtros desde localStorage al Cargar la Página
    useEffect(() => {

        const savedPage = localStorage.getItem('currentPageRechazadas');

        if (savedPage) {
            setCurrentPage(Number(savedPage));
        }

        const lastURL = localStorage.getItem('lastURL');

        if (lastURL != 'requisicion/rechazadas') {
            setCurrentPage(1);
            localStorage.setItem('currentPageRechazadas', 1);
        }

        localStorage.setItem('lastURL', 'requisicion/rechazadas');

    }, []);

    function truncateText(text, wordLimit) {
        try {
            const wordsArray = text.split(' ');
            if (wordsArray.length > wordLimit) {
                return wordsArray.slice(0, wordLimit).join(' ') + '...';
            } else {
                return text;
            }
        } catch {
            return text;
        }

    }

    const goToEditRequi = async (req) => {

        const requiSel = await getRequisitionWithDetailsById(req.id)
        navigate('./aprobar', { state: { requiSel } });
    };


    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' style={{ height: "170vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div style={{ width: "100%" }}>
                            <div className='titulo-container-requis-solicitadas'>
                                <p style={{ fontWeight: "500" }}>Requisiciones Rechazadas</p>
                            </div>

                            <div className='user-details-requis-solicitadas'>
                                <table className='tabla-requis-solicitadas'>
                                    <thead>
                                        <tr className='tabla-header'>
                                            <th style={{ paddingTop: "2%", paddingBottom: "2%", paddingLeft: "3%", width: "7%" }}>#</th>
                                            <th style={{ width: "23%" }}>Proyecto</th>
                                            <th style={{ width: "23%" }}>Descripción</th>
                                            <th style={{ width: "10%" }}>Total</th>
                                            <th style={{ width: "17%" }}>Motivo del Rechazo</th>
                                            <th style={{ width: "10%" }}></th>
                                        </tr>
                                        <tr className="separator">
                                            <td colSpan="6"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentRequisiciones.slice(0, 10).map((requi) => (

                                            <React.Fragment key={requi.id}>
                                                <tr key={requi.id} style={{ height: "75px" }}>
                                                    <td style={{ paddingLeft: "3%" }}>{requi.correlativo}</td>
                                                    <td>{requi.proyecto.nombre}</td>
                                                    <td style={{ paddingRight: "5%" }}>{truncateText(requi.descripcion, 18)}</td>
                                                    <td>Q {requi.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td style={{ paddingRight: "2.5%" }}>{truncateText(requi.motivo_rechazo, 18)}</td>

                                                    <td><button className="icon-button-requis-aprobar"
                                                        onClick={() => goToEditRequi(requi)}>
                                                        <MdOutlineModeEditOutline style={{ marginRight: "4px", fontSize: "15px" }} />Modificar
                                                    </button>
                                                    </td>


                                                </tr>
                                                <tr className="separator">
                                                    <td colSpan="8"></td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='paginador-usuarios'>
                                    <span>{currentPage} de {totalPages} </span>
                                    <div>
                                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}><MdArrowBackIosNew /> Anterior</button>
                                        {currentPage > 1 && <button onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</button>}
                                        <button style={{ fontWeight: "bold" }} className={currentPage === currentPage ? "selected-page" : ""}>{currentPage}</button>
                                        {currentPage < totalPages && <button onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</button>}
                                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Siguiente <MdArrowForwardIos /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isLoading && <LoadingModal />}
        </>
    );
}

export default RequisRechazadas;