// Importa las dependencias necesarias
import { Route, Routes } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase/firebase';


//PAGINAS
import Login from '../components/login/login.jsx'
import HomePage from '../components/home/home.jsx';
import UserPage from '../components/user/user.jsx';
import Editusr from '../components/Ajustes/editusr.jsx';

import UsuariosPage from '../components/Usuarios/usuarios.jsx';
import Nuevo_Usuario from '../components/Usuarios/nuevo_usuario.jsx';
import Editar_Usuario from '../components/Usuarios/editarUsuario.jsx';

import RolesPage from '../components/Roles/roles.jsx'

import HistorialProyectos from '../components/Proyecto/historial_proyectos.jsx'
import NuevoProyecto from '../components/Proyecto/nuevo_proyecto.jsx';
import EditarProyecto from '../components/Proyecto/editar_proyecto.jsx';

import ListadoProveedores from '../components/Proveedores/listado_proveedores.jsx'
import NuevoProveedor from '../components/Proveedores/nuevo_proveedor.jsx';
import EditarProveedor from '../components/Proveedores/editar_provedor.jsx';

import Empresas from '../components/Empresas/listado_empresas';
import NuevaEmpresa from '../components/Empresas/nueva_empresa.jsx';
import EditarEmpresa from '../components/Empresas/editar_empresa.jsx';

import CuentasBanco from '../components/cuentas/listado_cuentas.jsx';
import NuevACuenta from '../components/cuentas/nueva_cuenta.jsx';
import EditarCuenta from '../components/cuentas/editar_cuenta.jsx';

import Solicitud from '../components/Requisiciones/solicitud.jsx';
import Requisiciones_Solicitadas from '../components/Requisiciones/requisicion_solicitadas.jsx';
import Nueva_requi from '../components/Requisiciones/nueva_requisicion.jsx'
import Edit_requi from '../components/Requisiciones/editar_requisicion.jsx'
import Aprobacion_requisicion from '../components/Requisiciones/aprobacion_requisicion.jsx'
import Aprobar from '../components/Requisiciones/aprobar.jsx'
import RequisRechazadas from '../components/Requisiciones/requisiciones_rechazadas.jsx'
import Edit_rechazo from '../components/Requisiciones/aprobar_rechazo.jsx'
import Historial from '../components/Requisiciones/historial_requisiciones.jsx';
import VerRequi from '../components/Requisiciones/ver_requisicion.jsx';
import PrintRequi from '../components/Requisiciones/imprimir_requisicion.jsx';
import Basurero from '../components/Requisiciones/basurero_requisiciones.jsx';

import NuevoVoucher from '../components/Vouchers/nuvo_voucher.jsx';
import HistorialVouchers from '../components/Vouchers/historial_vouchers.jsx';
import EditVoucher from '../components/Vouchers/editar_voucher.jsx';
import VerVoucher from '../components/Vouchers/ver_voucher.jsx';
import PrintVoucher from '../components/Vouchers/imprimir_voucher.jsx';


//para notificaciones
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';










// Exporta un componente funcional que devuelve tus rutas
export default function AppRoutes() {

    const ShowError = (msj) => {
        // esto es para la notificacion
        toast.error(msj, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const ShowNotification = (msj) => {
        // esto es para la notificacion
        toast(msj, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const ShowDone = (msj) => {
        // esto es para la notificacion
        toast.success(msj, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const ShowWar = (msj) => {
        // esto es para la notificacion
        toast.warn(msj, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    //metodo para cerrar sesion
    const logOut = async () => {
        await signOut(auth);
    };

    return (
        <>
            <ToastContainer></ToastContainer>
            <Routes>
                <Route path="/" element=
                    {
                        <Login
                            showErr={ShowError}
                            showMsj={ShowNotification}
                            ShowWar={ShowWar}
                        />
                    }
                />

                <Route path="/login" element=
                    {
                        <Login
                            showErr={ShowError}
                            showMsj={ShowNotification}
                            ShowWar={ShowWar}
                        />
                    }
                />
                <Route path="/home" element=
                    {
                        <HomePage
                            logOut={logOut}
                        />
                    } />
                <Route path="/user" element=
                    {
                        <UserPage />
                    }
                />
                <Route path="/editusr" element=
                    {
                        <Editusr
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/permisos/usuarios" element=
                    {
                        <UsuariosPage
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/permisos/usuarios/nuevo" element=
                    {
                        <Nuevo_Usuario
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/permisos/usuarios/editar" element=
                    {
                        <Editar_Usuario
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/permisos/roles" element=
                    {
                        <RolesPage
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/proyecto/nuevo" element=
                    {
                        <NuevoProyecto
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/solicitud" element=
                    {
                        <Solicitud
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/nueva" element=
                    {
                        <Requisiciones_Solicitadas
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/nueva/crear" element=
                    {
                        <Nueva_requi
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/nueva/editar" element=
                    {
                        <Edit_requi
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/aprobacion" element=
                    {
                        <Aprobacion_requisicion
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/aprobacion/nueva" element=
                    {
                        <Aprobar
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/rechazadas" element=
                    {
                        <RequisRechazadas
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/rechazadas/aprobar" element=
                    {
                        <Edit_rechazo
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/historial" element=
                    {
                        <Historial
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/historial/ver" element=
                    {
                        <VerRequi
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/historial/print" element=
                    {
                        <PrintRequi
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/requisicion/basurero" element=
                    {
                        <Basurero
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/voucher/nuevo" element=
                    {
                        <NuevoVoucher
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/voucher/historial" element=
                    {
                        <HistorialVouchers
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/voucher/edit" element=
                    {
                        <EditVoucher
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/voucher/ver" element=
                    {
                        <VerVoucher
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/voucher/print" element=
                    {
                        <PrintVoucher
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/proyecto/historial" element=
                    {
                        <HistorialProyectos
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/proyecto/historial/editar" element=
                    {
                        <EditarProyecto
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/proveedores/listado" element=
                    {
                        <ListadoProveedores
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/proveedores/nuevo" element=
                    {
                        <NuevoProveedor
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/proveedores/listado/editar" element=
                    {
                        <EditarProveedor
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/empresas/listado" element=
                    {
                        <Empresas
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/empresas/nuevo" element=
                    {
                        <NuevaEmpresa
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/empresas/listado/editar" element=
                    {
                        <EditarEmpresa
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/cuentas/listado" element=
                    {
                        <CuentasBanco
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/cuentas/nuevo" element=
                    {
                        <NuevACuenta
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />
                <Route path="/cuentas/listado/editar" element=
                    {
                        <EditarCuenta
                            showMsj={ShowNotification}
                            showErr={ShowError}
                            ShowWar={ShowWar}
                            ShowDone={ShowDone}
                        />
                    }
                />

                {/* Redirigir a la página de inicio si ninguna otra ruta coincide */}
                <Route path="*" element={<HomePage />} />

            </Routes>
        </>
    );
}

