import { db, auth, uploadFile, storage } from './firebase'; // Importamos 'db' que es nuestra referencia a Firestore desde el archivo original de firebase
import { collection, getDocs, addDoc, query, where, doc, deleteDoc, updateDoc } from 'firebase/firestore'; // Importamos los métodos necesarios de Firestore

// FUNCION PARA OBTENER TODOS LOS NOMBRES DE LAS COMPAÑÍAS
export async function getCompanies() {
    try {
        const companyCollection = collection(db, 'companie_detail');
        const companySnapshot = await getDocs(companyCollection);
        const companyList = companySnapshot.docs.map(doc => doc.id);
        return companyList;
    } catch (error) {
        console.error("Error al obtener las empresas: ", error);
        return [];
    }
}

//FUNCION PARA ALMACENAR NUEVO PROYECTO
export async function addNewProject(projectName, companyName, id_empresa, monto, entidad, fechaInicio, periodo) {
    try {
        // Accede a la colección 'project_detail'
        const projectCollection = collection(db, 'project_detail');

        // Crea una consulta para buscar proyectos con el mismo nombre
        const q = query(projectCollection, where("project_name", "==", projectName), where("project_company", "==", companyName));

        // Ejecuta la consulta
        const querySnapshot = await getDocs(q);

        // Verifica si ya existe un proyecto con el mismo nombre
        if (!querySnapshot.empty) {
            return {
                success: false,
                error: "No se puede agregar el proyecto",
                message: "Ya existe un proyecto con este nombre para la empresa seleccionada."
            };
        }

        // Agrega un nuevo documento con los detalles del proyecto
        const newProject = {
            project_name: projectName,
            project_company: companyName,
            project_company_id: id_empresa,
            project_monto: monto,
            project_entidad_contratante: entidad,
            project_fecha_inicio: fechaInicio,
            project_periodo_tiempo: periodo
        };

        const docRef = await addDoc(projectCollection, newProject);

        return { success: true, message: "Proyecto creado exitosamente." };
    } catch (error) {
        console.error("Error al agregar el proyecto: ", error);
        return {
            success: false,
            error: error,
            message: "Ocurrió un error al agregar el proyecto."
        };
    }
}

// FUNCION PARA OBTENER LA LISTA DE TODOS LOS PROYECTOS REGISTRADOS
export async function getAllProjects() {
    try {
        const projectCollection = collection(db, 'project_detail');
        const projectSnapshot = await getDocs(projectCollection);

        // Verificar si hay documentos
        if (projectSnapshot.empty) {
            return [];
        }

        const projectList = projectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Ordenar la lista alfabéticamente por el campo "project_name" sin importar mayúsculas o minúsculas
        projectList.sort((a, b) => a.project_name.localeCompare(b.project_name, undefined, { sensitivity: 'base' }));

        return projectList;

    } catch (error) {
        console.error("Error al numerar los Proyectos: ", error);
        throw error;
    }
}

// FUNCION PARA ELIMINAR UN PROYECTO POR ID
export async function deleteProjectById(projectId) {
    try {
        // Crea una referencia al documento usando el ID
        const projectRef = doc(db, 'project_detail', projectId);

        // Elimina el documento
        await deleteDoc(projectRef);

        // Retorna un mensaje de éxito
        return { success: true, message: "Proyecto eliminado exitosamente." };
    } catch (error) {
        console.error("Error al eliminar el proyecto: ", error);
        // Retorna un mensaje de error
        return { success: false, message: "Ocurrió un error al eliminar el proyecto.", error: error };
    }
}

// FUNCION PARA ELIMINAR VARIOS PROYECTOS POR ID
export async function deleteProjectsByIds(projectIds) {
    const deleteResults = await Promise.allSettled(
        projectIds.map(projectId => {
            const projectRef = doc(db, 'project_detail', projectId);
            return deleteDoc(projectRef)
                .then(() => ({ id: projectId, success: true }))
                .catch(error => ({ id: projectId, success: false, error }));
        })
    );

    // Mapea los resultados a un formato consistente, independientemente de si la promesa fue resuelta o rechazada
    const results = deleteResults.map(result =>
        result.status === 'fulfilled'
            ? result.value
            : { id: result.reason.id, success: false, error: result.reason.error }
    );

    return results;
}

// FUNCION PARA ACTUALIZAR UN PROYECTO
export async function UpdateProject(projectId, nombreP, empresa, empresaID, monto, entidad, fechaInicio, periodoTiempo) {
    try {
        // Accede a la colección 'project_detail'
        const projectCollection = collection(db, 'project_detail');

        // Crea una consulta para buscar proyectos con el mismo nombre
        const q = query(projectCollection, where("project_name", "==", nombreP), where("project_company", "==", empresa));

        // Ejecuta la consulta
        const querySnapshot = await getDocs(q);

        // Verifica si ya existe un proyecto con el mismo nombre
        if (!querySnapshot.empty) {
            return {
                success: false,
                error: "No se puede editar el proyecto",
                message: "Ya existe un proyecto con este nombre para la empresa seleccionada."
            };
        }

        // Crea una referencia al documento que se va a actualizar
        const projectRef = doc(db, 'project_detail', projectId);

        // Objeto con los nuevos datos del proyecto
        const updatedProject = {
            project_name: nombreP,
            project_company: empresa,
            project_company_id: empresaID,
            project_monto: monto,
            project_entidad_contratante: entidad,
            project_fecha_inicio: fechaInicio,
            project_periodo_tiempo: periodoTiempo
        };

        // Realiza la actualización del documento
        await updateDoc(projectRef, updatedProject);

        // Retorna un mensaje de éxito
        return { success: true, message: "Proyecto actualizado exitosamente." };
    } catch (error) {
        console.error("Error al actualizar el proyecto: ", error);
        // Retorna un mensaje de error
        return { success: false, message: "Ocurrió un error al actualizar el proyecto.", error: error };
    }
}
