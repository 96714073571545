import React from "react";
import "./Modal.css";

export default function Modal({ user, selectedUsers, close, onDelete,
    requi, selectedRequi,
    project, selectedProjects,
    provider, selectedProviders,
    company, selectedCompanies,
    account, selectedAccounts
}) {

    let displayText;

    if (user != null || selectedUsers != null) {
        if (user) {
            displayText = `¿Estás seguro que deseas eliminar a ${user.nombre}?`;
        } else if (selectedUsers != null && selectedUsers.length > 1) {
            displayText = `¿Estás seguro que deseas eliminar a ${selectedUsers.length} usuarios?`;
        } else if (selectedUsers != null && selectedUsers.length === 1) {
            displayText = `¿Estás seguro que deseas eliminar al usuario ${selectedUsers[0].nombre}?`;
        } else {
            displayText = "No hay usuarios seleccionados para eliminar.";
        }
    } else if (requi != null || selectedRequi != null) {
        if (requi) {
            displayText = `¿Estás seguro que quieres eliminar la Requisición # ${requi.correlativo}?`;
        } else if (selectedRequi != null && selectedRequi.length > 1) {
            displayText = `¿Estás seguro que deseas eliminar ${selectedRequi.length} requisiciones?`;
        } else if (selectedRequi != null && selectedRequi.length === 1) {
            displayText = `¿Estás seguro que quieres eliminar la Requisición # ${selectedRequi[0].correlativo}?`;
        } else {
            displayText = "No hay requisiciones seleccionadas para ser eliminadas.";
        }
    } else if (project != null || selectedProjects != null) {
        if (project) {
            displayText = `¿Estás seguro que deseas el proyecto "${project.project_name}"?`;
        } else if (selectedProjects != null && selectedProjects.length > 1) {
            displayText = `¿Estás seguro que deseas eliminar ${selectedProjects.length} proyectos?`;
        } else if (selectedProjects != null && selectedProjects.length === 1) {
            displayText = `¿Estás seguro que deseas eliminar el proyecto "${selectedProjects[0].project_name}"?`;
        } else {
            displayText = "No hay proyectos seleccionados para eliminar.";
        }
    } else if (provider != null || selectedProviders != null) {
        if (provider) {
            displayText = `¿Estás seguro que deseas el proveedor "${provider.name}"?`;
        } else if (selectedProviders != null && selectedProviders.length > 1) {
            displayText = `¿Estás seguro que deseas eliminar ${selectedProviders.length} proveedores?`;
        } else if (selectedProviders != null && selectedProviders.length === 1) {
            displayText = `¿Estás seguro que deseas eliminar el proveedor "${selectedProviders[0].name}"?`;
        } else {
            displayText = "No hay proveedores seleccionados para eliminar.";
        }
    } else if (company != null || selectedCompanies != null) {
        if (company) {
            displayText = `¿Estás seguro que deseas la empresa "${company.nombre}"?`;
        } else if (selectedCompanies != null && selectedCompanies.length > 1) {
            displayText = `¿Estás seguro que deseas eliminar ${selectedCompanies.length} empresas?`;
        } else if (selectedCompanies != null && selectedCompanies.length === 1) {
            displayText = `¿Estás seguro que deseas eliminar la empresa "${selectedCompanies[0].nombre}"?`;
        } else {
            displayText = "No hay empresas seleccionados para eliminar.";
        }
    } else if (account != null || selectedAccounts != null) {
        if (account) {
            displayText = `¿Estás seguro que deseas la cuenta "${account.name}"?`;
        } else if (selectedAccounts != null && selectedAccounts.length > 1) {
            displayText = `¿Estás seguro que deseas eliminar ${selectedAccounts.length} cuentas?`;
        } else if (selectedAccounts != null && selectedAccounts.length === 1) {
            displayText = `¿Estás seguro que deseas eliminar la cuenta "${selectedAccounts[0].name}"?`;
        } else {
            displayText = "No hay cuentas seleccionados para eliminar.";
        }
    }


    const handleDelete = (event) => {
        event.stopPropagation();
        onDelete();
        close();
    };

    const handleClose = (event) => {
        event.stopPropagation();
        close();
    };

    return (
        <>
            <div className="modal">
                <div className="overlay"></div>
                <div className="modal-content">
                    <p>{displayText}</p>
                    <button className="close-modal-delete" onClick={handleClose}>X</button>
                    <div className="modal-footer">
                        <button onClick={handleDelete} className="confirmation-button-delete">Eliminar</button>
                        <button onClick={handleClose} className="close-button-delete">Cancelar</button>
                    </div>
                </div>
            </div>
        </>
    );
}
