import React, { useEffect, useState, useRef } from "react";

//import para inicio de sesion y consultas
import { onAuthStateChanged } from 'firebase/auth';
import { auth, getUserInfo } from '../../firebase/firebase';

//import para ver permisos
import { hasPermission } from '../../firebase/permisos';

//import para navigate
import { useNavigate } from 'react-router-dom';

//importacion del MENU
import Menu from '../menu';
import HeaderHome from '../header';

//import para funciones especificas de esta pantalla
import { getRegimes, addProvider } from '../../firebase/proveedores.js'
import './proveedores.css'

//import './nuevo_proyecto.css'

import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack } from 'react-icons/io';


function NuevoProveedor(props) {

    const navigate = useNavigate();

    const [useUser, setUseUser] = useState({
        nombre: '',
        rol: '',
        email: '',
        genero: '',
        fechaIni: '',
        cumple: '',
        tel: '',
        foto: ''
    });

    const [loading, setLoading] = useState(true);
    const hasCheckedPermission = useState(false);
    const [Regimes, setRegimes] = useState([]);
    const [touchedFields, setTouchedFields] = useState({});

    // Este useEffect maneja el estado del usuario
    useEffect(() => {
        if (!hasCheckedPermission.current) {
            onAuthStateChanged(auth, handleUserStateChanged);
            hasCheckedPermission.current = true;
        }
    }, []);

    async function handleUserStateChanged(user) {
        if (user) {
            try {
                const res = await getUserInfo(user.uid);
                if (res != null) {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: res.nombre,
                        rol: res.rol,
                        email: res.email,
                        genero: res.genero,
                        fechaIni: res.fechaIni.toDate().toLocaleDateString(),
                        cumple: res.cumple.toDate().toLocaleDateString(),
                        tel: res.tel,
                        foto: res.foto
                    }));

                    const regimeList = await getRegimes();
                    setRegimes(regimeList);

                    setLoading(false); // para saber si termino de cargar los datos del usuario

                    const canAccess = await hasPermission(res.rol, "proveedores/listado");
                    if (!canAccess) {
                        props.showErr('Usted no tiene permisos para acceder a esta función.')
                        navigate('/home');
                        return;
                    }

                } else {
                    setUseUser(prevState => ({
                        ...prevState,
                        nombre: 'N/A',
                        rol: 'N/A',
                        email: 'N/A',
                        genero: 'N/A',
                        fechaIni: 'N/A',
                        cumple: 'N/A',
                        tel: 'N/A',
                        foto: 'N/A'
                    }));
                }
            } catch {
                console.log('NO HAY USUARIO :(');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }

    const [update, setUpdate] = useState(false);
    const [nombreP, setnombreP] = useState('');
    const [Contacto, setContacto] = useState('');
    const [Correo, setCorreo] = useState('');
    const [Nit, setNit] = useState('');
    const [Telefono, setTelefono] = useState('');
    const [Direccion, setDireccion] = useState('');
    const [Regime, setRegime] = useState('');
    const [Giro, setGiro] = useState('');


    const InputChangeUsr = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'nombreP':
                setnombreP(value);
                break;
            case 'contacto':
                setContacto(value);
                break;
            case 'correo':
                setCorreo(value);
                break;
            case 'nit':
                setNit(value);
                break;
            case 'telefono':
                if (/^[0-9]*$/.test(value) || value === '') { // Permite solo números

                    setTelefono(value);

                } else {

                    props.ShowWar('El campo solo admite números.');
                }

                break;
            case 'direccion':
                setDireccion(value);
                break;
            case 'giro':
                setGiro(value);
                break;
            default:
                setRegime(value);
                break;
        }
        setTouchedFields(prevState => ({ ...prevState, [name]: true }));
        //setUpdate(true);
    };


    // useEffect para verificar que los datos necesarios contengan informacion
    useEffect(() => {
        // Comprobar si todos los campos tienen datos
        const allFieldsFilled = nombreP &&
            Contacto &&
            Giro;

        // Habilitar o deshabilitar el botón basado en si todos los campos están llenos
        setUpdate(allFieldsFilled);
    }, [nombreP, Contacto, Giro]);

    const [isCompanyOpen, setCompanyOpen] = useState(false);

    const handleCompanyButtonClick = () => {
        setCompanyOpen(!isCompanyOpen);
        // Marcar el botón como "tocado" al interactuar con él
        setTouchedFields(prevState => ({ ...prevState, empresa: true }));
    };


    //Seleccion de regimen
    const handleRegimeChange = (regi, event) => {

        event.preventDefault(); // Esto previene la recarga de la página

        setCompanyOpen(false);
        setRegime(regi);
    };

    // Efecto para manejar el clic fuera del menú
    useEffect(() => {
        function handleDocumentClick(event) {
            // Verificar si el clic es fuera del menú "field-proyecto"
            if (isCompanyOpen && !event.target.closest('.field-proyecto')) {
                setCompanyOpen(false);
            }
        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isCompanyOpen]); // Dependencias del efecto


    // Función para validar el correo electrónico
    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    //enviar a guardar el proyecto
    const handleSaveChanges = async () => {

        try {

            if (!nombreP || !Contacto || !Giro) {
                props.ShowWar('Por favor llena los campos minimos necesarios "*"');

            } else {
                if (Correo != '' && !validateEmail(Correo)) {
                    props.ShowWar('Verifique el formato del Correo Electrónico.');
                    return;
                } else if (nombreP.trim().length < 1) {
                    props.ShowWar('Debes indicar un "Nombre" valido.');
                    return;
                }

                //Creando la estructura para el proveedor
                const newProvider = {
                    name: nombreP.trim(),
                    contact: Contacto.trim(),
                    email: Correo.trim(),
                    regime: Regime,
                    nit: Nit.trim(),
                    phone: Telefono.trim(),
                    address: Direccion.trim(),
                    business_turnover: Giro.trim()
                };

                const { success, message, error } = await addProvider(newProvider);
                if (success) {
                    setnombreP('');
                    setContacto('');
                    setCorreo('');
                    setRegime('');
                    setNit('');
                    setTelefono('');
                    setDireccion('');
                    setGiro('');


                    props.ShowDone(message);
                    setUpdate(false);

                    navigate("/proveedores/listado")

                } else {
                    props.showErr(error);
                    props.ShowWar(message);
                }
            }


        } catch (error) {
            props.showErr("No es posible crear el proveedor.");
        }
    };

    return (
        <>
            {!loading && (
                <div>
                    < HeaderHome
                        userLoged={useUser}
                    />
                    <div className='bodyHome-user' style={{ height: "210vh" }}>
                        < Menu
                            userLoged={useUser}
                        />
                        <div style={{ width: "100%" }}>
                            <a className="back-button-requis" onClick={() => navigate("/proveedores/listado")}> <IoIosArrowBack style={{ marginRight: "10px" }} /> Regresar</a>
                            <p style={{ fontWeight: "500", marginTop: "10vh" }}>Nuevo Proveedor</p>
                            <div className='card-proveedores'>
                                <a style={{ marginBottom: "2.5%", fontSize: "large" }}>Información Básica</a>
                                <form>
                                    <div className='card-nuevo-proyecto-div'>
                                        <div style={{ flex: 1 }}>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>
                                                    Nombre
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['nombreP'] ? '#3A93F7' : '' }}
                                                    id='nombreP'
                                                    name='nombreP'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el nombre del proveedor"
                                                    value={nombreP}
                                                />
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>
                                                    Contacto
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['contacto'] ? '#3A93F7' : '' }}
                                                    id='contacto'
                                                    name='contacto'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el nombre del contacto"
                                                    value={Contacto}
                                                />
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>Correo Electrónico</label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['correo'] ? '#3A93F7' : '' }}
                                                    type='email'
                                                    id='correo'
                                                    name='correo'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el correo electrónico"
                                                    value={Correo}
                                                />
                                            </div>
                                            <div className="field-proyecto">
                                                <label style={{ color: 'black' }}>Régimen de Facturación</label>
                                                <button
                                                    className="boton-desplegable"
                                                    type="button"
                                                    onClick={handleCompanyButtonClick}
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', textAlign: "left", borderColor: touchedFields['empresa'] ? '#3A93F7' : '' }}>
                                                    {Regime || "Selecciona el régimen de facturación"}
                                                    {isCompanyOpen ? <IoIosArrowUp style={{ fontSize: "25px" }} /> : <IoIosArrowDown style={{ fontSize: "25px" }} />}
                                                </button>

                                                {isCompanyOpen && (
                                                    <div className="dropdown-container-regimen">
                                                        <ul>
                                                            {Regimes.map((regime, index) => (
                                                                <li key={index}>
                                                                    <button className="boton-desplegable" type="button" onClick={(e) => handleRegimeChange(regime, e)}>
                                                                        {regime}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>NIT</label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['nit'] ? '#3A93F7' : '' }}
                                                    id='nit'
                                                    name='nit'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el número de NIT"
                                                    value={Nit}
                                                />
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>Teléfono</label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['telefono'] ? '#3A93F7' : '' }}
                                                    id='telefono'
                                                    name='telefono'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe el número de teléfono"
                                                    value={Telefono}
                                                />
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>Dirección</label>
                                                <input
                                                    style={{ background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['direccion'] ? '#3A93F7' : '' }}
                                                    id='direccion'
                                                    name='direccion'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe la dirección"
                                                    value={Direccion}
                                                />
                                            </div>
                                            <div className="form-column-proyecto">
                                                <label style={{ color: 'black' }}>
                                                    Giro de Negocio
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    style={{ marginBottom: '40px', background: '#FFFFFF', boxShadow: '3px 3px 5px #00000029', borderColor: touchedFields['giro'] ? '#3A93F7' : '' }}
                                                    id='giro'
                                                    name='giro'
                                                    onChange={InputChangeUsr}
                                                    placeholder="Escribe la descripción de su giro de negocio"
                                                    value={Giro}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='button-container-proyecto'>
                                {update ? (
                                    <button className='botonPrincipal-proyecto' onClick={handleSaveChanges}>Crear</button>
                                ) : (
                                    <button className='botonSecondary-proyecto' disabled={true}>Crear</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </>
    );

}

export default NuevoProveedor;