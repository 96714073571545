import React, { useState } from "react";
import "./Modal-rechazar.css";

import { updateRequisitionStatus } from '../../firebase/requisiciones.js'

export default function ModalRechazo({ handleClose, showErr, ShowDone, ShowWar, requi }) {

    const [justification, setJustification] = useState("");

    const handleInputChange = (e) => {
        setJustification(e.target.value);
    };

    //funcion para validar entrada
    const validateJust = () => {
        if (justification.trim().length < 1) {
            ShowWar("Debes indicar un motivo valido.");
            return false;
        }
        return true;
    };

    //Funcion para cambair a estado "Rechazada"
    const handleCreateRole = async () => {
        if (validateJust()) {
            const result = await updateRequisitionStatus(requi, "Rechazada", justification);
            if (result.success) {

                ShowDone(result.message)
                handleClose();


            } else {
                showErr(result.message);
                handleClose();

            }

        }
    };



    return (
        <>
            <div className="modal">
                <div className="overlay"></div>
                <div className="modal-content-rol">
                    <p>¿Cuál es el motivo del rechazo? </p>
                    <textarea
                        id='rol'
                        name='rol'
                        className="modal-content-rol-textarea"
                        onChange={handleInputChange}
                        placeholder="Escribe el motivo de la eliminación de la requisición"
                        value={justification}
                    ></textarea>
                    <button className="close-modal" onClick={handleClose}>X</button>
                    <div className="modal-footer">
                        <button className="confirmation-button" onClick={handleCreateRole}>Aceptar</button>
                        <button className="close-button" onClick={handleClose}>Cancelar</button>
                    </div>
                </div>
            </div>
        </>
    );
}
