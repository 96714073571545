import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, updateDoc, setDoc, serverTimestamp } from 'firebase/firestore';
//import { getFirestore, collection, addDoc, getDoc, doc, query, where, setDoc, deleteDoc, initializeFirestore } from 'firebase/firestore';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword, updateEmail } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL, getBytes } from 'firebase/storage';
import CryptoJS from 'crypto-js';


import { onAuthStateChanged } from "firebase/auth";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Referencia al Storage
const storage = getStorage(app);

// Crear una referencia de autenticación
const auth = getAuth();

// Crear una referencia a Firestore
const db = getFirestore();


//funcion para ver si el usuario ya esta registrado con el reste de su informacion
export async function userExist(uid) {
    const docRef = doc(db, "users_detail", uid);
    const result = await getDoc(docRef);

    return result.exists();
}

//METODO PARA HACER LA CONSULTA DE UN USUARIO
export async function getUserInfo(uid) {

    try {
        //console.log(uid);
        const docRef = doc(db, "users_detail", uid);
        const document = await getDoc(docRef);
        //console.log(document.data());
        return document.data();

    } catch (error) {
        console.log('ERROR DE LA VIDA!!!')
        console.log(error);
        return null;
    }
}

//EDITAR UN USUARIO
export async function updateUserInfo(uid, data) {
    try {
        const userRef = doc(db, 'users_detail', uid);
        await updateDoc(userRef, data);
        return true;
    } catch (error) {
        return 'Error actualizando usuario:', error;
    }
}

//CREAR USUARIO
export async function createOrUpdateUserInfo(uid, data) {
    try {
        const userRef = doc(db, 'users_detail', uid);
        await setDoc(userRef, data);
        console.log('Usuario creado o actualizado exitosamente');
    } catch (error) {
        console.error('Error creando o actualizando usuario:', error);
    }
}

//ACTUALIZAR ULTIMA CONEXION
export async function updateLastLogin(uid) {
    const userRef = doc(db, 'users_detail', uid);
    await updateDoc(userRef, {
        ultimo_log: serverTimestamp()  // Esto coloca el tiempo actual del servidor de Firebase.
    });
}

export async function uploadFile(file, uid) {
    try {
        const stref = ref(storage, '/avatars/' + uid);
        const result = await uploadBytes(stref, file);
        const imgresult = await getDownloadURL(stref);
        //console.log(result);
        return imgresult;
    } catch (error) {
        return 'Error actualizando Imagen:', error;
    }
}

export async function getAvatar(uid) {
    try {
        const stref = ref(storage, '/avatars/' + uid);
        const result = await getDownloadURL(stref);
        //console.log(result);
        return result;
    } catch (error) {
        return 'Error obtener Avatar:', error;
    }
}

// Función para cifrar la contraseña
function encryptPassword(password, secretKey) {
    return CryptoJS.AES.encrypt(password, secretKey).toString();
}

// Función para descifrar la contraseña
function decryptPassword(ciphertext, secretKey) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
}
//CAMBIO DE CONTRASEÑA USUARIO AUTENTICADO
export async function changePasswordAndEmail(currentPassword, newPassword, newEmail) {
    const user = auth.currentUser;

    if (user && user.email) {
        const credential = EmailAuthProvider.credential(user.email, currentPassword);

        try {
            // Reautenticar al usuario con la contraseña actual
            await reauthenticateWithCredential(user, credential);

            if (newEmail != user.email) {
                await updateEmail(user, newEmail);
            }

            // Cambiar la contraseña
            await updatePassword(user, newPassword);

            // Encriptar la contraseña
            const secretKey = process.env.REACT_APP_ECRP;  // Asegúrate de almacenar esta clave en un lugar seguro
            const encryptedPassword = encryptPassword(newPassword, secretKey);

            // Actualizar el campo "ecrp" en Firestore
            const userRef = doc(db, 'users_detail', user.uid);
            const newEcrpValue = encryptedPassword;
            await updateDoc(userRef, { ecrp: newEcrpValue });
            return { success: true, message: "Contraseña actualizada de forma correcta!" };

        } catch (error) {
            console.error("Error updating password:", error);
            if (error.code === 'auth/too-many-requests') {
                return { success: false, error: error, message: "Acceso a esta cuenta ha sido temporalmente deshabilitado debido a muchos intentos fallidos de inicio de sesión. Puedes restaurarlo inmediatamente al restablecer tu contraseña o puedes intentarlo nuevamente más tarde." };
            } else if (error.code === 'auth/weak-password') {
                return { success: false, error: error, message: "La contraseña debe tener al menos 6 caracteres." };
            } else {
                console.log('Ocurrió un error al cambiar la contraseña:', error.message);
                return { success: false, error: error, message: "Ocurrió un error al cambiar la contraseña." };
            }
        }
    } else {
        return new Error("Ningún usuario ha iniciado sesión o falta el correo electrónico.");
    }
}

//CAMBIO DE CORREO USUARIO AUTENTICADO
export async function changeEmail(newEmail, currentPassword) {
    const user = auth.currentUser;

    if (user && user.email) {
        const credential = EmailAuthProvider.credential(user.email, currentPassword);

        try {
            // Reautenticar al usuario con la contraseña actual
            await reauthenticateWithCredential(user, credential);

            if (newEmail != user.email) {
                await updateEmail(user, newEmail);
            }
            return { success: true, message: "Correo actualizada de forma correcta!" };

        } catch (error) {
            console.error("Error updating email:", error);
            if (error.code === 'auth/too-many-requests') {
                return { success: false, error: error, message: "Acceso a esta cuenta ha sido temporalmente deshabilitado debido a muchos intentos fallidos de inicio de sesión. Puedes restaurarlo inmediatamente al restablecer tu contraseña o puedes intentarlo nuevamente más tarde." };
            } else {
                console.log('Ocurrió un error al cambiar la contraseña:', error.message);
                return { success: false, error: error, message: "Ocurrió un error al cambiar el correo." };
            }
        }
    } else {
        return new Error("Ningún usuario ha iniciado sesión o falta el correo electrónico.");
    }
}

export { app, db, auth, storage };