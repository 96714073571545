import React, { useState, useEffect } from 'react';

const convertToCSSString = (styleObj) => {
    let cssStr = '';
    for (const [key, value] of Object.entries(styleObj)) {
        const cssKey = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
        cssStr += `${cssKey}: ${value}; `;
    }
    return cssStr;
};


const baseStyles = {

    estiloRequisicion: {
        fontFamily: 'Arial, sans-serif',
        width: '89%',
        height: '92%',
        padding: '5%',
        border: '1px solid #ddd'
    },
    encabezado: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '0px',
        marginBottom: '5px'

    },
    titulo: {
        marginTop: "25px",
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    numeroRequisicion: {
        textAlign: 'right',
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    dato: {
        fontSize: '16px',
        fontWeight: "bolder",
        marginTop: "0"
    },
    detalle: {
        marginLeft: "15px",
        marginBottom: "0",
        marginTop: "0"
    },
    descrip: {
        marginTop: "0",
        height: '300px',
        fontSize: '16px',
        fontWeight: "bolder"
    },
    total: {
        margin: '0',
        fontSize: '16px',
        fontWeight: "bolder"
    },
    linea: {
        height: '7px',
        backgroundColor: 'black',
        margin: '10px 0'
    },
    firmas: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '230px'
    },
    firma: {
        borderTop: '1px solid black',
        width: '45%',
        textAlign: 'center',
        fontSize: '16px',
        paddingTop: '10px'
    },
    seccion: {
        borderBottom: '1px solid #000',
        marginBottom: '30px',

    }
};

const PDFComponent = ({ numero, emisor, solicitante, fecha, proyecto, empresa, proveedor, descripcion, total, items }) => {

    //estados para calculos
    const [SubTotal, setSubTotal] = useState(0);
    const [DescuentoTotal, setDescuentoTotal] = useState(0);
    const [TotalIVA, setTotalIVA] = useState(0);
    const [TotalISR, setTotalISR] = useState(0);

    useEffect(() => {

        // Calculando el subtotal
        const subtotal = items.reduce((acc, item) => acc + ((item.cantidad ?? 0) * (Number(item.precio) || 0)), 0);

        // Calculando el descuento
        const descuento = items.reduce((acc, item) => acc + (Number(item.descuento) || 0), 0);

        // Calculando el total de IVA
        const totalIVA = items.reduce((acc, item) => acc + (Number(item.iva) || 0), 0);

        // Calculando el total de ISR
        const totalISR = items.reduce((acc, item) => acc + (Number(item.isr) || 0), 0);


        // Actualizando los estados
        setSubTotal(subtotal);
        setDescuentoTotal(descuento);
        setTotalIVA(totalIVA);
        setTotalISR(totalISR);

    }, [items]);

    return (
        <div className="estiloRequisicion" id="contenidoParaImprimir">
            <div className="encabezado">
                <div className="titulo">REQUERIMIENTO DE COMPRA</div>
                <div className="numeroRequisicion">NO. {numero}</div>
            </div>
            <div className='seccion'></div>
            <div style={{ display: "flex" }}>
                <p className="dato">FECHA: </p>
                <p className="detalle">{fecha}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="dato">EMISOR: </p>
                <p className="detalle">{emisor}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="dato">SOLICITA: </p>
                <p className="detalle" style={{ marginBottom: "30px" }}>{solicitante}</p>
            </div>


            <div className='seccion'></div>


            <div style={{ display: "flex" }}>
                <p className="dato">PROYECTO: </p>
                <p className="detalle" style={{ marginLeft: "10px" }}>{proyecto}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="dato">EMPRESA: </p>
                <p className="detalle" style={{ marginLeft: "10px" }}>{empresa}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="dato">EMITIR CHEQUE PARA: </p>
                <p className="detalle" style={{ marginLeft: "10px" }}>{proveedor}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="descrip">DESCRIPCIÓN: </p>
                <p className="detalle" style={{ width: "90%", marginLeft: "10px" }}>{descripcion}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="total">SUBTOTAL: </p>
                <p className="detalle" style={{ marginLeft: "15px" }}>Q {SubTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="total">TOTAL DESCUENTO: </p>
                <p className="detalle" style={{ marginLeft: "15px" }}>Q {DescuentoTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="total">TOTAL RETENCIÓN IVA: </p>
                <p className="detalle" style={{ marginLeft: "15px" }}>Q {TotalIVA.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="total">TOTAL RETENCIÓN ISR: </p>
                <p className="detalle" style={{ marginLeft: "15px" }}>Q {TotalISR.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            <div style={{ display: "flex", marginTop: "35px", fontWeight: "bolder" }}>
                <p className="total">TOTAL REQUISICIÓN: </p>
                <p className="detalle" style={{ marginLeft: "15px", placeSelf: "center", marginTop: "5px" }}>Q {total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            <div className="firmas">
                <div className="firma">FIRMA<br />AUTORIZACIÓN</div>
                <div className="firma">FIRMA<br />SEGUIMIENTO</div>
            </div>
        </div>
    );
};

export default PDFComponent;

export const getPDFStyles = () => {
    let styleString = '';
    // Convertir cada sub-objeto en baseStyles a una cadena CSS
    for (const [className, styleObj] of Object.entries(baseStyles)) {  // Usamos baseStyles directamente
        styleString += `.${className} { ${convertToCSSString(styleObj)} }\n`;  // Usamos convertToCSSString directamente
    }
    return styleString;
}
