import React, { useState, useEffect } from 'react';
import { getRequisitionWithDetailsById } from '../../firebase/requisiciones'

const convertToCSSString = (styleObj) => {
    let cssStr = '';
    for (const [key, value] of Object.entries(styleObj)) {
        const cssKey = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
        cssStr += `${cssKey}: ${value}; `;
    }
    return cssStr;
};


const baseStyles = {

    estiloRequisicion: {
        fontFamily: 'Arial, sans-serif',
        width: '89%',
        height: '92%',
        padding: '5%',
        border: '1px solid #ddd'
    },
    encabezado: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '0px',
        marginBottom: '5px'

    },
    titulo: {
        marginTop: "25px",
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    numeroRequisicion: {
        textAlign: 'right',
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    datos: {
        marginTop: '0',
        fontSize: '16px',
        fontWeight: "bolder"
    },
    detalles: {
        marginTop: "0",
        marginLeft: "15px"
    },
    descripc: {
        marginTop: "20px",
        fontSize: '16px',
        fontWeight: "bolder"
    },
    total: {
        marginTop: '0',
        fontSize: '16px',
        fontWeight: "bolder"
    },
    linea: {
        height: '7px',
        backgroundColor: 'black',
        margin: '10px 0'
    },
    firmas: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '230px'
    },
    firma: {
        borderTop: '1px solid black',
        width: '45%',
        textAlign: 'center',
        fontSize: '16px',
        paddingTop: '10px'
    },
    seccion: {
        borderBottom: '1px solid #000',
        marginBottom: '30px',

    }
};

const PDFComponent = ({ fecha, numero, cuenta_num, cuenta_name, check, proveedor, proyecto, descripcion, total, items }) => {

    // Asegurándonos de que 'total' sea un número antes de formatearlo
    const totalNumerico = Number(total); // Convierte 'total' a un número si es una cadena

    // Usando 'toLocaleString' para formatear el número
    const totalFormateado = totalNumerico.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    //estados para calculos
    const [SubTotal, setSubTotal] = useState(0);
    const [DescuentoTotal, setDescuentoTotal] = useState(0);
    const [TotalIVA, setTotalIVA] = useState(0);
    const [TotalISR, setTotalISR] = useState(0);

    useEffect(() => {

        if (items) {

            // Calculando el subtotal
            const subtotal = items.reduce((acc, item) => acc + ((item.cantidad ?? 0) * (Number(item.precio) || 0)), 0);

            // Calculando el descuento
            const descuento = items.reduce((acc, item) => acc + (Number(item.descuento) || 0), 0);

            // Calculando el total de IVA
            const totalIVA = items.reduce((acc, item) => acc + (Number(item.iva) || 0), 0);

            // Calculando el total de ISR
            const totalISR = items.reduce((acc, item) => acc + (Number(item.isr) || 0), 0);

            // Actualizando los estados
            setSubTotal(subtotal);
            setDescuentoTotal(descuento);
            setTotalIVA(totalIVA);
            setTotalISR(totalISR);

        }


    }, [items]);


    return (
        <div className="estiloRequisicion" id="contenidoParaImprimir">
            <div className="encabezado">
                <div className="titulo">VOUCHER</div>
                <div className="numeroRequisicion">NO. {numero}</div>
            </div>
            <div className='seccion'></div>
            <div style={{ display: "flex" }}>
                <p className="datos">FECHA: </p>
                <p className="detalles">{fecha}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="datos">NO. DE CUENTA: </p>
                <p className="detalles">{cuenta_num}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="datos">NOMBRE DE LA CUENTA: </p>
                <p className="detalles">{cuenta_name}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="datos">NO. DE CHEQUE: </p>
                <p className="detalles">{check}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p className="datos">BENEFICIARIO: </p>
                <p className="detalles">{proveedor}</p>
            </div>
            {proyecto && ( // Solo renderizar esta sección si `proyecto` tiene un valor
                <div style={{ display: "flex" }}>
                    <p className="datos">FINANCIAMIENTO: </p>
                    <p className="detalles">{proyecto}</p>
                </div>
            )}
            <div style={{ display: "flex" }}>
                <p className="descripc">DESCRIPCIÓN: </p>
                <p className="detalles" style={{ width: "90%", height: '145px', marginTop: "20px" }}>{descripcion}</p>
            </div>

            {proyecto && ( // Solo renderizar esta sección si `proyecto` tiene un valor
                <>
                    <div style={{ display: "flex" }}>
                        <p className="total">SUBTOTAL: </p>
                        <p className="detalles" style={{ marginLeft: "15px" }}>Q {SubTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                        <p className="total">TOTAL DESCUENTO: </p>
                        <p className="detalles" style={{ marginLeft: "15px" }}>Q {DescuentoTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                        <p className="total">TOTAL RETENCIÓN IVA: </p>
                        <p className="detalles" style={{ marginLeft: "15px" }}>Q {TotalIVA.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                        <p className="total">TOTAL RETENCIÓN ISR: </p>
                        <p className="detalles" style={{ marginLeft: "15px" }}>Q {TotalISR.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                </>
            )}

            <div style={{ display: "flex", fontWeight: "bolder" }}>
                <p className="total">MONTO TOTAL: </p>
                <p className="detalles" style={{ marginLeft: "15px", alignSelf: "center" }}>Q {totalFormateado}</p>
            </div>


            <div style={{ textAlign: 'left', marginTop: '90px', width: '840px', textAlignLast: 'center' }}>
                <span>RECIBIDO POR: </span>
                <div style={{ borderBottom: '1px solid black', display: 'inline-block', width: '40%' }}></div>
                <div style={{ marginTop: '25px' }}>
                    <span >FECHA: </span>
                    <div style={{ borderBottom: '1px solid black', display: 'inline-block', width: '130px' }}></div>
                    <span>DPI: </span>
                    <div style={{ borderBottom: '1px solid black', display: 'inline-block', width: '233px' }}></div>
                </div>
                <span style={{ marginTop: '20px' }}>FIRMA: </span>
                <div style={{ borderBottom: '1px solid black', display: 'inline-block', width: '48%', marginTop: '40px' }}></div>
            </div>
        </div>
    );
};

export default PDFComponent;

export const getPDFStyles = () => {
    let styleString = '';
    // Convertir cada sub-objeto en baseStyles a una cadena CSS
    for (const [className, styleObj] of Object.entries(baseStyles)) {  // Usamos baseStyles directamente
        styleString += `.${className} { ${convertToCSSString(styleObj)} }\n`;  // Usamos convertToCSSString directamente
    }
    return styleString;
}
